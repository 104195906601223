// @flow
import {Axios} from "axios";
import {axiosInstance} from "../../api";

class AnnouncementService {
	axios: Axios;

	constructor(axios: Axios) {
		this.axios = axios;
	}

	async getReleaseNotes() {
		return this.axios.get("/releaseNote")
			.then(res => res.data.content);
	}

	async getClearanceNotes() {
		return this.axios.get("/clearanceNote")
			.then(res => res.data.content);
	}

	async getMalFunctionNotes() {
		return this.axios.get("/malfunctionNote")
			.then(res => res.data.content);
	}

	async getMaintenanceNotes() {
		return this.axios.get("/maintenanceNote")
			.then(res => res.data.content);
	}

}

const AnnAnnouncementServiceInstance = new AnnouncementService(axiosInstance);
export default AnnAnnouncementServiceInstance;
