import type {PartCodeModel, ProductResponse} from "../../productsearch/productApi";
import productApi from "../../productsearch/productApi";

class FigureProductService {

	extractUnmatchedPartCodes(partCodeModels: PartCodeModel[], foundPartCodes) {
		return partCodeModels.filter(partCodeModel =>
			!foundPartCodes.some(
				foundPartCode => partCodeModel.partCode === foundPartCode.partCode
					&& partCodeModel.partCodeSymbol === foundPartCode.partCodeSymbol
			)
		)
			.map(partCodeModel => {
				return {
					...partCodeModel,
					fullPartCode: [partCodeModel.partCode, partCodeModel.partCodeSymbol].filter(Boolean).join("*")
				};
			});
	}

	async getProductsWithPartCodes(partCodes, factoryCode, destinationCode, modelYearCode, optionCodes, colorCode, interiorColorCode, figureNumber, vehicleBuildDate, vehicleDecommissionDate) {
		try {
			const foundProducts = await productApi.getByPartCodeAndMostRelevantPrecisionDetails(partCodes, factoryCode, destinationCode, modelYearCode, optionCodes, colorCode, interiorColorCode, figureNumber, vehicleBuildDate, vehicleDecommissionDate);

			const foundPartCodes = foundProducts.content.map(entry => {
					return {
						partCode: entry.partCode,
						partCodeSymbol: entry.partCodeSymbol
					};
				}
			);
			const unmatchedPartCodes = this.extractUnmatchedPartCodes(partCodes, foundPartCodes);
			return {
				products: foundProducts.content,
				unmatchedPartCodes: unmatchedPartCodes
			};
		} catch (error) {
			console.error(error);
		}
	}

	extractProductNotFoundPartNumbers(partNumbers: string[], foundProducts: ProductResponse[]) {
		return partNumbers.filter(partNumber => !foundProducts.some(productContent =>
			productContent.partNo === partNumber)
		);
	}

	async getProductsForPartNumbers(partNumbers) {
		const products = [];
		let productNotFoundPartNumbers;
		await Promise.all(
			partNumbers.map(partNumber => {
				return productApi.getByPartNumber(partNumber);
			})
		).then(response => {
			response.forEach(productModel => {
				if (productModel) {
					products.push(productModel);
				}
			});
		}).catch(error => {
			console.error(error);
			throw error;
		});
		if (products.length) {
			productNotFoundPartNumbers = this.extractProductNotFoundPartNumbers(partNumbers, products);
		} else {
			productNotFoundPartNumbers = partNumbers;
		}
		return {
			products: products,
			productNotFoundPartNumbers: productNotFoundPartNumbers
		};
	}
}

const figureProductService = new FigureProductService();
export default figureProductService;
