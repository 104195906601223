import inspectionService from "../../service/inspectionService";
import type ThunkAction from "redux-thunk";
import type {Dispatch, GetState} from "../../actions";
import VkCodeConverter from "../../vehicle/service/VkCodeConverter";

export const INSPECTION_REQUEST = "INSPECTION_REQUEST";
export const INSPECTION_FAILURE = "INSPECTION_FAILURE";
export const INSPECTION_SUCCESS = "INSPECTION_SUCCESS";
export const INSPECTION_RESET = "INSPECTION_RESET";

export function searchInspections(ageInMonths: number, mileageInKm: number, vkCode?: string): ThunkAction {
	return async (dispatch: Dispatch, getState: GetState) => {
		dispatch({
			type: INSPECTION_REQUEST
		});
		try {
			vkCode = vkCode || getState().vehicle.search.vkCode;
			let vkCodeString = "";
			if (vkCode) {
				vkCodeString = VkCodeConverter.convertVkCodeToString(vkCode);
			}
			const inspections = await inspectionService.searchForInspections(vkCodeString, ageInMonths, mileageInKm);
			return dispatch({
				type: INSPECTION_SUCCESS,
				inspections
			});
		} catch (e) {
			return dispatch({
				type: INSPECTION_FAILURE
			});
		}
	};
}

export function resetInspections() {
	return dispatch => {
		dispatch({
			type: INSPECTION_RESET
		});
	};
}
