import * as moment from "moment";
import i18n from "i18next";
import "moment/locale/de";

export function formatDate(date: Date, format: string) {
	return moment(date).locale(i18n.language).format(format);
}

export function daysSince(date: Date) {
	return moment().diff(date, "days");
}

export function getDateDaysAgo(daysCount: number) {
	return moment().subtract(daysCount, "days");
}