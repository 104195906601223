import WheelService from "../service/wheelService";
import type {EnhancedRimModel, TireModel, WheelFilter, WheelModel} from "../models";
import {WheelConfigurationStep} from "../models";
import {enhanceRims, enhanceWheels} from "../service/wheelEnhancer";
import {Dispatch} from "../../cart/actions";
import type {GetState} from "../../actions";
import DocumentService from "../../documentsearch/api/documentService";

export const GET_WHEEL_VEHICLE_MODELS = {
	REQUEST: "GET_WHEEL_VEHICLE_MODELS_REQUEST",
	SUCCESS: "GET_WHEEL_VEHICLE_MODELS_SUCCESS",
	FAILURE: "GET_WHEEL_VEHICLE_MODELS_FAILURE"
};

export const SEARCH_WHEELS = {
	REQUEST: "SEARCH_WHEELS_REQUEST",
	SUCCESS: "SEARCH_WHEELS_SUCCESS",
	FAILURE: "SEARCH_WHEELS_FAILURE"
};

export const ENHANCE_WHEEL_RIM = {
	REQUEST: "ENHANCE_WHEEL_RIM_REQUEST",
	SUCCESS: "ENHANCE_WHEEL_RIM_SUCCESS",
	FAILURE: "ENHANCE_WHEEL_RIM_FAILURE"
};

export const ENHANCE_WHEELS = {
	REQUEST: "ENHANCE_WHEELS_REQUEST",
	SUCCESS: "ENHANCE_WHEELS_SUCCESS",
	FAILURE: "ENHANCE_WHEELS_FAILURE"
};

export const GET_RIM_DOCUMENTS = {
	REQUEST: "GET_RIM_DOCUMENTS_REQUEST",
	SUCCESS: "GET_RIM_DOCUMENTS_SUCCESS",
	FAILURE: "GET_RIM_DOCUMENTS_FAILURE"
};

export const OPEN_RIM_DETAILS_MODAL = "OPEN_RIM_DETAILS_MODAL";
export const OPEN_TIRE_DETAILS_MODAL = "OPEN_TIRE_DETAILS_MODAL";
export const CLOSE_RIM_DETAILS_MODAL = "CLOSE_RIM_DETAILS_MODAL";
export const CLOSE_TIRE_DETAILS_MODAL = "CLOSE_TIRE_DETAILS_MODAL";

export const SET_WHEEL_CONFIGURATION_STEP = "SET_WHEEL_CONFIGURATION_STEP";
export const SET_SELECTED_RIM = "SET_SELECTED_RIM";
export const SET_SELECTED_TIRE = "SET_SELECTED_TIRE";
export const SET_WHEEL_FILTER = "SET_WHEEL_FILTER";

export const GET_RIM_DETAILS = {
	REQUEST: "GET_RIM_DETAILS_REQUEST",
	SUCCESS: "GET_RIM_DETAILS_SUCCESS",
	FAILURE: "GET_RIM_DETAILS_FAILURE"
};

export const setWheelFilter = (wheelFilter: WheelFilter) => {
	return dispatch => {
		dispatch({
			type: SET_WHEEL_FILTER,
			wheelFilter
		});
	};
};

export const setSelectedRim = (rim: EnhancedRimModel) => {
	return dispatch => {
		dispatch({
			type: SET_SELECTED_RIM,
			rim
		});
	};
};

export const setSelectedTire = (tire: TireModel) => {
	return dispatch => {
		dispatch({
			type: SET_SELECTED_TIRE,
			tire
		});
	};
};

export const getRim = (partNumber: string) => {
	return (dispatch: Dispatch) => {
		dispatch({
			type: GET_RIM_DETAILS.REQUEST
		});
		WheelService.getRim(partNumber)
			.then(rim => {
				dispatch({
					type: GET_RIM_DETAILS.SUCCESS,
					rim
				});
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: GET_RIM_DETAILS.FAILURE
				});
			});
	};
};

export const setWheelConfigurationStep = (step: WheelConfigurationStep) => {
	return dispatch => {
		dispatch({
			type: SET_WHEEL_CONFIGURATION_STEP,
			step
		});
	};
};

export const getWheelVehicleFacets = () => {
	return (dispatch: Dispatch) => {
		dispatch({
			type: GET_WHEEL_VEHICLE_MODELS.REQUEST
		});
		WheelService.getWheelVehicleModels()
			.then(vehicleFacets => {
				dispatch({
					type: GET_WHEEL_VEHICLE_MODELS.SUCCESS,
					vehicleModels: vehicleFacets
				});
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: GET_WHEEL_VEHICLE_MODELS.FAILURE
				});
			});
	};
};


export const searchWheels = () => {
	return (dispatch: Dispatch, getState: GetState) => {
		dispatch({
			type: SEARCH_WHEELS.REQUEST
		});
		const wheelFilter = getState().wheel.filterParameter;
		WheelService.getByFilterParameters(wheelFilter)
			.then(payload => {
				dispatch({
					type: SEARCH_WHEELS.SUCCESS, payload
				});
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: SEARCH_WHEELS.FAILURE
				});
			});
	};
};

export const enhanceWheelRims = (rimPartNumbers: string[]) => {
	return dispatch => {
		dispatch({
			type: ENHANCE_WHEEL_RIM.REQUEST
		});
		enhanceRims(rimPartNumbers)
			.then(enhancedRims => {
				dispatch({
					type: ENHANCE_WHEEL_RIM.SUCCESS,
					enhancedRims
				});
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: ENHANCE_WHEEL_RIM.FAILURE
				});
			});
	};
};

export const enhanceWheelModels = (wheels: WheelModel[]) => {
	return dispatch => {
		dispatch({
			type: ENHANCE_WHEELS.REQUEST
		});
		enhanceWheels(wheels)
			.then(enhancedWheels => {
				dispatch({
					type: ENHANCE_WHEELS.SUCCESS,
					enhancedWheels
				});
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: ENHANCE_WHEELS.FAILURE
				});
			});
	};
};

export const getProductDocuments = (partNumber: string) => {
	return dispatch => {
		dispatch({
			type: GET_RIM_DOCUMENTS.REQUEST
		});
		const documentService = new DocumentService();
		documentService.getDocumentsByPartNumber(partNumber)
				.then(response => {
					dispatch({
						type: GET_RIM_DOCUMENTS.SUCCESS,
						documents: response.documents
					});
				});
	};
};

export const openRimDetailsModal = (rim: EnhancedRimModel) => {
	return dispatch => {
		return dispatch(
			{
				type: OPEN_RIM_DETAILS_MODAL,
				rim
			}
		);
	};
};

export const openTireDetailsModal = (wheelModel: WheelModel) => {
	return dispatch => {
		return dispatch(
			{
				type: OPEN_TIRE_DETAILS_MODAL,
				wheelModel
			}
		);
	};
};

export const closeRimDetailsModal = () => {
	return dispatch => {
		return dispatch(
			{
				type: CLOSE_RIM_DETAILS_MODAL
			}
		);
	};
};

export const closeTireDetailsModal = () => {
	return dispatch => {
		return dispatch(
			{
				type: CLOSE_TIRE_DETAILS_MODAL
			}
		);
	};
};