import type {$AxiosXHR, Axios} from "axios";
import {axiosInstance} from "../../api";
import type {Vehicle, VkCode} from "../model";
import type {DealerVehicle} from "../../profile/reducer/profile";
import type {FacetModel} from "../../components/filter/model";

export type VehiclesByVinResponse = {
	vin: string,
	modelCode: string,
	factoryCode: string,
	optionCode: string,
	colorCode: string,
	interiorColorCode: string,
	productionDate: Date,
	decommissionDate: Date,
	vkCode: {
		id: string,
		modelYear: string,
		type: string,
		subType: string,
		engine: string,
		gear: string,
		configuration: string,
		variant: string,
		color: string,
		upholstery: string,
		imageUrl: string,
		translations: Array<{
			language: string,
			modelYear: string,
			type: string,
			subType: string,
			engine: string,
			gear: string,
			configuration: string,
			variant: string,
			color: string,
			upholstery: string
		}>
	}
};

export type VkFacetResponse = {
	name: string,
	facetEntries: Array<{
		value: string,
		count: number,
		label: string
	}>
};

class VehicleClient {
	axios: Axios;

	constructor(axios: Axios) {
		this.axios = axios;
	}

	async getVehiclesByVinPhrase(vinPhrase: string): Promise<Array<Vehicle>> {
		let response = await this.axios.get("/vehicle/search/" + vinPhrase);
		if (response.status !== 200) {
			throw new VehicleClientException();
		}
		return response.data.map(vehicle => {
			return {
				...vehicle,
				vkCode: {...vehicle.vkCode},
				imageUrl: vehicle.vkCode.imageUrl,
				translations: vehicle.vkCode.translations.reduce(
					(translations, value) => {
						if (value.language) {
							translations[value.language] = value;
						}
						return translations;
					}, {}
				)
			};
		});
	}

	async getRecalls(vinPhrase: string) {
		return this.axios.get(`vehicle/recall/${vinPhrase}`)
			.then(res => res.data);
	}

	async getRecallDocuments(recallId: number, download: boolean) {
		return this.axios.get(`vehicle/recall/document/${recallId}`,
			{
				params: {
					download: download
				},
				responseType: "arraybuffer"
			});
	}

	async getVehiclesFromDealerVehicleHistoryByVin(vins: string[]): Promise<Array<Vehicle>> {
		return Promise.all(vins.map(async (vin: string) => {
			return this.axios.get("/vehicle/" + vin, {
				validateStatus: () => true
			}).then(response => {
				if (response.status === 200) {
					return {
						...response.data,
						vkCode: {...response.data.vkCode},
						imageUrl: response.data.vkCode.imageUrl,
						translations: response.data.vkCode.translations.reduce(
							(translations, value) => {
								if (value.language) {
									translations[value.language] = value;
								}
								return translations;
							}, {}
						)
					};
				}
			});
		}));
	}

	async getVehiclesFromDealerVehicleHistory(dealerVehicles: DealerVehicle[]): Promise<Array<Vehicle>> {
		return Promise.all(dealerVehicles.map(async (dealerVehicle: DealerVehicle) => {
			return this.axios.get("/vehicle/" + dealerVehicle.vin, {
				validateStatus: () => true
			}).then(response => {
				if (response.status === 200) {
					return {
						...response.data,
						vkCode: {...response.data.vkCode},
						imageUrl: response.data.vkCode.imageUrl,
						translations: response.data.vkCode.translations.reduce(
							(translations, value) => {
								if (value.language) {
									translations[value.language] = value;
								}
								return translations;
							}, {}
						)
					};
				}
			});
		}));
	}

	async getVkCodeFacets(vkCode: VkCode): Promise<Array<FacetModel>> {
		let response: $AxiosXHR<Array<VkFacetResponse>> = await this.axios.post("/vehicle/facets", {
			vkModelYear: vkCode.modelYear,
			vkType: vkCode.type,
			vkSubType: vkCode.subType,
			vkEngine: vkCode.engine,
			vkGear: vkCode.gear,
			vkConfiguration: vkCode.configuration,
			vkVariant: vkCode.variant
		});
		if (response.status !== 200) {
			throw new VehicleClientException();
		}
		let data: VkFacetResponse[] = response.data;
		return data.map(facet => {
			let name = "";
			switch (facet.name) {
				case "vkModelYear":
					name = "modelYear";
					break;
				case "vkType":
					name = "type";
					break;
				case "vkSubType":
					name = "subType";
					break;
				case "vkEngine":
					name = "engine";
					break;
				case "vkGear":
					name = "gear";
					break;
				case "vkVariant":
					name = "variant";
					break;
				case "vkConfiguration":
					name = "configuration";
					break;
				default:
					break;
			}
			return {
				name,
				facetEntries: facet.facetEntries
			};
		});
	}
}

function VehicleClientException() {
	this.message = "Server error";
	this.name = "VehicleClientException";
}

const VehicleClientInstance = new VehicleClient(axiosInstance);
export default VehicleClientInstance;
