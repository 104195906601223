import type {State} from "../../reducers/rootReducer";
import type {Action} from "../../order/actions/offer";
import {SEND_DOCUMENT_FAILURE, SEND_DOCUMENT_REQUEST, SEND_DOCUMENT_SUCCESS} from "../actions/sendDocument";

const initialState = {
	sent: false,
	sending: false
};

const sendDocument = (state: State = initialState, action: Action) => {
	switch (action.type) {
		case SEND_DOCUMENT_REQUEST:
			return {
				...state,
				sending: true,
				sent: false,
			};
		case SEND_DOCUMENT_SUCCESS:
			return {
				...state,
				sending: false,
				sent: true
			};
		case SEND_DOCUMENT_FAILURE:
			return {
				...state,
				sending: false,
				sent: false
			};
		default:
			return state;
	}
};

export default sendDocument;
