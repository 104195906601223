import type {EventHandler, EventType, VehicleFoundEvent} from "../index";
import store from "../../../configureStore";
import {VEHICLE} from "../../action";

export const VehicleNotFoundEventHandler: EventHandler = {
	handles(eventType: EventType): boolean {
		return eventType === "VEHICLE_NOT_FOUND";
	},
	handle(event: VehicleFoundEvent) {
		store.dispatch({
			type: VEHICLE.NOT_FOUND
		});
	}
};
