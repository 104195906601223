import {INSPECTION_REQUEST, INSPECTION_RESET, INSPECTION_SUCCESS} from "../actions/inspections";
import type {Inspection} from "../../service/inspectionService";
import {GET_INSPECTION_ELEMENTS} from "../../proposal/action";
import type {InspectionData, Proposal} from "../../proposal/model";

export type InspectionState = {
	categories: { [key: string]: Inspection },
	isLoading: boolean,
	currentInspectionData: ?InspectionData,
	isFetchingInspection: boolean,
	proposals: Proposal[],
	current: Proposal,
}

const initialState: InspectionState = {
	categories: {},
	isLoading: false,
	currentInspectionData: null,
	isFetchingInspection: false,
	proposals: [],
	current: null,
};

const inspections = (state: InspectionState = initialState, action) => {
	switch (action.type) {
		case INSPECTION_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case INSPECTION_SUCCESS:
			return {
				...state,
				categories: action.inspections,
				isLoading: false
			};
		case INSPECTION_RESET:
			return {
				...state,
				categories: {},
				isLoading: false,
			};
		case GET_INSPECTION_ELEMENTS.REQUEST: {
			return {
				...state,
				currentInspectionData: {
					name: "",
					workUnits: 0,
					consumables: [],
					parts: [],
					tasks: []
				},
				isFetchingInspection: true
			};
		}

		case GET_INSPECTION_ELEMENTS.SUCCESS: {
			return {
				...state,
				currentInspectionData: action.payload,
				isFetchingInspection: false
			};
		}

		case GET_INSPECTION_ELEMENTS.CLEANUP:
		case GET_INSPECTION_ELEMENTS.FAILURE: {
			return {
				...state,
				currentInspectionData: {
					name: "",
					workUnits: 0,
					consumables: [],
					parts: [],
					tasks: []
				},
				isFetchingInspection: false
			};
		}

		default:
			return state;
	}
};

export default inspections;
