// @flow
import type {CartAction} from "../actions";
import {
	CART_FAILURE,
	CART_REQUEST,
	CART_SUCCESS,
	CLEAR_FAILURE,
	CLEAR_REQUEST,
	CLEAR_SUCCESS,
	ITEM_ENHANCE_REQUEST,
	ITEM_ENHANCE_SUCCESS,
	ITEM_REMOVE_FAILURE,
	ITEM_REMOVE_REQUEST,
	ITEM_REMOVE_SUCCESS
} from "../actions";
import type {CartItemModel, EnhancedCartItemModel} from "../models";
import {StockInformation} from "../../dms/event/model/stockInformation";
import {DMS_ERROR, STOCK_INFORMATION} from "../../dms/action";

export type CartState = {
	+isFetching: boolean,
	+isEnhancing: boolean,
	+items: Array<CartItemModel>,
	+enhancedItems: Array<EnhancedCartItemModel>,
	+localStock: Array<StockInformation>,
	+errorMessage: string
};

const initialState: CartState = {
	isFetching: false,
	isEnhancing: false,
	items: [],
	localStock: [],
	enhancedItems: [],
	errorMessage: ""
};

const cart = (state: CartState = initialState, action: CartAction): CartState => {
	switch (action.type) {
		case CART_REQUEST: {
			return {
				...state,
				localStock: [],
				isFetching: true,
				errorMessage: ""
			};
		}
		case STOCK_INFORMATION.FOUND: {
			return {
				...state,
				localStock: [...state.localStock, action.payload]
					.filter((stock, index, self) => self.findIndex(s => s.partNumber === stock.partNumber) === index),
				errorMessage: ""
			};
		}
		case DMS_ERROR:
			return {
				...state,
				errorMessage: action.error
			};

		case CART_SUCCESS: {
			return {
				...state,
				items: action.cartItems,
				enhancedItems: action.enhancedCartItems,
				isFetching: false,
			};
		}
		case ITEM_REMOVE_REQUEST: {
			const {partNumber} = action;
			return {
				...state,
				enhancedItems: (state.enhancedItems.filter((item: EnhancedCartItemModel) => item.partNumber !== partNumber): Array<EnhancedCartItemModel>),
				items: (state.items.filter((item: CartItemModel) => item.partNumber !== partNumber): Array<CartItemModel>),
			};
		}
		case ITEM_REMOVE_FAILURE: {
			return {
				...state
			};
		}
		case ITEM_REMOVE_SUCCESS: {
			return {
				...state
			};
		}
		case CLEAR_REQUEST: {
			return {
				...state
			};
		}
		case CLEAR_FAILURE: {
			return {
				...state
			};
		}
		case CLEAR_SUCCESS: {
			return {
				...state,
				items: [],
				enhancedItems: [],
				isFetching: false,
			};
		}
		case CART_FAILURE: {
			return {
				...state,
				isFetching: false
			};
		}
		case ITEM_ENHANCE_REQUEST: {
			return {
				...state,
				isEnhancing: true
			};
		}
		case ITEM_ENHANCE_SUCCESS: {
			return {
				...state,
				enhancedItems: action.enhancedItems,
				isEnhancing: false
			};
		}
		default:
			(action: empty); // eslint-disable-line no-unused-expressions
			return state;
	}
};

export default cart;
