class TicketsCache {

	storage = sessionStorage;

	constructor() {
		this.storage.setItem("tickets", JSON.stringify([]));
	}

	addTicket(ticket) {
		let tickets = JSON.parse(this.storage.getItem("tickets"));
		tickets.push(ticket);
		this.storage.setItem("tickets", JSON.stringify(tickets));
	}

	getTickets() {
		return JSON.parse(this.storage.getItem("tickets"));
	}
}

const TicketsCacheInstance = new TicketsCache();
export default TicketsCacheInstance;
