import {ERROR_DIALOG_CLOSE, ERROR_DIALOG_OPEN, ERROR_RESET, ERROR_SET} from "../action/error";

const initialState = {
	error: {},
	showDialog: false
};

export type ErrorState = {
	error: Error,
	showDialog: boolean,
};

const error = (state: ErrorState = initialState, action): ErrorState => {
	switch (action.type) {
		case ERROR_SET:
			return {
				...state,
				error: action.error
			};
		case ERROR_RESET:
			return {
				...state,
				error: {}
			};
		case ERROR_DIALOG_OPEN:
			return {
				...state,
				showDialog: true
			};
		case ERROR_DIALOG_CLOSE:
			return {
				...state,
				showDialog: false
			};
		default:
			return {
				...state
			};
	}
};

export default error;
