// @flow
import type {CartItemModel, EnhancedCartItemModel} from "../models";
import productService from "../../service/productService";

export const enhanceCartItems = async (cartItems: CartItemModel[], withExternalStocks: boolean): Promise<Array<EnhancedCartItemModel>> => {
	const partNumbers = cartItems.map(item => item.partNumber);
	const detailedProducts = await productService.getDetailedProducts(partNumbers, true, true, withExternalStocks);
	return detailedProducts.map(detailedProduct => {
		const cartItem = cartItems.find(item => item.partNumber === detailedProduct.partNumber);
		return {
			...cartItem,
			...detailedProduct
		};
	});
};
