import {LOGIN_SUCCESS} from "../service/authService";
import cartUpdater from "../cart/service/cartUpdater";
import dmsListener from "../dms/service/dmsListener";
import {FETCH_DEALER_DETAILS, TOGGLE_DMS_CLIENT_POLICY} from "../profile/actions";

export const sseSubscriptionRefresher = store => next => action => {
	switch (action.type) {
		case LOGIN_SUCCESS: {
			cartUpdater.refreshEventSource(action.accessToken);
			const profile = store.getState().profile;
			if (profile.dealer && profile.dealer.useDmsClient) dmsListener.refreshEventSource(action.accessToken);
			break;
		}
		case FETCH_DEALER_DETAILS.SUCCESS:
			const {accessToken} = store.getState().auth;
			if (action.payload.useDmsClient) dmsListener.refreshEventSource(accessToken);
			break;
		case TOGGLE_DMS_CLIENT_POLICY: {
			const {useDmsClient} = store.getState().profile.dealer;
			const {accessToken} = store.getState().auth;
			if (useDmsClient) dmsListener.close(accessToken);
			else dmsListener.refreshEventSource(accessToken);
			break;
		}
		default:
			break;
	}
	return next(action);
};
