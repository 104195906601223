// @flow
import gtaService from "../services/gtaService";
import type {Catalog} from "../services/gtaService";

export const CATALOGS_REQUEST = "CATALOGS_REQUEST";
export const CATALOGS_SUCCESS = "CATALOGS_SUCCESS";
export const CATALOGS_FAILURE = "CATALOGS_FAILURE";

export type CatalogsRequest = {
	type: typeof CATALOGS_REQUEST
}

export type CatalogsSuccess = {
	type: typeof CATALOGS_SUCCESS,
	catalogs: Catalog[]
}

export type CatalogsFailure = {
	type: typeof CATALOGS_FAILURE
}

export const getCatalogs = (): CatalogsRequest => {
	return dispatch => {
		dispatch(getCatalogsRequest());
		gtaService.getCatalogs()
			.then(res => dispatch(getCatalogsSuccess(res)))
			.catch(err => {
				console.error(err);
				dispatch(getCatalogsFailure());
			});
	};
};

const getCatalogsRequest = (): CatalogsRequest => ({
	type: CATALOGS_REQUEST
});
const getCatalogsSuccess = (catalogs: Catalog[]): CatalogsSuccess => ({
	type: CATALOGS_SUCCESS,
	catalogs: catalogs
});

const getCatalogsFailure = (): CatalogsFailure => ({
	type: CATALOGS_FAILURE
});

export type CatalogsAction = CatalogsRequest | CatalogsSuccess | CatalogsFailure;
