import type {State} from "../../reducers/rootReducer";
import type {Action} from "../../order/actions/offer";
import {PART_RESET, PART_SET_PAGE, PART_SET_PAGE_SIZE, PART_SET_SEARCH_PATTERN} from "../actions/searchPart";

const initialState = {
	isFetching: false,
	categories: [],
	errorMessage: "",
	searchPattern: "",
	page: 0,
	pageSize: 10,
	totalPages: 0,
	totalItems: 0,
	hasSearched: false
};

const partSearch = (state: State = initialState, action: Action) => {
	switch (action.type) {
		case PART_SET_SEARCH_PATTERN:
			return {
				...state,
				isFetching: true,
				searchPattern: action.searchPattern,
				page: 0,
				totalItems: 0,
				categories: []
			};
		case PART_RESET:
			return Object.assign({}, state, initialState);
		case PART_SET_PAGE:
			return {
				...state,
				page: action.page,
				hasSearched: false
			};
		case PART_SET_PAGE_SIZE:
			return {
				...state,
				pageSize: action.pageSize
			};
		default:
			return {...state};
	}
};

export default partSearch;
