import ArticleService from "../api/articleService";
import {getSupportArticles} from "./articles";
import {computeOverdueTickets, getAllTickets} from "./tickets";

export type ZendeskUser = {
	id: number,
	name: string,
	job: string,
	email: string,
	phone: string,
}

export const GET_ZENDESK_USERS = {
	REQUEST: "GET_ZENDESK_USERS_REQUEST",
	SUCCESS: "GET_ZENDESK_USERS_SUCCESS",
	FAILURE: "GET_ZENDESK_USERS_FAILURE"
};

export const getConversations = () => {
	return dispatch => {
		dispatch(getAllTickets());
		dispatch(getSupportArticles());
		dispatch(computeOverdueTickets());
	};
};

export const getZendeskUsers = () => {
	return dispatch => {
		dispatch({
			type: GET_ZENDESK_USERS.REQUEST
		});
		ArticleService.getZendeskUsers()
			.then(payload => dispatch({
				type: GET_ZENDESK_USERS.SUCCESS, payload
			}))
			.catch(err => {
				console.error(err);
				dispatch({
					type: GET_ZENDESK_USERS.FAILURE
				});
			});
	};
};
