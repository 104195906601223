type SpinnerType = "spinner-default" | "spinner-easter" | "spinner-autumn"

export function spinnerType(): SpinnerType {
	const date = new Date();
	if (
		date > new Date(date.getFullYear(), 8, 22, 0, 0, 0, 0) &&
		date < new Date(date.getFullYear(), 11, 22, 0, 0, 0, 0)
	) {
		return "spinner-autumn";
	}
	return "spinner-default";
}
