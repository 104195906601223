// @flow
import type {OfferBundle, OfferItem} from "../models";
import {push} from "connected-react-router";
import {Trans} from "react-i18next";
import React from "react";
import orderService from "../api/orderService";
import RouteDictionary from "../../RouteDictionary";

export const CALCULATE_OFFERS_REQUEST = "CALCULATE_OFFERS_REQUEST";
export const CALCULATE_OFFERS_SUCCESS = "CALCULATE_OFFERS_SUCCESS";
export const CALCULATE_OFFERS_FAILURE = "CALCULATE_OFFERS_FAILURE";

export const CREATE_ORDERS_REQUEST = "CREATE_ORDERS_REQUEST";
export const CREATE_ORDERS_SUCCESS = "CREATE_ORDERS_SUCCESS";
export const CREATE_ORDERS_FAILURE = "CREATE_ORDERS_FAILURE";

type OptimizeRequestAction = { type: typeof CALCULATE_OFFERS_REQUEST }
type OptimizeFailureAction = { type: typeof CALCULATE_OFFERS_FAILURE, errorMessage: string }
type OptimizeSuccessAction = { type: typeof CALCULATE_OFFERS_SUCCESS, offerBundle: OfferBundle }

export type Action =
	| OptimizeRequestAction
	| OptimizeFailureAction
	| OptimizeSuccessAction;

type GetState = () => any;
type PromiseAction = Promise<Action>;
// eslint-disable-next-line
type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;
export type Dispatch = (action: Action | ThunkAction | PromiseAction) => any;

export function optimizeCart(): ThunkAction {
	return async (dispatch, getState) => {
		dispatch({type: CALCULATE_OFFERS_REQUEST});

		const cart = getState().cart;

		return orderService.optimizeCart(cart.items)
			.then(response => {
				return dispatch({
					type: CALCULATE_OFFERS_SUCCESS,
					offerBundle: response
				});
			})
			.catch(reason => {
				return dispatch({
					type: CALCULATE_OFFERS_FAILURE,
					errorMessage: <Trans i18nKey="orders.errorMessage"/>
				});
			});
	};
}

export function disposeOfferItems(offerItems: OfferItem[]) {
	return (dispatch, getState) => {
		dispatch({type: CREATE_ORDERS_REQUEST});
		const cartItems = getState().cart.items;
		let disposableProducts = offerItems.reduce(
			(prev, curr) => {
				if (prev[curr.productNumber]) {
					prev[curr.productNumber].amount = prev[curr.productNumber].amount + curr.amount;
				} else {
					prev[curr.productNumber] = curr;
				}
				return prev;
			}, {});
		let disposableCartItems = cartItems
			.filter(item => disposableProducts[item.partNumber])
			.map(item => {
				item.amount = disposableProducts[item.partNumber].amount;
				return item;
			});

		return orderService.disposeOrder(disposableCartItems)
			.then(response => {
				dispatch({
					type: CREATE_ORDERS_SUCCESS,
					orderBundle: response.data
				});
				dispatch(push(RouteDictionary.getRoutes().ORDER));
			})
			.catch(reason => {
				return dispatch({
					type: CREATE_ORDERS_FAILURE,
					errorMessage: reason.response.data.message
				});
			});
	};
}
