// @flow
import React from "react";
import type {Channel} from "../model/Channel";

type Props = {
	currentChannel: Channel
};
export const ContactBox = (props: Props) => {
	const {contactDescription, contactBannerUrl} = props.currentChannel;
	return contactDescription || contactBannerUrl
		? <div>
			<section>
				<div className="box">
					<img src={props.currentChannel.contactBannerUrl} alt={""}/>
					<div dangerouslySetInnerHTML={{__html: contactDescription}}/>
				</div>
			</section>
		</div>
		: null;
};
