// @flow

import connect from "react-redux/es/connect/connect";
import {SeriesPage} from "../components/SeriesPage";
import type {State} from "../../reducers/rootReducer";
import type {MenuProps} from "../../components/layout/menu/Menu";

export const COURSE_PAGE_VISITED = "COURSE_PAGE_VISITED";

function mapStateToProps(state: State, ownProps) {
	const {channelId, courseId} = ownProps.match.params;
	const currentChannel = state.medialib.channels.find(channel => channel.id.toString() === channelId);
	const currentSeries = currentChannel
		? currentChannel.channelSeries.find(channelSeries => channelSeries.id.toString() === courseId)
		: null;
	return {
		currentChannel,
		currentSeries
	};
}

function mapDispatchToProps(dispatch) {
	return {
		onPageLoad: (courseId: number) => dispatch(
			{
				type: COURSE_PAGE_VISITED,
				payload: courseId
			}
		)
	};
}

export default connect<MenuProps, _, _, _, _, _>(mapStateToProps, mapDispatchToProps)(SeriesPage);
