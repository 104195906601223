import {Axios} from "axios";
import {axiosInstance} from "../api";

class MailService {
	axios: Axios;

	constructor(axios: Axios) {
		this.axios = axios;
	}

	async sendNotificationAboutMissingPartCode(partCode: string, vin: string, figure: string): Promise<void> {
		await this.axios.post("/product/partcode/send", {
			partIdentifier: partCode,
			vin,
			figure
		});
	};
	async sendNotificationAboutMissingPartNumber(partNumber: string, vin: string, figure: string): Promise<void> {
		await this.axios.post("/product/partNumber/send", {
			partIdentifier: partNumber,
			vin,
			figure
		});
	};
}

const MailServiceInstance = new MailService(axiosInstance);
export default MailServiceInstance;
