import {
	CREATE_DEALER_DETAILS,
	FETCH_CONDITIONS,
	FETCH_CONSUMABLE_PRICE,
	FETCH_DEALER_DETAILS,
	FETCH_DEALER_VEHICLES,
	FETCH_USER_DETAILS,
	UPDATE_CONDITIONS,
	UPDATE_DEALER_DETAILS
} from "../actions";
import type {Vehicle} from "../../vehicle/model";
import type {Price} from "../../proposal/model";

export type UserDetails = {
	accountId: string,
	displayName: string,
	mail: string,
	phone: string
}

export type VehicleConsumable = {
	id: number,
	vehicle: Vehicle,
	amount: number,
	unit: string,
}

export type Consumable = {
	id: number,
	consumableType: string,
	vehicleConsumables: VehicleConsumable[],
	name: string,
	unitPrice: Price,
}
export type ConsumablePrice = {
	price: number,
	currency: string,
	consumable: Consumable
}
export type DealerVehicle = {
	vin: string,
	dealerNumber: string,
	viewDate: string
}
export type DealerDetails = {
	dealerNumber: string,
	companyNumber: string,
	hourlyRate: number,
	currency: string,
	useDmsClient: boolean,
	consumablePrices: ConsumablePrice[],
	contactEmail: string
}

export type ProfileState = {
	isFetchingUser: boolean,
	isFetchingDealer: boolean,
	isFetchingConditions: boolean,
	isFetchingConsumablePrices: boolean,
	isFetchingDealerVehicle: boolean,
	fetchDealerVehicleError: string,
	user: UserDetails,
	dealer: DealerDetails,
	conditions: string,
	consumablePrices: ConsumablePrice[],
	dealerVehicles: DealerVehicle[]
}

const initialState: ProfileState = {
	isFetchingUser: false,
	isFetchingDealer: false,
	isFetchingConditions: false,
	isFetchingConsumablePrices: false,
	isFetchingDealerVehicle: false,
	fetchDealerVehicleError: "",
	conditions: "",
	user: {},
	dealer: {},
	consumablePrices: [],
	dealerVehicles: []
};

export const profile = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_USER_DETAILS.REQUEST:
			return {
				...state,
				isFetchingUser: true
			};
		case FETCH_USER_DETAILS.SUCCESS:
			return {
				...state,
				isFetchingUser: false,
				user: action.payload
			};
		case FETCH_USER_DETAILS.FAILURE:
			return {
				...state,
				isFetchingUser: false
			};
		case FETCH_DEALER_DETAILS.REQUEST:
			return {
				...state,
				isFetchingDealer: true
			};
		case FETCH_DEALER_DETAILS.SUCCESS:
			return {
				...state,
				isFetchingDealer: false,
				dealer: action.payload
			};
		case FETCH_DEALER_DETAILS.FAILURE:
			return {
				...state,
				isFetchingDealer: false
			};
		case CREATE_DEALER_DETAILS.REQUEST:
			return {
				...state,
				isFetchingDealer: true
			};
		case CREATE_DEALER_DETAILS.SUCCESS:
			return {
				...state,
				isFetchingDealer: false,
				dealer: action.payload
			};
		case CREATE_DEALER_DETAILS.FAILURE:
			return {
				...state,
				isFetchingDealer: false
			};
		case UPDATE_DEALER_DETAILS.SUCCESS:
			return {
				...state,
				isFetchingDealer: false,
				dealer: action.payload
			};
		case FETCH_CONDITIONS.REQUEST:
			return {
				...state,
				isFetchingConditions: true
			};
		case FETCH_CONDITIONS.SUCCESS:
			return {
				...state,
				isFetchingConditions: false,
				conditions: action.payload
			};
		case FETCH_CONDITIONS.FAILURE:
			return {
				...state,
				isFetchingConditions: false
			};
		case UPDATE_CONDITIONS.SUCCESS:
			return {
				...state,
				conditions: action.payload
			};
		case FETCH_CONSUMABLE_PRICE.REQUEST:
			return {
				...state,
				isFetchingConsumablePrices: true
			};
		case FETCH_CONSUMABLE_PRICE.SUCCESS:
			return {
				...state,
				isFetchingConsumablePrices: false,
				consumablePrices: action.payload
			};
		case FETCH_CONSUMABLE_PRICE.FAILURE:
			return {
				...state,
				isFetchingConsumablePrices: false
			};
		case FETCH_DEALER_VEHICLES.REQUEST:
			return {
				...state,
				isFetchingDealerVehicle: true
			};
		case FETCH_DEALER_VEHICLES.SUCCESS:
			return {
				...state,
				isFetchingDealerVehicle: false,
				dealerVehicles: action.payload
			};
		case FETCH_DEALER_VEHICLES.FAILURE:
			return {
				...state,
				isFetchingDealerVehicle: false,
				fetchDealerVehicleError: action.error
			};
		default:
			return state;
	}
};
