// @flow
import type {Dispatch} from "../cart/actions";
import supportTicketService from "../service/supportTicketService";

export const CREATE_TICKET_REQUEST = "CREATE_TICKET_REQUEST";
export const CREATE_TICKET_SUCCESS = "CREATE_TICKET_SUCCESS";
export const CREATE_TICKET_FAILURE = "CREATE_TICKET_FAILURE";

const ticketCreateRequest = () => {
	return {
		type: CREATE_TICKET_REQUEST
	};
};

const ticketCreateSuccess = () => {
	return {
		type: CREATE_TICKET_SUCCESS
	};
};

const ticketCreateFailure = () => {
	return {
		type: CREATE_TICKET_FAILURE
	};
};
export function createTicket(name: string, phone: string, mail: string, description: string, category: string, dealerId: string) {
	return async (dispatch: Dispatch) => {
		dispatch(ticketCreateRequest());
		try {
			await supportTicketService.createTicket(name, phone, mail, description, category, dealerId);
			return dispatch(ticketCreateSuccess());
		} catch (e) {
			return dispatch(ticketCreateFailure());
		}
	};
}
