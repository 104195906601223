import cartService from "../../cart/service/CartService";
import type {WatchList} from "../models";
import type {CartItemModel, CartType, EnhancedCartItemModel} from "../../cart/models";
import {enhanceCartItems} from "../../cart/service/cartEnhancer";
import ThunkAction from "redux-thunk";
import type {GetState} from "../../actions";

export const WATCH_LIST_RESET = "WATCH_LIST_RESET";
export const WATCH_LIST_ITEM_ADD_REQUEST = "WATCH_LIST_ITEM_ADD_REQUEST";
export const WATCH_LIST_ITEM_ADD_SUCCESS = "WATCH_LIST_ITEM_ADD_SUCCESS";
export const WATCH_LIST_ITEM_ADD_FAILURE = "WATCH_LIST_ITEM_ADD_FAILURE";
export const WATCH_LIST_ITEM_EDIT_REQUEST = "WATCH_LIST_ITEM_EDIT_REQUEST";
export const WATCH_LIST_ITEM_EDIT_SUCCESS = "WATCH_LIST_ITEM_EDIT_SUCCESS";
export const WATCH_LIST_ITEM_EDIT_FAILURE = "WATCH_LIST_ITEM_EDIT_FAILURE";
export const WATCH_LIST_ITEM_REMOVE_REQUEST = "WATCH_LIST_ITEM_REMOVE_REQUEST";
export const WATCH_LIST_ITEM_REMOVE_SUCCESS = "WATCH_LIST_ITEM_REMOVE_SUCCESS";
export const WATCH_LIST_ITEM_REMOVE_FAILURE = "WATCH_LIST_ITEM_REMOVE_FAILURE";
export const WATCH_LIST_CREATE_REQUEST = "WATCH_LIST_CREATE_REQUEST";
export const WATCH_LIST_CREATE_SUCCESS = "WATCH_LIST_CREATE_SUCCESS";
export const WATCH_LIST_CREATE_FAILURE = "WATCH_LIST_CREATE_FAILURE";
export const WATCH_LIST_DELETE_REQUEST = "WATCH_LIST_DELETE_REQUEST";
export const WATCH_LIST_DELETE_SUCCESS = "WATCH_LIST_DELETE_SUCCESS";
export const WATCH_LIST_DELETE_FAILURE = "WATCH_LIST_DELETE_FAILURE";
export const UPDATE_WATCH_LISTS = "WATCH_LIST_UPDATE_SUCCESS";
export const WATCH_LIST_ITEM_ENHANCE_REQUEST = "WATCH_LIST_ITEM_ENHANCE_REQUEST";
export const WATCH_LIST_ITEM_ENHANCE_SUCCESS = "WATCH_LIST_ITEM_ENHANCE_SUCCESS";
export const WATCH_LIST_ITEM_TRANSFER_REQUEST = "WATCH_LIST_ITEM_TRANSFER_REQUEST";
export const WATCH_LIST_ITEM_TRANSFER_SUCCESS = "WATCH_LIST_ITEM_TRANSFER_SUCCESS";
export const WATCH_LIST_ITEM_TRANSFER_FAILURE = "WATCH_LIST_ITEM_TRANSFER_FAILURE";
export const SET_ACTIVE_WATCHLIST = "SET_ACTIVE_WATCHLIST";
export const WATCH_LIST_MODAL_OPEN = "WATCH_LIST_MODAL_OPEN";
export const WATCH_LIST_MODAL_CLOSE = "WATCH_LIST_MODAL_CLOSE";

const watchListCreateRequest = (watchListName: string) => {
	return {
		type: WATCH_LIST_CREATE_REQUEST,
		watchListName
	};
};

const watchListDeleteSuccess = (watchListName: string) => {
	return {
		type: WATCH_LIST_DELETE_SUCCESS,
		watchListName
	};
};

const watchListDeleteFailure = (error: string, watchListName: string) => {
	return {
		type: WATCH_LIST_DELETE_FAILURE,
		watchListName,
		error
	};
};

const watchListDeleteRequest = (watchListName: string) => {
	return {
		type: WATCH_LIST_DELETE_REQUEST,
		watchListName
	};
};

const watchListCreateSuccess = (watchListName: string) => {
	return {
		type: WATCH_LIST_CREATE_SUCCESS,
		watchListName
	};
};

const watchListCreateFailure = (error: string, watchListName: string) => {
	return {
		type: WATCH_LIST_CREATE_FAILURE,
		watchListName,
		error
	};
};

const transferToCartRequest = () => {
	return {
		type: WATCH_LIST_ITEM_TRANSFER_REQUEST
	};
};

const transferToCartSuccess = (amount: number) => {
	return {
		type: WATCH_LIST_ITEM_TRANSFER_SUCCESS,
		amount
	};
};

const transferToCartFailure = (error: string, watchListName: string) => {
	return {
		type: WATCH_LIST_ITEM_TRANSFER_FAILURE,
		watchListName,
		error
	};
};

const watchListItemAddRequest = (partNumber: string, watchListName: string) => {
	return {
		type: WATCH_LIST_ITEM_ADD_REQUEST,
		watchListName,
		partNumber
	};
};

const watchListItemAddSuccess = (watchListName: string) => {
	return {
		type: WATCH_LIST_ITEM_ADD_SUCCESS,
		watchListName
	};
};

const watchListItemAddFailure = (error: string, watchListName: string) => {
	return {
		type: WATCH_LIST_ITEM_ADD_FAILURE,
		watchListName,
		error
	};
};

const watchListItemRemoveRequest = (partNumber: string, watchListName: string) => {
	return {
		type: WATCH_LIST_ITEM_REMOVE_REQUEST,
		watchListName,
		partNumber
	};
};

const watchListItemRemoveSuccess = (watchListName: string) => {
	return {
		type: WATCH_LIST_ITEM_REMOVE_SUCCESS,
		watchListName
	};
};

const watchListItemRemoveFailure = (error: string, watchListName: string) => {
	return {
		type: WATCH_LIST_ITEM_REMOVE_FAILURE,
		watchListName,
		error
	};
};

const watchListItemEditRequest = (partNumber: string, watchListName: string) => {
	return {
		type: WATCH_LIST_ITEM_EDIT_REQUEST,
		watchListName,
		partNumber
	};
};

const watchListItemEditSuccess = (partNumber: string, watchListName: string) => {
	return {
		type: WATCH_LIST_ITEM_EDIT_SUCCESS,
		watchListName,
		partNumber
	};
};

const watchListItemEditFailure = (error: string, partNumber: string, watchListName: string) => {
	return {
		type: WATCH_LIST_ITEM_EDIT_FAILURE,
		watchListName,
		partNumber,
		error
	};
};

const watchListItemEnhanceRequest = () => {
	return {
		type: WATCH_LIST_ITEM_ENHANCE_REQUEST,
	};
};

const watchListsReset = () => {
	return {
		type: WATCH_LIST_RESET
	};
};

const watchListItemEnhanceSuccess = (enhancedItems: Array<EnhancedCartItemModel>, cartName: string, getState: GetState) => {
	return {
		type: WATCH_LIST_ITEM_ENHANCE_SUCCESS,
		enhancedItems: [...enhancedItems, ...getState().watchList.watchLists.find(watchList => watchList.name === cartName).enhancedItems],
		name: cartName
	};
};

export const setActiveWatchlist = (activeWatchlist: string) => {
	return {
		type: SET_ACTIVE_WATCHLIST,
		activeWatchlist
	};
};

export function createCart(watchListName: string, cartType: CartType): ThunkAction {
	return async dispatch => {
		dispatch(watchListCreateRequest(watchListName));
		try {
			await cartService.createCart(watchListName.trim(), cartType);
			dispatch(watchListCreateSuccess(watchListName));
			return dispatch(setActiveWatchlist(watchListName));
		} catch (e) {
			const message = e.response.data.message;
			return dispatch(watchListCreateFailure(message, watchListName));
		}
	};
}

export function deleteWatchList(watchListName: string): ThunkAction {
	return async dispatch => {
		dispatch(watchListDeleteRequest(watchListName));
		try {
			await cartService.deleteWatchList(watchListName);
			return dispatch(watchListDeleteSuccess(watchListName));
		} catch (e) {
			return dispatch(watchListDeleteFailure(e, watchListName));
		}
	};
}

export function transferToCart(partNumbers: string[], watchListName: string): ThunkAction {
	return async dispatch => {
		dispatch(transferToCartRequest());
		try {
			await cartService.transferItems(
				{
					name: watchListName,
					type: "WATCHLIST"
				}, {
					name: null,
					type: "CART"
				},
				partNumbers
			);
			return dispatch(transferToCartSuccess(partNumbers.length));
		} catch (e) {
			const message = e.response.data.message;
			return dispatch(transferToCartFailure(message, watchListName));
		}
	};
}

export function addWatchListItem(partNumber: string, amount: number, note: string, watchListName: string): ThunkAction {
	return async dispatch => {
		dispatch(watchListItemAddRequest(partNumber, watchListName));
		try {
			await cartService.addCartItem(partNumber, amount, note, watchListName, "WATCHLIST");
			return dispatch(watchListItemAddSuccess(watchListName));
		} catch (e) {
			return dispatch(watchListItemAddFailure(e, watchListName));
		}
	};
}

export function editWatchListItem(partNumber: string, amount: number, watchListName: string): ThunkAction {
	return async dispatch => {
		dispatch(watchListItemEditRequest(partNumber, watchListName));
		try {
			await cartService.editItem(partNumber, "", "", amount, false, watchListName, "WATCHLIST");
			return dispatch(watchListItemEditSuccess(partNumber, watchListName));
		} catch (e) {
			return dispatch(watchListItemEditFailure(e, partNumber, watchListName));
		}
	};
}

export function deleteWatchListItem(partNumber: string, watchListName: string): ThunkAction {
	return async dispatch => {
		dispatch(watchListItemRemoveRequest(partNumber, watchListName));
		try {
			await cartService.removeItem(partNumber, watchListName, "WATCHLIST");
			return dispatch(watchListItemRemoveSuccess(watchListName));
		} catch (e) {
			return dispatch(watchListItemRemoveFailure(e, watchListName));
		}
	};
}

export function updateWatchLists(watchLists: Array<WatchList>): ThunkAction {
	return dispatch => {
		return dispatch({
			type: UPDATE_WATCH_LISTS,
			watchLists
		});
	};
}

export function resetWatchLists(): ThunkAction {
	return dispatch => {
		return dispatch(watchListsReset());
	};
}

export function enhanceWatchListItems(cartItems: CartItemModel[], watchListName: string): ThunkAction {
	return (dispatch, getState) => {
		dispatch(watchListItemEnhanceRequest());
		enhanceCartItems(cartItems, true, false)
			.then(
				enhancedItems => {
					return dispatch(watchListItemEnhanceSuccess(enhancedItems, watchListName, getState));
				}
			);
	};
}

export function openWatchListModal(): ThunkAction {
	return dispatch => {
		return dispatch(
			{
				type: WATCH_LIST_MODAL_OPEN
			}
		);
	};
}

export function closeWatchListModal(): ThunkAction {
	return dispatch => {
		return dispatch(
			{
				type: WATCH_LIST_MODAL_CLOSE
			}
		);
	};
}
