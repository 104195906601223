import {combineReducers} from "redux";
import searchPart from "./searchPart";
import sendDocument from "./sendDocument";

const rootReducer = combineReducers({
	searchPart,
	sendDocument
});

export default rootReducer;
