// @flow
import type {Vehicle} from "../../productsearch/models";
import {
	FACETS_DELETE_FILTER,
	FACETS_RESET_FILTERS,
	FACETS_SET_FILTER,
	PRODUCTS_SUCCESS,
	RESET_PRODUCTS_SEARCH_PHRASE,
	RESET_VIN_SEARCH_PHRASE, SET_PRODUCTS_SEARCH_PARTCODE,
	SET_PRODUCTS_SEARCH_PARTNUMBER,
	SET_PRODUCTS_SEARCH_PHRASE,
	SET_VEHICLESEARCH_TYPE,
	SET_VIN_SEARCH_PHRASE
} from "../../productsearch/actions/search";
import {
	SUGGESTIONS_RESET_PARTCODE,
	SUGGESTIONS_RESET_PARTNUMBER,
	SUGGESTIONS_RESET_SEARCH_WORD
} from "../actions/suggestions";
import type {Category} from "../../documentsearch/components/DocumentList";
import type {PartCode} from "../../gta/models";

export type VehicleState = {
	+vehicle: Vehicle,
	+vehicleSelected: boolean,
	useFacets: boolean,
	vehicleDocuments?: Category[],
	searchPhrase: {
		fuzzy: string,
		vin: string,
		filterParameter: any
	},
	partNumber: string,
	partCode: PartCode,
	tags: any[]
}

const initialState: VehicleState = {
	vehicleSelected: false,
	vehicle: null,
	vehicleDocuments: [],
	useFacets: false,
	facets: [],
	searchPhrase: {
		fuzzy: "",
		vin: "",
		filterParameter: {}
	},
	partNumber: "",
	partCode: {
		partCode: "",
		partCodeSymbol: null
	},
	tags: []
};

const vehicleFilter = (state: VehicleState = initialState, action: any) => {
	let filterParameter = {...state.searchPhrase.filterParameter};

	switch (action.type) {
		case PRODUCTS_SUCCESS:
			return {
				...state,
				tags: [...action.products.facets]
			};
		case SET_VEHICLESEARCH_TYPE:
			return {
				...state,
				useFacets: action.useFacets
			};
		case SET_PRODUCTS_SEARCH_PHRASE:
			return {
				...state,
				searchPhrase: {
					fuzzy: action.searchPhrase,
					vin: state.searchPhrase.vin,
					filterParameter: state.searchPhrase.filterParameter
				}
			};
		case SET_PRODUCTS_SEARCH_PARTNUMBER:
			return {
				...state,
				partNumber: action.partNumber
			};
		case SET_PRODUCTS_SEARCH_PARTCODE:
			return {
				...state,
				partCode: {
					partCode: action.partCode,
					partCodeSymbol: null
				}
			};
		case SUGGESTIONS_RESET_PARTNUMBER:
			return {
				...state,
				partNumber: ""
			};
		case SUGGESTIONS_RESET_PARTCODE:
			return {
				...state,
				partCode: {
					partCode: "",
					partCodeSymbol: null
				}
			};
		case SUGGESTIONS_RESET_SEARCH_WORD:
			return {
				...state,
				searchPhrase: {
					fuzzy: "",
					vin: state.searchPhrase.vin,
					filterParameter: {}
				}
			};

		case RESET_PRODUCTS_SEARCH_PHRASE:
			return {
				...state,
				searchPhrase: {
					fuzzy: "",
					vin: state.searchPhrase.vin,
					filterParameter: {}
				}
			};
		case SET_VIN_SEARCH_PHRASE:
			return {
				...state,
				searchPhrase: {
					fuzzy: state.searchPhrase.fuzzy,
					vin: action.searchPhrase,
					filterParameter: state.searchPhrase.filterParameter
				}
			};
		case RESET_VIN_SEARCH_PHRASE:
			return {
				...state,
				searchPhrase: {
					fuzzy: state.searchPhrase.fuzzy,
					vin: "",
					filterParameter: {}
				}
			};
		case FACETS_SET_FILTER:
			let fieldName = action.fieldName;
			let fieldValue = action.fieldValue;

			if ((filterParameter[fieldName] === undefined) && fieldValue) {
				filterParameter[fieldName] = fieldValue;
			} else if ((fieldValue === "") && (filterParameter[fieldName] !== undefined)) {
				delete filterParameter[fieldName];
			} else {
				filterParameter[fieldName] = fieldValue;
			}
			return {
				...state,
				searchPhrase: {
					fuzzy: state.searchPhrase.fuzzy,
					vin: state.searchPhrase.vin,
					filterParameter
				},
			};
		case FACETS_DELETE_FILTER:
			delete filterParameter[action.fieldName];
			return {
				...state,
				vehicle: null,
				vehicleSelected: false,
				searchPhrase: {
					fuzzy: state.searchPhrase.fuzzy,
					vin: state.searchPhrase.vin,
					filterParameter
				}
			};
		case FACETS_RESET_FILTERS:
			return {
				...state,
				vehicle: null,
				vehicleSelected: false,
				searchPhrase: {
					fuzzy: state.searchPhrase.fuzzy,
					vin: state.searchPhrase.vin,
					filterParameter: {}
				}
			};
		default:
			return state;
	}
};

export default vehicleFilter;
