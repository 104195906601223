// @flow
import { Component, ElementType } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

type ScrollToTopProps = {
	children: ElementType,
	location: any
}

type ScrollToTopState = {

}

class ScrollToTop extends Component<ScrollToTopProps, ScrollToTopState> {
	componentDidUpdate(prevProps: ScrollToTopProps) {
		if (this.props.location !== prevProps.location) {
			window.scrollTo(0, 0);
		}
	}

	render() {
		return this.props.children;
	}
}

ScrollToTop.propTypes = {
	location: PropTypes.any,
	children: PropTypes.element
};

export default withRouter(ScrollToTop);
