// @flow
import type {Axios} from "axios";
import {axiosInstance} from "../../api";

export default class NewsService {
	api: Axios;
	apiPrefix: string;

	constructor() {
		this.api = axiosInstance;
		this.apiPrefix = "/news";
	}

	getNewsById(id: number) {
		return this.api.get(this.apiPrefix + `/${id}`)
			.then(response => response.data);
	}

	getActiveNews(from: ?string, to: ?string) {
		return this.api.get(this.apiPrefix + "/active/", {
			params: {
				from,
				to
			}
		}).then(response => response.data);
	}

	getActiveNewsOfLastMonth() {
		const now: Date = new Date();
		const oneMonthAgo: Date = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
		return this.getActiveNews(oneMonthAgo.toISOString().slice(0, 10), now.toISOString().slice(0, 10));
	}

	getActiveNewsOfLastYear() {
		const now: Date = new Date();
		const oneYearAgo: Date = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
		return this.getActiveNews(oneYearAgo.toISOString().slice(0, 10), now.toISOString().slice(0, 10));
	}
}
