export const ReshipmentModalType =  Object.freeze({
	CREATE: "CREATE_RESHIPMENT",
	REQUEST: "REQUEST_RESHIPMENT",
	DELETE: "DELETE_RESHIPMENT",
	SEND: "SEND_RESHIPMENT"
});

export const Currency = Object.freeze({
	EUR: "EUR",
	UNKNOWN: "UNKNOWN",
});

export const ReshipmentStatus = Object.freeze({
	CREATED: "CREATED",
	REQUESTED: "REQUESTED",
	APPROVED: "APPROVED",
	REJECTED: "REJECTED",
	SENT: "SENT",
});

export type CurrencyModel = {
	currencyCode: $Keys<typeof Currency>,
	currencySymbol: string,
}

export type PriceModel = {
	value: number,
	currency: CurrencyModel,
}

export type ReshipmentModelItem = {
	id: number,
	name: string,
	partNumber: string,
	vin: string,
	singlePrice: PriceModel,
	batchPrice: PriceModel,
	reason: string,
	orderedAmount: number,
	reshippedAmount: number,
}

export type ReshipmentModel = {
	id: number,
	items: ReshipmentModelItem[],
	orderNumber: string,
	reshipmentNumber: string,
	dealerNumber: string,
	dealerName: string,
	contactEmail: string,
	contactPerson: string,
	comment: string,
	status: $Keys<typeof ReshipmentStatus>,
	bundlePrice: PriceModel,
	currency: CurrencyModel,
	creationDate: Date,
	deadlineDate: Date,
	shippingLabels: string[],
	valid: boolean
}

export type CreateReshipmentRequestModel = {
	orderNumber: string,
	item: ReshipmentModelItem,
}

export type SendReshipmentRequestModel = {
	reshipmentId: number,
	contactPerson: string,
	contactEmail: string,
}

export type DeleteReshipmentModel = {
	id: number,
}

export type DeleteReshipmentItemModel = {
	id: number,
}

export type SendReshipmentSendModel = {
	reshipmentId: number,
	shipmentLabels: string[]
}

export type UpdateReshipmentItemRequestModel = {
	reshipmentId: number,
	partNumber: string,
	newItemAmount: number
}