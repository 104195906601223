// @flow
import type {Dispatch} from "../cart/actions";

export const TOGGLE_CUSTOMER_MODE = "TOGGLE_CUSTOMER_MODE";
export const SET_FAQ = "TOGGLE_FAQ";
export const SET_MENU = "TOGGLE_MENU";
export const SET_SUPPORT = "TOGGLE_SUPPORT";
export const SET_GUIDE = "TOGGLE_GUIDE";

export function toggleCustomerMode() {
	return (dispatch: Dispatch) => {
		dispatch({
			type: TOGGLE_CUSTOMER_MODE
		});
	};
}

export function setFaq(payload: boolean) {
	return (dispatch: Dispatch) => {
		dispatch({
			type: SET_FAQ,
			payload
		});
	};
}

export function setMenu(payload: boolean) {
	return (dispatch: Dispatch) => {
		dispatch({
			type: SET_MENU,
			payload
		});
	};
}

export function setSupport(payload: boolean) {
	return (dispatch: Dispatch) => {
		dispatch({
			type: SET_SUPPORT,
			payload
		});
	};
}

export function setGuide(payload: boolean) {
	return (dispatch: Dispatch) => {
		dispatch({
			type: SET_GUIDE,
			payload
		});
	};
}
