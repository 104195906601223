import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import intervalPlural from "i18next-intervalplural-postprocessor";
import MandatorProvider from "../mandator/MandatorProvider";
import resources from "./translations";

export const SET_LANGUAGE = "SET_LANGUAGE";

i18n.use(initReactI18next)
	.use(LanguageDetector)
	.use(intervalPlural)
	.init(
		{
			resources,
			lng: "de",
			fallbackLng: "en",
			debug: true,
			ns: [MandatorProvider.get(), "common"],
			defaultNS: MandatorProvider.get(),
			fallbackNS: "common",
			interpolation: {
				defaultVariables: MandatorProvider.getI18nDefaultInterpolationSet(),
				escapeValue: false,
				formatSeparator: ","
			},
			react: {
				wait: true
			}
		}
	);

export default i18n;
