import type {Axios, AxiosPromise} from "axios";
import {axiosInstance} from "../../api";
import type {ConsumablePrice, DealerDetails, DealerVehicle, UserDetails} from "../reducer/profile";

class DealerService {
	client: Axios;

	constructor() {
		this.client = axiosInstance;
	};

	async getUserDetails(): UserDetails {
		const user = await this.client.get("/user/me");
		return user.data;
	}

	async getDealerDetails(): DealerDetails {
		const dealer = await this.client.get("/dealer/me");
		return dealer.data;
	}

	async createDealer(): AxiosPromise<DealerDetails> {
		const dealer = await this.client.post("/dealer/me");
		return dealer.data;
	}

	async updateDealer(dealerDetails: DealerDetails): DealerDetails {
		const dealer = await this.client.patch("/dealer/me", {...dealerDetails, currency: "EUR"});
		return dealer.data;
	}

	async fetchConditions(): string {
		const conditions = await this.client.get("/dealer/me/conditions");
		return conditions.data;
	}

	async updateConditions(conditions: string): string {
		const updatedConditions = await this.client.patch("/dealer/me/conditions", {conditions});
		return updatedConditions.data;
	}

	async fetchConsumablePrices(): ConsumablePrice[] {
		const consumablePrices = await this.client.get("/dealer/me/consumable-price");
		return consumablePrices.data;
	}

	async updateConsumablePrices(consumablePrices : ConsumablePrice[]): ConsumablePrice[] {
		const updatedConsumablePrices = await this.client.patch("/dealer/me/consumable-price", {consumablePrices});
		return updatedConsumablePrices.data;
	}

	async addDealerVehicleToHistory(vin: string): DealerVehicle {
		const addedDealerVehicle = await this.client.post("/dealer/me/vehicle-history", {vin});
		return addedDealerVehicle.data;
	}

	async fetchDealerVehicleHistory(): DealerVehicle[] {
		const dealerVehicles = await this.client.get("/dealer/me/vehicle-history");
		return dealerVehicles.data;
	}
}

const DealerServiceInstance = new DealerService();
export default DealerServiceInstance;
