import type {EnhancedRimModel, EnhancedWheelModel, TireModel, VehicleModel, WheelFilter, WheelModel} from "../models";
import {WheelConfigurationStep} from "../models";
import {
	CLOSE_RIM_DETAILS_MODAL,
	CLOSE_TIRE_DETAILS_MODAL,
	ENHANCE_WHEEL_RIM,
	ENHANCE_WHEELS,
	GET_RIM_DETAILS,
	GET_RIM_DOCUMENTS,
	GET_WHEEL_VEHICLE_MODELS,
	OPEN_RIM_DETAILS_MODAL,
	OPEN_TIRE_DETAILS_MODAL,
	SEARCH_WHEELS,
	SET_SELECTED_RIM,
	SET_SELECTED_TIRE,
	SET_WHEEL_CONFIGURATION_STEP,
	SET_WHEEL_FILTER
} from "../actions";
import type {Facet} from "../../productsearch/productApi";

export type WheelState = {
	isSearching: boolean,
	isEnhancing: boolean,
	isFetchingRimDetails: boolean,
	isSearchingDocuments: boolean,
	configurationStep: WheelConfigurationStep,
	filterParameter: WheelFilter,
	selectedRim: EnhancedRimModel,
	selectedTire: TireModel,
	wheels: WheelModel[],
	vehicleModels: VehicleModel[],
	enhancedRims: EnhancedRimModel[],
	enhancedWheels: EnhancedWheelModel[],
	rimFacets: Facet[],
	tireFacets: Facet[],
	isRimDetailsModalOpen: boolean,
	isTireDetailsModalOpen: boolean,
	rimDetailsModalContent: EnhancedRimModel,
	tireDetailsModalContent: WheelModel,
}

const initialState: WheelState = {
	isSearching: false,
	isEnhancing: false,
	isFetchingRimDetails: false,
	isSearchingDocuments: false,
	configurationStep: WheelConfigurationStep.VEHICLE,
	filterParameter: {},
	selectedRim: null,
	selectedTire: null,
	wheels: [],
	vehicleModels: [],
	enhancedRims: [],
	enhancedWheels: [],
	rimFacets: [],
	tireFacets: [],
	isRimDetailsModalOpen: false,
	isTireDetailsModalOpen: false,
	rimDetailsModalContent: null,
	tireDetailsModalContent: null,
};

const wheel = (state: WheelState = initialState, action): WheelState => {
	switch (action.type) {
		case SEARCH_WHEELS.REQUEST:
			return {
				...state,
				isSearching: true,
			};
		case SEARCH_WHEELS.SUCCESS:
			return {
				...state,
				isSearching: false,
				wheels: action.payload.content,
				rimFacets: action.payload.rimFacets,
				tireFacets: action.payload.tireFacets
			};
		case SEARCH_WHEELS.FAILURE:
			return {
				...state,
				isSearching: false,
				wheels: [],
				enhancedRims: []
			};
		case ENHANCE_WHEEL_RIM.REQUEST:
			return {
				...state,
				isEnhancing: true
			};
		case ENHANCE_WHEEL_RIM.SUCCESS:
			return {
				...state,
				enhancedRims: action.enhancedRims,
				isEnhancing: false
			};
		case ENHANCE_WHEEL_RIM.FAILURE:
			return {
				...state,
				isEnhancing: false,
				enhancedRims: []
			};
		case SET_WHEEL_FILTER:
			return {
				...state,
				filterParameter: {
					...action.wheelFilter
				}
			};
		case SET_SELECTED_RIM:
			return {
				...state,
				selectedRim: action.rim
			};
		case SET_SELECTED_TIRE:
			return {
				...state,
				selectedTire: action.tire
			};
		case SET_WHEEL_CONFIGURATION_STEP:
			return {
				...state,
				configurationStep: action.step
			};
		case GET_WHEEL_VEHICLE_MODELS.SUCCESS:
			return {
				...state,
				vehicleModels: action.vehicleModels
			};
		case ENHANCE_WHEELS.REQUEST:
			return {
				...state,
				isEnhancing: true
			};
		case ENHANCE_WHEELS.SUCCESS:
			return {
				...state,
				enhancedWheels: action.enhancedWheels,
				isEnhancing: false
			};
		case ENHANCE_WHEELS.FAILURE:
			return {
				...state,
				isEnhancing: false,
			};
		case GET_RIM_DETAILS.REQUEST:
			return {
				...state,
				isFetchingRimDetails: true
			};
		case GET_RIM_DETAILS.SUCCESS:
			return {
				...state,
				rimDetailsModalContent: {
					...state.rimDetailsModalContent,
					...action.rim
				},
				isFetchingRimDetails: false
			};
		case GET_RIM_DETAILS.FAILURE:
			return {
				...state,
				isFetchingRimDetails: false
			};
		case OPEN_RIM_DETAILS_MODAL:
			return {
				...state,
				isRimDetailsModalOpen: true,
				rimDetailsModalContent: {
					...state.rimDetailsModalContent,
					...action.rim
				}
			};
		case OPEN_TIRE_DETAILS_MODAL:
			return {
				...state,
				isTireDetailsModalOpen: true,
				tireDetailsModalContent: action.wheelModel
			};
		case CLOSE_RIM_DETAILS_MODAL:
			return {
				...state,
				isRimDetailsModalOpen: false
			};
		case CLOSE_TIRE_DETAILS_MODAL:
			return {
				...state,
				isTireDetailsModalOpen: false
			};
		case GET_RIM_DOCUMENTS.REQUEST:
			return {
				...state,
				isSearchingDocuments: true
			};
		case GET_RIM_DOCUMENTS.SUCCESS:
			return {
				...state,
				isSearchingDocuments: false,
				rimDetailsModalContent: {
					...state.rimDetailsModalContent,
					documents: action.documents
				}
			};
		default:
			return state;
	}
};

export default wheel;