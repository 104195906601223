import DocumentService from "../api/documentService";
import type {PagedDocuments} from "../api/documentService";

export const SEARCH_REQUEST = "@@DOCUMENT/SEARCH_REQUEST";
export const SEARCH_SUCCESS = "@@DOCUMENT/SEARCH_SUCCESS";
export const SEARCH_FAILURE = "@@DOCUMENT/SEARCH_FAILURE";

export type SearchSuccessAction = {
	type: typeof SEARCH_SUCCESS,
	vehicleDocuments: PagedDocuments
};

export type VehicleDocumentAction = SearchSuccessAction;

export const searchVehicleDocuments = vkCode => {
	const mtcalcCategories = ["ACCESSORIES_BROCHURE", "TECHNICAL_DATA"];
	const documentService = new DocumentService();

	return async dispatch => {
		dispatch({
			type: SEARCH_REQUEST
		});
		try {
			const vehicleDocuments = await documentService.getDocumentsByVkPattern(vkCode, mtcalcCategories);
			dispatch({
				type: SEARCH_SUCCESS,
				vehicleDocuments
			});
		} catch (e) {
			dispatch({
				type: SEARCH_FAILURE
			});
		}
	};
};
