import {forEach, isArray, isDate, isObject} from "axios/lib/utils";

function encode(val) {
	return encodeURIComponent(val)
		.replace(/%3A/gi, ":")
		.replace(/%24/g, "$")
		.replace(/%2C/gi, ",")
		.replace(/%20/g, "+");
}

const paramsSerializer = params => {
	/* eslint no-param-reassign:0 */
	if (!params) {
		return "";
	}
	let parts = [];

	forEach(params, function serialize(val, key) {
		if (val === null || typeof val === "undefined") {
			return;
		}

		if (!isArray(val)) {
			val = [val];
		}

		forEach(val, function parseValue(v) {
			if (isDate(v)) {
				v = v.toISOString();
			} else if (isObject(v)) {
				v = JSON.stringify(v);
			}
			parts.push(encode(key) + "=" + encode(v));
		});
	});

	return parts.join("&");
};

export default paramsSerializer;
