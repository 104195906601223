// @flow

import type {State} from "../../reducers/rootReducer";
import NewsService from "../api/NewsService";
import type {News} from "../models/News";
import type {Dispatch, ThunkAction} from "../../actions";

export const FETCH_ALL_NEWS_REQUEST = "@@news/FETCH_ALL_NEWS_REQUEST";
export const FETCH_ALL_NEWS_SUCCESS = "@@news/FETCH_ALL_NEWS_SUCCESS";
export const FETCH_ALL_NEWS_FAILURE = "@@news/FETCH_ALL_NEWS_FAILURE";

export const getAllNews = () => {
	return (dispatch: Dispatch, getState: () => State) => {
		dispatch({
			type: FETCH_ALL_NEWS_REQUEST
		});

		const newsService = new NewsService();

		newsService.getActiveNewsOfLastYear()
			.then(response => {
				dispatch({
					type: FETCH_ALL_NEWS_SUCCESS,
					payload: response
				});
			})
			.catch(() => {
				dispatch({
					type: FETCH_ALL_NEWS_FAILURE
				});
			});
	};
};

export const FETCH_RECENT_NEWS_REQUEST = "@@news/FETCH_RECENT_NEWS_REQUEST";
export const FETCH_RECENT_NEWS_SUCCESS = "@@news/FETCH_RECENT_NEWS_SUCCESS";
export const FETCH_RECENT_NEWS_FAILURE = "@@news/FETCH_RECENT_NEWS_FAILURE";
export const RESET_RECENT_NEWS = "@@news/RESET_RECENT_NEWS";

export const FETCH_NEWS_REQUEST = "@@news/FETCH_NEWS_REQUEST";
export const FETCH_NEWS_SUCCESS = "@@news/FETCH_NEWS_SUCCESS";
export const FETCH_NEWS_FAILURE = "@@news/FETCH_NEWS_FAILURE";

export type FetchRecentNewsRequest = { type: typeof FETCH_RECENT_NEWS_REQUEST };
export type FetchRecentNewsSuccess = { type: typeof FETCH_RECENT_NEWS_SUCCESS, recentNews: News[] };
export type FetchRecentNewsFailure = { type: typeof FETCH_RECENT_NEWS_FAILURE, errorMessage: string };
export type FetchNewsRequest = { type: typeof FETCH_NEWS_REQUEST };
export type FetchNewsSuccess = { type: typeof FETCH_NEWS_SUCCESS, news: News };
export type FetchNewsFailure = { type: typeof FETCH_NEWS_FAILURE, errorMessage: string };
export type ResetRecentNews = { type: typeof RESET_RECENT_NEWS };

export const fetchRecentNewsRequest = (): FetchRecentNewsRequest => {
	return {
		type: FETCH_RECENT_NEWS_REQUEST
	};
};

export const fetchRecentNewsSuccess = (recentNews: News[]): FetchRecentNewsSuccess => {
	return {
		type: FETCH_RECENT_NEWS_SUCCESS,
		recentNews
	};
};

export const fetchRecentNewsFailure = (errorMessage: string): FetchRecentNewsFailure => {
	return {
		type: FETCH_RECENT_NEWS_FAILURE,
		errorMessage
	};
};

export const fetchNewsRequest = (): FetchNewsRequest => {
	return {
		type: FETCH_NEWS_REQUEST
	};
};

export const fetchNewsSuccess = (news: News): FetchNewsSuccess => {
	return {
		type: FETCH_NEWS_SUCCESS,
		news
	};
};

export const fetchNewsFailure = (errorMessage: string): FetchNewsFailure => {
	return {
		type: FETCH_NEWS_FAILURE,
		errorMessage
	};
};

export const getRecentNews = () => {
	return async (dispatch: Dispatch): Promise<ThunkAction> => {
		dispatch(fetchRecentNewsRequest());
		const newsService = new NewsService();
		try {
			const recentNews = await newsService.getActiveNewsOfLastMonth();
			return dispatch(fetchRecentNewsSuccess(recentNews));
		} catch (e) {
			return dispatch(fetchRecentNewsFailure("Error occurred..."));
		}
	};
};

export const getNews = (id: number) => {
	return async (dispatch: Dispatch): Promise<ThunkAction> => {
		dispatch(fetchNewsRequest());
		const newsService = new NewsService();
		try {
			const news = await newsService.getNewsById(id);
			return dispatch(fetchNewsSuccess(news));
		} catch (e) {
			return dispatch(fetchNewsFailure("Error occurred..."));
		}
	};
};

export type NewsAction =
	| FetchRecentNewsRequest
	| FetchRecentNewsSuccess
	| FetchRecentNewsFailure
	| FetchNewsRequest
	| FetchNewsSuccess
	| FetchNewsFailure
	| ResetRecentNews;
