import maintenanceService from "../service/maintenanceService";
import type {MaintenanceGuideTimeModel} from "../model";
import type {Dispatch, GetState} from "../../actions";
import type {PartCodeModel, ProductResponse} from "../../productsearch/productApi";
import productApi from "../../productsearch/productApi";

export const SET_SELECTED_MAINTENANCE_CATEGORY = "SET_SELECTED_MAINTENANCE_CATEGORY";
export const RESET_SELECTED_MAINTENANCE_CATEGORY = "RESET_SELECTED_MAINTENANCE_CATEGORY";
export const SET_MAINTENANCE_GUIDE_TIME_FILTER = "SET_MAINTENANCE_GUIDE_TIME_FILTER";
export const RESET_MAINTENANCE_TASK_SPARES = "RESET_MAINTENANCE_TASK_SPARES";
export const RESET_MATCHING_GUIDE_TIMES = "RESET_MATCHING_GUIDE_TIMES";
export const SET_SPARE_PART_MAINTENANCE_GUIDE_TIME = "SET_SPARE_PART_MAINTENANCE_GUIDE_TIME";
export const FETCH_MAINTENANCE_SECTIONS_FACETS = {
	REQUEST: "FETCH_MAINTENANCE_SECTIONS_REQUEST",
	SUCCESS: "FETCH_MAINTENANCE_SECTIONS_SUCCESS",
	FAILURE: "FETCH_MAINTENANCE_SECTIONS_FAILURE",
};

export const FETCH_MAINTENANCE_GUIDE_TIME_MODELS = {
	REQUEST: "FETCH_MAINTENANCE_GUIDE_TIME_MODELS_REQUEST",
	SUCCESS: "FETCH_MAINTENANCE_GUIDE_TIME_MODELS_SUCCESS",
	FAILURE: "FETCH_MAINTENANCE_GUIDE_TIME_MODELS_FAILURE",
};

export const FETCH_SPARE_PART_MAINTENANCE_GUIDE_TIME_MODELS = {
	REQUEST: "FETCH_SPARE_PART_MAINTENANCE_GUIDE_TIME_MODELS_REQUEST",
	SUCCESS: "FETCH_SPARE_PART_MAINTENANCE_GUIDE_TIME_MODELS_SUCCESS",
	FAILURE: "FETCH_SPARE_PART_MAINTENANCE_GUIDE_TIME_MODELS_FAILURE",
};

export const FETCH_MAINTENANCE_CATEGORY_ILLUSTRATIONS = {
	REQUEST: "FETCH_MAINTENANCE_CATEGORY_ILLUSTRATIONS_REQUEST",
	SUCCESS: "FETCH_MAINTENANCE_CATEGORY_ILLUSTRATIONS_SUCCESS",
	FAILURE: "FETCH_MAINTENANCE_CATEGORY_ILLUSTRATIONS_FAILURE",
};

export const FETCH_MAINTENANCE_TASK_SPARES = {
	REQUEST: "FETCH_MAINTENANCE_TASK_SPARES_REQUEST",
	SUCCESS: "FETCH_MAINTENANCE_TASK_SPARES_SUCCESS",
	FAILURE: "FETCH_MAINTENANCE_TASK_SPARES_FAILURE",
};

export const setSelectedMaintenanceCategory = (categoryId: number) => {
	return dispatch => {
		dispatch(
			{
				type: SET_SELECTED_MAINTENANCE_CATEGORY,
				payload: categoryId
			});
	};
};

export const resetSelectedMaintenanceCategory = () => {
	return dispatch => {
		dispatch({type: RESET_SELECTED_MAINTENANCE_CATEGORY});
	};
};

export const setMaintenanceGuideTimeFilter = (isActive: boolean) => {
	return dispatch => {
		dispatch(
			{
				type: SET_MAINTENANCE_GUIDE_TIME_FILTER,
				payload: isActive
			});
	};
};

export const getMaintenanceFittingsByCategoryAndModuleNumbersAndVehicle = (categoryId: number, moduleNumbers: string[]) => {
	return async (dispatch, getState) => {
		const {modelYear, type} = getState().proposal.vehicleDetails.vkCode;
		dispatch({type: FETCH_MAINTENANCE_GUIDE_TIME_MODELS.REQUEST});
		try {
			const matchingGuideTimes = await maintenanceService
				.getFittingsByCategoryAndModuleNumbersAndVehicle(
					categoryId,
					moduleNumbers,
					type,
					modelYear
				);
			dispatch({
				type: FETCH_MAINTENANCE_GUIDE_TIME_MODELS.SUCCESS,
				payload: matchingGuideTimes
			});
		} catch (e) {
			dispatch({type: FETCH_MAINTENANCE_GUIDE_TIME_MODELS.FAILURE});
			console.error(e);
		}
	};
};

export const getMaintenanceGuideTimeModelsByPartCodeAndVehicle = (partCode: PartCodeModel, productNumber: number) => {
	return async (dispatch, getState) => {
		const {modelYear, type} = getState().proposal.vehicleDetails.vkCode;
		dispatch({type: FETCH_SPARE_PART_MAINTENANCE_GUIDE_TIME_MODELS.REQUEST});
		try {
			const sparePartMaintenanceGuideTimes = await maintenanceService
				.getFittingsByPartCodeAndVehicle(
					productNumber,
					partCode,
					type,
					modelYear
				);
			dispatch({
				type: FETCH_SPARE_PART_MAINTENANCE_GUIDE_TIME_MODELS.SUCCESS,
				payload: {
					productNumber,
					sparePartMaintenanceGuideTimes
				}
			});
		} catch (e) {
			dispatch({type: FETCH_SPARE_PART_MAINTENANCE_GUIDE_TIME_MODELS.FAILURE});
			console.error(e);
		}
	};
};

export function fetchMaintenanceSectionFacetsByVehicle() {
	return async (dispatch, getState) => {
		const {modelYear, type} = getState().proposal.vehicleDetails.vkCode;
		dispatch({type: FETCH_MAINTENANCE_SECTIONS_FACETS.REQUEST});
		try {
			const sectionFacets = await maintenanceService.getSectionFacetsByVehicle(type, modelYear);
			dispatch({
				type: FETCH_MAINTENANCE_SECTIONS_FACETS.SUCCESS,
				payload: sectionFacets
			});
		} catch (e) {
			dispatch({type: FETCH_MAINTENANCE_SECTIONS_FACETS.FAILURE});
			console.error(e);
		}
	};
}

export function fetchMaintenanceCategoryIllustrations(categoryId: number) {
	return async dispatch => {
		dispatch({type: FETCH_MAINTENANCE_CATEGORY_ILLUSTRATIONS.REQUEST});
		try {
			const illustrations = await maintenanceService.getCategoryIllustrations(categoryId);
			dispatch({
				type: FETCH_MAINTENANCE_CATEGORY_ILLUSTRATIONS.SUCCESS,
				payload: illustrations
			});
		} catch (e) {
			dispatch({type: FETCH_MAINTENANCE_CATEGORY_ILLUSTRATIONS.FAILURE});
			console.error(e);
		}
	};
}

export const resetMaintenanceTaskSpares = () => {
	return dispatch => {
		dispatch(
			{type: RESET_MAINTENANCE_TASK_SPARES});
	};
};

export const resetMatchingGuideTimes = () => {
	return dispatch => {
		dispatch(
			{type: RESET_MATCHING_GUIDE_TIMES});
	};
};

export function fetchProductDetailsForMaintenanceGuideTimes(guideTimeModel: MaintenanceGuideTimeModel, withExternalStocks: boolean) {
	return async (dispatch: Dispatch, getState: GetState) => {
		const factoryCode = getState().proposal.vehicleDetails.factoryCode;
		const destinationCode = getState().proposal.vehicleDetails.destinationCode;
		const modelYearCode = getState().proposal.vehicleDetails.modelYearRestriction;
		const optionCodes = getState().proposal.vehicleDetails.optionCodes;
		const colorCode = getState().proposal.vehicleDetails.colorCode;
		const interiorColorCode = getState().proposal.vehicleDetails.interiorColorCode;
		const vehicleBuildDate = getState().proposal.vehicleDetails.productionDate;
		const vehicleDecommissionDate = getState().proposal.vehicleDetails.decommissionDate;

		dispatch({type: FETCH_MAINTENANCE_TASK_SPARES.REQUEST});
		try {
			const productResponse: ProductResponse = await productApi.getByPartCodeAndMostRelevantPrecisionDetails(
				guideTimeModel.partCodes,
				factoryCode,
				destinationCode,
				modelYearCode,
				optionCodes,
				colorCode,
				interiorColorCode,
				null,
				vehicleBuildDate,
				vehicleDecommissionDate
			);
			let productDetails = [];
			if (!!productResponse.content.length) {
				const partNumbers = productResponse.content.map(p => p.partNo);
				productDetails = await productApi.getProductDetails(withExternalStocks, partNumbers);
			}
			const productsWithDetails = productResponse.content.map(product => {
				const details = productDetails.find(details => product.partNo === details.partNumber);
				return {
					productNumber: product.partNo,
					name: product.name,
					taskDescription: guideTimeModel.taskDescription + " " + guideTimeModel.operationActionDescription,
					retailPrice: details.storage.retailPrice,
					unit: "CHUNK",
					specification: product.specification,
					comment: product.comment,
					quantity: product.quantity
				};
			});
			const response = {
				operationNumber: guideTimeModel.operationNumber,
				description: guideTimeModel.taskDescription + " " + guideTimeModel.operationActionDescription,
				spares: productsWithDetails
			};

			dispatch({
				type: FETCH_MAINTENANCE_TASK_SPARES.SUCCESS,
				payload: response
			});
		} catch (error) {
			dispatch({type: FETCH_MAINTENANCE_TASK_SPARES.FAILURE});
			console.error(error);
			throw error;
		}
	};
}

export const setSparePartMaintenanceGuideTimeModels = (sparePartProductNumber: number) => {
	return dispatch => {
		dispatch({
				type: SET_SPARE_PART_MAINTENANCE_GUIDE_TIME,
				payload: sparePartProductNumber
			});
	};
};