// @flow

import type {EnhancedOrderItem, Order, OrderStatus} from "../models";
import {Trans} from "react-i18next";
import React from "react";
import {addCartItems} from "../../cart/actions";
import orderService from "../api/orderService";

export const FETCH_ORDERS_REQUEST = "FETCH_ORDERS_REQUEST";
export const FETCH_ORDERS_SUCCESS = "FETCH_ORDERS_SUCCESS";
export const FETCH_ORDERS_FAILURE = "FETCH_ORDERS_FAILURE";

export const ENHANCE_ORDER_REQUEST = "ENHANCE_ORDER_REQUEST";
export const ENHANCE_ORDER_SUCCESS = "ENHANCE_ORDER_SUCCESS";
export const ENHANCE_ORDER_FAILURE = "ENHANCE_ORDER_FAILURE";

export const PLACE_DISPOSALS_REQUEST = "PLACE_DISPOSALS_REQUEST";
export const PLACE_DISPOSALS_SUCCESS = "PLACE_DISPOSALS_SUCCESS";
export const PLACE_DISPOSALS_FAILURE = "PLACE_DISPOSALS_FAILURE";

export const PLACE_DISPOSAL_REQUEST = "PLACE_DISPOSAL_REQUEST";
export const PLACE_DISPOSAL_SUCCESS = "PLACE_DISPOSAL_SUCCESS";
export const PLACE_DISPOSAL_FAILURE = "PLACE_DISPOSAL_FAILURE";

export const DELETE_DISPOSAL_REQUEST = "DELETE_DISPOSAL_REQUEST";
export const DELETE_DISPOSAL_SUCCESS = "DELETE_DISPOSAL_SUCCESS";
export const DELETE_DISPOSAL_FAILURE = "DELETE_DISPOSAL_FAILURE";

export const PRINT_DISPOSAL_FAILURE = "PRINT_DISPOSAL_FAILURE";

export const fetchOrders = (dateRange: { from: Date, to: Date }, filterByStatus?: $Values<typeof OrderStatus>) => {
	return (dispatch: any) => {
		dispatch({
			type: FETCH_ORDERS_REQUEST,
			dateRange,
			filterByStatus
		});
		return orderService.getOrders(dateRange.from, dateRange.to, filterByStatus)
			.then(orders => {
				return dispatch({
					type: FETCH_ORDERS_SUCCESS,
					orders
				});
			}).catch(() => {
				return dispatch({
					type: FETCH_ORDERS_FAILURE
				});
			});
	};
};

export const enhanceOrder = (order: Order) => {
	return (dispatch: any) => {
		dispatch({
			type: ENHANCE_ORDER_REQUEST
		});
		return orderService.enhanceOrder(order)
			.then(enhancedOrder => {
				return dispatch({
					type: ENHANCE_ORDER_SUCCESS,
					enhancedOrder
				});
			}).catch(() => {
				return dispatch({
					type: ENHANCE_ORDER_FAILURE
				});
			});
	};
};

export const placeOrder = (dateRange: {
	from: Date,
	to: Date
}, filterByStatus?: $Values<typeof OrderStatus>, disposal: Order) => {
	return (dispatch: any) => {
		dispatch({
			type: PLACE_DISPOSAL_REQUEST,
			disposal
		});
		orderService.placeOrder(disposal.orderId)
			.then(response => {
				dispatch({
					type: PLACE_DISPOSAL_SUCCESS,
					payload: {
						disposalId: disposal.orderId,
						transactionId: response
					}
				});
				return dispatch(fetchOrders(dateRange, filterByStatus));
			})
			.catch(reason => {
				console.error(reason);
				return dispatch({
					type: PLACE_DISPOSAL_FAILURE,
					errorMessage: <Trans i18nKey="orders.errorMessage"/>
				});
			});
	};
};

export const placeOrders = (dateRange: {
	from: Date,
	to: Date
}, filterByStatus?: $Values<typeof OrderStatus>, disposals: Order[]) => {
	return (dispatch: any) => {
		dispatch({type: PLACE_DISPOSALS_REQUEST, disposals});
		return Promise.all(disposals.map(disposalId => orderService.placeOrder(disposalId.orderId)))
			.then(response => {
				dispatch({
					type: PLACE_DISPOSALS_SUCCESS,
					payload: {
						disposalIds: disposals.map(disposal => disposal.orderId),
						transactionId: response
					}
				});
				return dispatch(fetchOrders(dateRange, filterByStatus));
			}).catch(reason => {
				return dispatch({
					type: PLACE_DISPOSALS_FAILURE,
					errorMessage: <Trans i18nKey="orders.errorMessage"/>
				});
			});
	};
};

export const cancelOrder = (dateRange: {
	from: Date,
	to: Date
}, filterByStatus?: $Values<typeof OrderStatus>, disposalId: string) => {
	return (dispatch: any) => {
		dispatch({type: DELETE_DISPOSAL_REQUEST, disposalId});
		return orderService.cancelOrder(disposalId)
			.then(response => {
				dispatch({
					type: DELETE_DISPOSAL_SUCCESS,
				});
				return dispatch(fetchOrders(dateRange, filterByStatus));
			}).catch(reason => {
				return dispatch({
					type: DELETE_DISPOSAL_FAILURE,
					errorMessage: <Trans i18nKey="orders.errorMessage"/>
				});
			});
	};
};

export const disbandOrder = (dateRange: {
	from: Date,
	to: Date
}, filterByStatus?: $Values<typeof OrderStatus>, disposalId: string, items: EnhancedOrderItem[]) => {
	return (dispatch: any) => {
		dispatch(cancelOrder(dateRange, filterByStatus, disposalId))
			.then(() => {
				let cartItems = items.map(item => {
					return {
						partNumber: item.partNumber,
						amount: item.orderedQuantity,
						note: item.note
					};
				});
				return dispatch(addCartItems(cartItems));
			});
	};
};

export const printOrderFailed = (errorMessage: string) => {
	return (dispatch: any) => {
		dispatch({
			type: PRINT_DISPOSAL_FAILURE,
			errorMessage
		});
	};
};
