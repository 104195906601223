// @flow
import * as React from "react";
import "./TicketFilter.scss";
import {Trans} from "react-i18next";

export const TicketStatusFilterOptions = Object.freeze({
	OPEN: "open",
	CLOSED: "closed",
	PENDING: "pending",
});

type Props = {
	ticketStatusFilter: $Values<typeof TicketStatusFilterOptions>,
	setTicketStatusFilter: $Values<typeof TicketStatusFilterOptions> => void
};

export const TicketFilter = ({ticketStatusFilter, setTicketStatusFilter}: Props) => {
	const renderTicketStatus = (status: $Values<typeof TicketStatusFilterOptions>) => (
		<React.Fragment>
			<input onChange={e => setTicketStatusFilter(e.target.value)}
				   className="check"
				   type="radio"
				   name="ticketFilter"
				   id={`ticketFilter-${status}`}
				   value={status}
				   checked={ticketStatusFilter === status}/>
			<label htmlFor={`ticketFilter-${status}`}>
				<span/>
				<Trans i18nKey={`susy.ticket.filter.${status}`}/>
			</label>
		</React.Fragment>
	);

	return <div className={"TicketFilter"}>
		{Object.values(TicketStatusFilterOptions).map(option => renderTicketStatus(option))}
	</div>;
};
