import {VehicleFoundEventHandler} from "./handler/VehicleFoundEventHandler";
import {VehicleNotFoundEventHandler} from "./handler/VehicleNotFoundEventHandler";
import {StockInformationFoundEventHandler} from "./handler/StockInformationFoundEventHandler";
import type {DmsVehicle} from "./model/vehicle";
import type {StockInformation} from "./model/stockInformation";


export type EventType =
	"VEHICLE_FOUND" |
	"VEHICLE_NOT_FOUND" |
	"STOCK_INFORMATION_FOUND" |
	"STOCK_INFORMATION_NOT_FOUND" |
	"DMS_ERROR" |
	"CONNECTION_CLOSED" |
	"UNKNOWN";

export type Event = {
	type: EventType
}

export type EventHandler = {
	handles: EventType => boolean,
	handle: Event => void
}

export type VehicleFoundEvent = Event & DmsVehicle
export type StockInformationFoundEvent = Event & StockInformation

export const getEventHandlers = (): EventHandler[] => {
	return [
		VehicleFoundEventHandler,
		VehicleNotFoundEventHandler,
		StockInformationFoundEventHandler,
	];
};

