// @flow

export const ANSWER_TYPE = {
	SINGLE: "SINGLE",
	MULTIPLE: "MULTIPLE"
};

export type AnswerType = "SINGLE" | "MULTIPLE";

export type Answer = {
	text: string,
	position: string
}

export type Question = {
	position: number,
	text: string,
	answerType: AnswerType,
	answers: Answer[]
}

export type Questionnaire = {
	introduction: string,
	questions: Question[],
}
