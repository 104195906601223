import type {FacetModel} from "../../components/filter/model";
import {
	FETCH_MAINTENANCE_CATEGORY_ILLUSTRATIONS,
	FETCH_MAINTENANCE_GUIDE_TIME_MODELS,
	FETCH_MAINTENANCE_SECTIONS_FACETS,
	FETCH_MAINTENANCE_TASK_SPARES,
	FETCH_SPARE_PART_MAINTENANCE_GUIDE_TIME_MODELS,
	RESET_MAINTENANCE_TASK_SPARES,
	RESET_MATCHING_GUIDE_TIMES,
	RESET_SELECTED_MAINTENANCE_CATEGORY,
	SET_MAINTENANCE_GUIDE_TIME_FILTER,
	SET_SELECTED_MAINTENANCE_CATEGORY, SET_SPARE_PART_MAINTENANCE_GUIDE_TIME
} from "../action";
import type {MaintenanceGuideTimeModel, MaintenanceIllustration} from "../model";
import {
	MaintenanceTaskPosition,
	MatchingMaintenanceGuideTimesResponse
} from "../model";


export type MaintenanceGuideTimeState = {
	isFetchingMaintenanceSectionFacets: boolean,
	isFetchingMatchingMaintenanceGuideTimeModels: boolean,
	isFetchingMaintenanceIllustrations: boolean,
	selectedMaintenanceCategoryId: number,
	maintenanceSectionFacets: FacetModel[],
	matchingMaintenanceGuideTimesResponse: MatchingMaintenanceGuideTimesResponse,
	sparePartGuideTime: {},
	selectedSparePartGuideTimeModels: MaintenanceGuideTimeModel[],
	maintenanceIllustrations: MaintenanceIllustration[],
	isMaintenanceGuideTimeFilterActive: boolean,
	isFetchingMaintenanceTaskSpares: boolean,
	maintenanceTaskPositions: MaintenanceTaskPosition[]
}

const initialState: MaintenanceGuideTimeState = {
	isFetchingMaintenanceSectionFacets: false,
	isFetchingMatchingMaintenanceGuideTimeModels: false,
	isFetchingMaintenanceIllustrations: false,
	selectedMaintenanceCategoryId: null,
	maintenanceSectionFacets: [],
	matchingMaintenanceGuideTimesResponse: null,
	sparePartGuideTime: {},
	selectedSparePartGuideTimeModels: [],
	maintenanceIllustrations: [],
	isMaintenanceGuideTimeFilterActive: false,
	isFetchingMaintenanceTaskSpares: false,
	maintenanceTaskPositions: [],
};

const maintenanceGuideTimeReducer = (state: MaintenanceGuideTimeState = initialState, action): MaintenanceGuideTimeState => {
	switch (action.type) {
		case SET_SELECTED_MAINTENANCE_CATEGORY:
			return {
				...state,
				selectedMaintenanceCategoryId: action.payload
			};
		case RESET_SELECTED_MAINTENANCE_CATEGORY:
			return {
				...state,
				selectedMaintenanceCategoryId: null
			};
		case SET_MAINTENANCE_GUIDE_TIME_FILTER:
			return {
				...state,
				isMaintenanceGuideTimeFilterActive: action.payload
			};
		case FETCH_MAINTENANCE_SECTIONS_FACETS.REQUEST:
			return {
				...state,
				isFetchingMaintenanceSectionsFacets: true
			};
		case FETCH_MAINTENANCE_SECTIONS_FACETS.SUCCESS:
			return {
				...state,
				maintenanceSectionFacets: action.payload,
				isFetchingMaintenanceSectionsFacets: false
			};
		case FETCH_MAINTENANCE_SECTIONS_FACETS.FAILURE:
			return {
				...state,
				isFetchingMaintenanceSectionsFacets: false
			};
		case FETCH_MAINTENANCE_GUIDE_TIME_MODELS.REQUEST:
			return {
				...state,
				isFetchingMatchingMaintenanceGuideTimeModels: true
			};
		case FETCH_MAINTENANCE_GUIDE_TIME_MODELS.SUCCESS:
			return {
				...state,
				matchingMaintenanceGuideTimesResponse: action.payload,
				isFetchingMatchingMaintenanceGuideTimeModels: false
			};
		case FETCH_MAINTENANCE_GUIDE_TIME_MODELS.FAILURE:
			return {
				...state,
				isFetchingMatchingMaintenanceGuideTimeModels: false
			};
		case FETCH_SPARE_PART_MAINTENANCE_GUIDE_TIME_MODELS.REQUEST:
			return {
				...state,
				isFetchingMatchingMaintenanceGuideTimeModels: true
			};
		case FETCH_SPARE_PART_MAINTENANCE_GUIDE_TIME_MODELS.SUCCESS:
			return {
				...state,
				isFetchingMatchingMaintenanceGuideTimeModels: false,
				sparePartGuideTime: {
					...state.sparePartGuideTime,
					...{
						[action.payload.productNumber]: action.payload.sparePartMaintenanceGuideTimes
					}
				},
			};
		case FETCH_SPARE_PART_MAINTENANCE_GUIDE_TIME_MODELS.FAILURE:
			return {
				...state,
				isFetchingMatchingMaintenanceGuideTimeModels: false
			};
		case FETCH_MAINTENANCE_CATEGORY_ILLUSTRATIONS.REQUEST:
			return {
				...state,
				isFetchingMaintenanceIllustrations: true
			};
		case FETCH_MAINTENANCE_CATEGORY_ILLUSTRATIONS.SUCCESS:
			return {
				...state,
				maintenanceIllustrations: action.payload,
				isFetchingMaintenanceIllustrations: false
			};
		case FETCH_MAINTENANCE_CATEGORY_ILLUSTRATIONS.FAILURE:
			return {
				...state,
				isFetchingMaintenanceIllustrations: false
			};
		case FETCH_MAINTENANCE_TASK_SPARES.REQUEST:
			return {
				...state,
				isFetchingMaintenanceTaskSpares: true
			};
		case FETCH_MAINTENANCE_TASK_SPARES.SUCCESS:
			return {
				...state,
				maintenanceTaskPositions: [...state.maintenanceTaskPositions, action.payload],
				isFetchingMaintenanceTaskSpares: false
			};
		case FETCH_MAINTENANCE_TASK_SPARES.FAILURE:
			return {
				...state,
				isFetchingMaintenanceTaskSpares: false
			};
		case RESET_MAINTENANCE_TASK_SPARES:
			return {
				...state,
				maintenanceTaskPositions: []
			};
		case RESET_MATCHING_GUIDE_TIMES:
			return {
				...state,
				matchingMaintenanceGuideTimesResponse: null
			};
		case SET_SPARE_PART_MAINTENANCE_GUIDE_TIME:
			return {
				...state,
				selectedSparePartGuideTimeModels: state.sparePartGuideTime[action.payload].maintenanceGuideTimes,
			};
		default:
			return state;
	}
};

export default maintenanceGuideTimeReducer;