import {FETCH_PROPOSAL} from "../proposal/action";
import {combineEpics, ofType} from "redux-observable";
import proposalService from "../proposal/service/proposalService";
import vehicleClient from "../vehicle/service/vehicleClient";
import {delay, EMPTY, mergeMap, of} from "rxjs";

const fetchProposalEpic = action$ => action$.pipe(
	ofType(FETCH_PROPOSAL.REQUEST),
	mergeMap(action => {
		return proposalService.fetchProposal(action.payload)
			.then(proposal => vehicleClient.getVehiclesByVinPhrase(proposal.vehicle)
				.then(vehicles => {
					const vehicle = vehicles.find(res => res.vin === proposal.vehicle);

					return {
						type: FETCH_PROPOSAL.SUCCESS,
						payload: {
							proposal,
							vehicle
						}
					};
				}
				)
			).catch(e => {
				console.error(e);
				return {
					type: FETCH_PROPOSAL.FAILURE,
					payload: action.payload,
					retry: action.retry
				};
			});
	})
);

const retryFetchingProposal = action$ => action$.pipe(
	ofType(FETCH_PROPOSAL.FAILURE),
	delay(2000),
	mergeMap(action => {
		if (action.retry) {
			return of({
				type: FETCH_PROPOSAL.REQUEST,
				payload: action.payload,
				retry: false
			});
		}
		return EMPTY;
	})
);
export const proposalEpic = combineEpics(fetchProposalEpic, retryFetchingProposal);
