// @flow
import {EventSourcePolyfill} from "event-source-polyfill";
import Configuration from "../../Configuration";
import type {Event} from "../event";
import {getEventHandlers} from "../event";

class DMSListener {
	eventSource: any;
	eventSourceUrl: string;

	constructor() {
		this.eventSourceUrl = `${Configuration.value("dmsBaseUrl")}/my-sse`;
	}

	createEventSource(accessToken: string) {
		return new EventSourcePolyfill(this.eventSourceUrl, {
			headers: {
				Authorization: `Bearer ${accessToken}`
			},
			heartbeatTimeout: 300000
		});
	}

	refreshEventSource(accessToken: string) {
		if (this.eventSource) {
			this.close(accessToken);
		}
		this.eventSource = this.createEventSource(accessToken);
		this.open(accessToken);
	}


	open(accessToken: string) {
		this.eventSource.onmessage = this.handleMessage;
		this.eventSource.onerror = e => {
			if (e.error && e.error.message.includes("No activity within")) return;
			// eslint-disable-next-line no-console
			console.log(e.error.message);
			this.eventSource.close();
			fetch(this.eventSourceUrl, {
				method: "delete",
				headers: new Headers({
					Authorization: `Bearer ${accessToken}`
				})
			}).catch(error => console.error(error));
		};
		this.eventSource.oncomplete = e => {
			// eslint-disable-next-line no-console
			console.log("Closing DmsListener:", e);
			fetch(this.eventSourceUrl, {
				method: "delete",
				headers: new Headers({
					Authorization: `Bearer ${accessToken}`
				})
			}).catch(error => console.error(error));
		};
	}

	handleMessage = evt => {
		try {
			const events: Event[] = JSON.parse(evt.data);
			events.forEach(event => {
					const applicableEventHandler = getEventHandlers().find(handler => handler.handles(event.type));
					if (applicableEventHandler) {
						applicableEventHandler.handle(event);
					} else {
						console.error("No eventhandler configured to handle event: ", event);
					}
				}
			);
		} catch (e) {
			console.error("Failed to handle dms message.");
			console.error(e);
		}
	};

	close(accessToken: string) {
		if (!this.eventSource) {
			return;
		}
		this.eventSource.close();
		fetch(this.eventSourceUrl, {
			method: "delete",
			headers: new Headers({
				Authorization: `Bearer ${accessToken}`
			})
		}).catch(error => console.error(error));
	}
}

const dmsListener = new DMSListener();

export default dmsListener;
