// @flow
import type {ProductSearchState} from "./search";
import productSearch from "./search";
import suggestions from "./suggestions";
import imageGallery from "./imageGallery";
import {combineReducers} from "redux";
import type {InspectionState} from "./inspections";
import inspections from "./inspections";
import type {Action} from "../../actions";
import type {VehicleState} from "./vehicleFilter";
import vehicleFilter from "./vehicleFilter";
import fitments from "./fitments";
import type {FitmentState} from "./fitments";

const rootReducer = combineReducers<_, Action>({
	productSearch,
	suggestions,
	imageGallery,
	vehicleFilter,
	inspections,
	fitments
});

export type SearchState = {
	productSearch: ProductSearchState,
	vehicleFilter: VehicleState,
	inspections: InspectionState,
	fitments: FitmentState
}

export default rootReducer;
