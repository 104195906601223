import ThunkAction from "redux-thunk";
import {ReshipmentModalType, ReshipmentStatus, SendReshipmentSendModel} from "../model";
import type {
	CreateReshipmentRequestModel, DeleteReshipmentItemModel, DeleteReshipmentModel,
	SendReshipmentRequestModel,
	UpdateReshipmentItemRequestModel
} from "../model";
import reshipmentService from "../api/reshipmentService";
import {push} from "connected-react-router";
import RouteDictionary from "../../RouteDictionary";

export const CREATE_RESHIPMENT = {
	REQUEST: "CREATE_RESHIPMENT_REQUEST",
	SUCCESS: "CREATE_RESHIPMENT_SUCCESS",
	FAILURE: "CREATE_RESHIPMENT_FAILURE",
};

export const SET_RESHIPMENT_CREATE_MODAL = "SET_RESHIPMENT_CREATE_MODAL";
export const SET_RESHIPMENT_REQUEST_MODAL = "SET_RESHIPMENT_REQUEST_MODAL";
export const SET_RESHIPMENT_SEND_MODAL = "SET_RESHIPMENT_SEND_MODAL";

export const FETCH_RESHIPMENTS = {
	REQUEST: "FETCH_RESHIPMENT_REQUEST",
	SUCCESS: "FETCH_RESHIPMENT_SUCCESS",
	FAILURE: "FETCH_RESHIPMENT_FAILURE",
};

export const REQUEST_RESHIPMENT = {
	REQUEST: "REQUEST_RESHIPMENT_REQUEST",
	SUCCESS: "REQUEST_RESHIPMENT_SUCCESS",
	FAILURE: "REQUEST_RESHIPMENT_FAILURE",
};

export const UPDATE_RESHIPMENT = {
	REQUEST: "UPDATE_RESHIPMENT_REQUEST",
	SUCCESS: "UPDATE_RESHIPMENT_SUCCESS",
	FAILURE: "UPDATE_RESHIPMENT_FAILURE",
};

export const DELETE_RESHIPMENT = {
	REQUEST: "DELETE_RESHIPMENT_REQUEST",
	SUCCESS: "DELETE_RESHIPMENT_SUCCESS",
	FAILURE: "DELETE_RESHIPMENT_FAILURE",
};

export const DELETE_RESHIPMENT_ITEM = {
	REQUEST: "DELETE_RESHIPMENT_ITEM_REQUEST",
	SUCCESS: "DELETE_RESHIPMENT_ITEM__SUCCESS",
	FAILURE: "DELETE_RESHIPMENT_ITEM__FAILURE",
};

export const SEND_RESHIPMENT = {
	REQUEST: "SEND_RESHIPMENT_REQUEST",
	SUCCESS: "SEND_RESHIPMENT_SUCCESS",
	FAILURE: "SEND_RESHIPMENT_FAILURE",
};

export const createReshipment = (request: CreateReshipmentRequestModel) => {
	return dispatch => {
		dispatch({type: CREATE_RESHIPMENT.REQUEST});
		reshipmentService.createReshipment(request)
			.then(() => dispatch({type: CREATE_RESHIPMENT.SUCCESS}))
			.catch(error => dispatch({type: CREATE_RESHIPMENT.FAILURE}));
	};
};

export const redirectToReshipments = () => {
	return dispatch => dispatch(push(RouteDictionary.getRoutes().RESHIPMENT));
};

export const requestReshipment = (request: SendReshipmentRequestModel) => {
	return dispatch => {
		dispatch({type: REQUEST_RESHIPMENT.REQUEST});
		reshipmentService.requestReshipment(request)
			.then(reshipment => dispatch({
				type: REQUEST_RESHIPMENT.SUCCESS,
				payload: reshipment
			}))
			.catch(error => dispatch({type: REQUEST_RESHIPMENT.FAILURE}));
	};
};

export const sendReshipment = (send: SendReshipmentSendModel) => {
	return dispatch => {
		dispatch({type: SEND_RESHIPMENT.REQUEST});
		reshipmentService.sendReshipment(send)
			.then(reshipment => dispatch({
				type: SEND_RESHIPMENT.SUCCESS,
				payload: reshipment
			}))
			.catch(() => dispatch({type: SEND_RESHIPMENT.FAILURE}));
	};
};

export const setReshipmentRequestModal = (modalType: ReshipmentModalType, payload: boolean) => {
	switch (modalType) {
		case ReshipmentModalType.CREATE:
			return {
				type: SET_RESHIPMENT_CREATE_MODAL,
				payload
			};
		case ReshipmentModalType.REQUEST:
			return {
				type: SET_RESHIPMENT_REQUEST_MODAL,
				payload
			};
		default:
			return null;
	}
};

export const setReshipmentSendModal = (modalType: ReshipmentModalType, payload: boolean) => {
	switch (modalType) {
		case ReshipmentModalType.SEND:
			return {
				type: SET_RESHIPMENT_SEND_MODAL,
				payload
			};
		default:
			return null;
	}
};

export const fetchReshipments = (filterByStatus?: $Values<typeof ReshipmentStatus>, dateRange: { from: Date, to: Date }): ThunkAction => {
	return dispatch => {
		dispatch({
			type: FETCH_RESHIPMENTS.REQUEST,
			filterByStatus,
			dateRange
		});
		reshipmentService.getReshipments(filterByStatus, dateRange.from, dateRange.to)
			.then(reshipments => {
				return dispatch({
					type: FETCH_RESHIPMENTS.SUCCESS,
					payload: reshipments
				});
			}).catch(() => {
			return dispatch({
				type: FETCH_RESHIPMENTS.FAILURE
			});
		});
	};
};

export const updateReshipment = (request: UpdateReshipmentItemRequestModel): ThunkAction => {
	return dispatch => {
		dispatch({type: UPDATE_RESHIPMENT.REQUEST});
		reshipmentService.updateReshipmentItem(request)
			.then(reshipment => {
				return dispatch({
					type: UPDATE_RESHIPMENT.SUCCESS,
					payload: reshipment
				});
			}).catch(() => {
			return dispatch({
				type: UPDATE_RESHIPMENT.FAILURE
			});
		});
	};
};

export const deleteReshipment = (request: DeleteReshipmentModel): ThunkAction => {
	return dispatch => {
		dispatch({type: DELETE_RESHIPMENT.REQUEST});
		reshipmentService.deleteReshipment(request)
			.then(reshipment => {
				return dispatch({
					type: DELETE_RESHIPMENT.SUCCESS,
					payload: reshipment
				});
			}).catch(() => {
				return dispatch({
					type: DELETE_RESHIPMENT.FAILURE
				});
			});
	};
};

export const deleteReshipmentItem = (request: DeleteReshipmentItemModel): ThunkAction => {
	return dispatch => {
		dispatch({type: DELETE_RESHIPMENT_ITEM.REQUEST});
		reshipmentService.deleteReshipmentItem(request)
			.then(reshipmentItem => {
				return dispatch({
					type: DELETE_RESHIPMENT_ITEM.SUCCESS,
					payload: reshipmentItem
				});
			}).catch(() => {
				return dispatch({
					type: DELETE_RESHIPMENT_ITEM.FAILURE
				});
			});
	};
};
