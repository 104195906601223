import type {Dispatch} from "../../cart/actions";
import dealerService from "../service/dealerService";
import type {ConsumablePrice, DealerDetails} from "../reducer/profile";
import {searchVehiclesByDealerVehicleHistory} from "../../vehicle/action";

export const FETCH_USER_DETAILS = {
	REQUEST: "FETCH_USER_DETAILS_REQUEST",
	SUCCESS: "FETCH_USER_DETAILS_SUCCESS",
	FAILURE: "FETCH_USER_DETAILS_FAILURE",
};
export const FETCH_DEALER_DETAILS = {
	REQUEST: "FETCH_DEALER_DETAILS_REQUEST",
	SUCCESS: "FETCH_DEALER_DETAILS_SUCCESS",
	FAILURE: "FETCH_DEALER_DETAILS_FAILURE",
};
export const CREATE_DEALER_DETAILS = {
	REQUEST: "CREATE_DEALER_DETAILS_REQUEST",
	SUCCESS: "CREATE_DEALER_DETAILS_SUCCESS",
	FAILURE: "CREATE_DEALER_DETAILS_FAILURE",
};
export const UPDATE_DEALER_DETAILS = {
	REQUEST: "UPDATE_DEALER_DETAILS_REQUEST",
	SUCCESS: "UPDATE_DEALER_DETAILS_SUCCESS",
	FAILURE: "UPDATE_DEALER_DETAILS_FAILURE",
};
export const FETCH_CONDITIONS = {
	REQUEST: "FETCH_CONDITIONS_REQUEST",
	SUCCESS: "FETCH_CONDITIONS_SUCCESS",
	FAILURE: "FETCH_CONDITIONS_FAILURE",
};
export const UPDATE_CONDITIONS = {
	REQUEST: "UPDATE_CONDITIONS_REQUEST",
	SUCCESS: "UPDATE_CONDITIONS_SUCCESS",
	FAILURE: "UPDATE_CONDITIONS_FAILURE",
};
export const FETCH_CONSUMABLE_PRICE = {
	REQUEST: "FETCH_CONSUMABLE_PRICE_REQUEST",
	SUCCESS: "FETCH_CONSUMABLE_PRICE_SUCCESS",
	FAILURE: "FETCH_CONSUMABLE_PRICE_FAILURE",
};
export const UPDATE_CONSUMABLES_PRICE = {
	REQUEST: "UPDATE_CONSUMABLES_PRICE_REQUEST",
	SUCCESS: "UPDATE_CONSUMABLES_PRICE_SUCCESS",
	FAILURE: "UPDATE_CONSUMABLES_PRICE_FAILURE",
};
export const ADD_DEALER_VEHICLE = {
	REQUEST: "ADD_DEALER_VEHICLE_REQUEST",
	SUCCESS: "ADD_DEALER_VEHICLE_SUCCESS",
	FAILURE: "ADD_DEALER_VEHICLE_FAILURE",
};
export const FETCH_DEALER_VEHICLES = {
	REQUEST: "FETCH_DEALER_VEHICLES_REQUEST",
	SUCCESS: "FETCH_DEALER_VEHICLES_SUCCESS",
	FAILURE: "FETCH_DEALER_VEHICLES_FAILURE",
};
export const TOGGLE_DMS_CLIENT_POLICY = "TOGGLE_DMS_CLIENT_POLICY";

export const getUserDetails = () => {
	return async (dispatch: Dispatch) => {
		dispatch({type: FETCH_USER_DETAILS.REQUEST});
		try {
			const userDetails = await dealerService.getUserDetails();
			return dispatch({
				type: FETCH_USER_DETAILS.SUCCESS,
				payload: userDetails
			});
		} catch (e) {
			return dispatch({type: FETCH_USER_DETAILS.FAILURE});
		}
	};
};

export const createDealer = () => {
	return async (dispatch: Dispatch) => {
		dispatch({type: CREATE_DEALER_DETAILS.REQUEST});
		try {
			const dealerDetails = await dealerService.createDealer();
			return dispatch({
				type: CREATE_DEALER_DETAILS.SUCCESS,
				payload: dealerDetails
			});
		} catch (e) {
			return dispatch({type: CREATE_DEALER_DETAILS.FAILURE});
		}
	};
};

export const getDealerDetails = () => {
	return async (dispatch: Dispatch) => {
		dispatch({type: FETCH_DEALER_DETAILS.REQUEST});
		try {
			const dealerDetails = await dealerService.getDealerDetails();
			return dispatch({
				type: FETCH_DEALER_DETAILS.SUCCESS,
				payload: dealerDetails
			});
		} catch (e) {
			return dispatch({type: FETCH_DEALER_DETAILS.FAILURE});
		}
	};
};

export const toggleDmsClientPolicy = () => {
	return async (dispatch: Dispatch, getState) => {
		dispatch({type: TOGGLE_DMS_CLIENT_POLICY});
		dispatch(updateDealer(
			{
				...getState().profile.dealer,
				useDmsClient: !getState().profile.dealer.useDmsClient
			}
		));
	};
};

export const updateDealer = (dealer: DealerDetails) => {
	return async (dispatch: Dispatch) => {
		dispatch({type: UPDATE_DEALER_DETAILS.REQUEST});
		try {
			const dealerDetails = await dealerService.updateDealer(dealer);
			return dispatch({
				type: UPDATE_DEALER_DETAILS.SUCCESS,
				payload: dealerDetails
			});
		} catch (e) {
			return dispatch({type: UPDATE_DEALER_DETAILS.FAILURE});
		}
	};
};

export const fetchConditions = () => {
	return async (dispatch: Dispatch) => {
		dispatch({type: FETCH_CONDITIONS.REQUEST});
		try {
			const conditions = await dealerService.fetchConditions();
			return dispatch({
				type: FETCH_CONDITIONS.SUCCESS,
				payload: conditions
			});
		} catch (e) {
			return dispatch({type: FETCH_CONDITIONS.FAILURE});
		}
	};
};

export const updateConditions = (conditions: string) => {
	return async (dispatch: Dispatch) => {
		dispatch({type: UPDATE_CONDITIONS.REQUEST});
		try {
			const updatedConditions = await dealerService.updateConditions(conditions);
			return dispatch({
				type: UPDATE_CONDITIONS.SUCCESS,
				payload: updatedConditions
			});
		} catch (e) {
			return dispatch({type: UPDATE_CONDITIONS.FAILURE});
		}
	};
};

export const fetchConsumablePrices = () => {
	return async (dispatch: Dispatch) => {
		dispatch({type: FETCH_CONSUMABLE_PRICE.REQUEST});
		try {
			const conditions = await dealerService.fetchConsumablePrices();
			return dispatch({
				type: FETCH_CONSUMABLE_PRICE.SUCCESS,
				payload: conditions
			});
		} catch (e) {
			return dispatch({
				type: FETCH_CONSUMABLE_PRICE.FAILURE,
				errorMessage: e.response.data.error
			});
		}
	};
};

export function updateConsumablePrices(consumablePrices: ConsumablePrice[]) {
	return async dispatch => {
		dispatch({type: UPDATE_CONSUMABLES_PRICE.REQUEST});
		try {
			const updatedConsumablePrices = await dealerService.updateConsumablePrices(consumablePrices);
			dispatch({
				type: UPDATE_CONSUMABLES_PRICE.SUCCESS,
				payload: updatedConsumablePrices
			});
		} catch (e) {
			dispatch({
				type: UPDATE_CONSUMABLES_PRICE.FAILURE,
				error: e
			});
		}
	};
}

export function addDealerVehicleToHistory(vin: string) {
	return async dispatch => {
		dispatch({type: ADD_DEALER_VEHICLE.REQUEST});
		try {
			dealerService.addDealerVehicleToHistory(vin);
			dispatch({
				type: ADD_DEALER_VEHICLE.SUCCESS
			});
		} catch (e) {
			dispatch({
				type: ADD_DEALER_VEHICLE.FAILURE,
				error: e
			});
		}
	};
}

export function fetchDealerVehicleHistory() {
	return async dispatch => {
		dispatch({type: FETCH_DEALER_VEHICLES.REQUEST});
		try {
			const dealerVehicles = await dealerService.fetchDealerVehicleHistory();
			dispatch({
				type: FETCH_DEALER_VEHICLES.SUCCESS,
				payload: dealerVehicles
			});
			dispatch(searchVehiclesByDealerVehicleHistory());
		} catch (e) {
			dispatch({
				type: FETCH_DEALER_VEHICLES.FAILURE,
				error: e
			});
		}
	};
};
