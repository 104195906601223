import {LOGIN_SUCCESS} from "../service/authService";
import {setAccessToken} from "../api";

const apiTokenMiddleWare = () => next => action => {
	if (action.type === LOGIN_SUCCESS) {
		setAccessToken(action.accessToken);
	}
	return next(action);
};

export default apiTokenMiddleWare;
