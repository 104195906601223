// @flow
import React from "react";
import type {Answer as QuestionnaireAnswer, AnswerType} from "../../model/Questionnaire";
import {ANSWER_TYPE} from "../../model/Questionnaire";

type Props = {
	type: AnswerType,
	index: number,
	answer: QuestionnaireAnswer,
	questionId: number,
};

export const Answer = (props: Props) => {
	if (props.type === ANSWER_TYPE.SINGLE) {
		return <div className={"Answer"} key={props.questionId + props.index}>
			<input name={props.questionId} id={`${props.questionId}-${props.index}`} type="radio"/>
			<label htmlFor={`${props.questionId}-${props.index}`}>{props.answer.text}</label>
		</div>;
	}

	return <div className={"Answer"} key={props.questionId + props.index}>
		<input name={props.questionId} id={`${props.questionId}-${props.index}`} type="checkbox"/>
		<label htmlFor={`${props.questionId}-${props.index}`}>{props.answer.text}</label>
	</div>;
};
