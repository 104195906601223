import DocumentService from "../api/documentService";

export const PART_REQUEST = "PART_REQUEST";
export const PART_SUCCESS = "PART_SUCCESS";
export const PART_FAILURE = "PART_FAILURE";
export const PART_SET_SEARCH_PATTERN = "PART_SET_SEARCH_PATTERN";
export const PART_RESET = "PART_RESET";
export const PART_SET_PAGE = "PART_SET_PAGE";
export const PART_SET_PAGE_SIZE = "PART_SET_PAGE_SIZE";

export function onSearchPartNumber(partNumber) {
	return dispatch => {
		dispatch({
			type: PART_SET_SEARCH_PATTERN,
			searchPattern: partNumber
		});
		return dispatch(partSearchDocuments(partNumber));
	};
}

export function partSearchDocuments(partNumbers) {
	return dispatch => {
		dispatch({
			type: PART_REQUEST
		});
		const documentService = new DocumentService();
		partNumbers.map(partNumber => {
			return documentService.getDocumentsByPartNumber(partNumber)
				.then(response => {
					dispatch({
						type: PART_SUCCESS,
						response: {
							partNumber,
							categories: response.categories
						}
					});
				});
		});
	};
}

export function resetPartSearch() {
	return dispatch => {
		dispatch({
			type: PART_RESET
		});
	};
}
