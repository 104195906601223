import {PRODUCT_FITMENT_MODAL_CLOSE, PRODUCT_FITMENT_MODAL_OPEN} from "../../productsearch/actions/fitment";
import type {Fitment} from "../../mandator/SU/components/productSearch/ProductTable";

export type FitmentState = {
	isProductFitmentModalOpen: boolean,
	currentVehicleFitments: Fitment[]
}

const initialState: FitmentState = {
	isProductFitmentModalOpen: false,
	currentVehicleFitments: []
};

const fitments = (state: FitmentState = initialState, action) => {
	switch (action.type) {
		case PRODUCT_FITMENT_MODAL_OPEN: {
			return {
				...state,
				isProductFitmentModalOpen: true,
				currentVehicleFitments: action.payload
			};
		}
		case PRODUCT_FITMENT_MODAL_CLOSE: {
			return {
				...state,
				isProductFitmentModalOpen: false,
				currentVehicleFitments: null
			};
		}
		default:
			return state;
	}
};

export default fitments;