import commonEn from "./common/en.json";
import commonDe from "./common/de.json";
import commonCs from "./common/cs.json";
import commonFr from "./common/fr.json";
import commonHu from "./common/hu.json";
import commonPl from "./common/pl.json";
import SUEn from "./SU/en.json";
import SUDe from "./SU/de.json";
import SUCs from "./SU/cs.json";
import SUFr from "./SU/fr.json";
import SUHu from "./SU/hu.json";
import SUPl from "./SU/pl.json";
import DHEn from "./DH/en.json";
import DHDe from "./DH/de.json";
import DHCs from "./DH/cs.json";
import DHFr from "./DH/fr.json";
import DHHu from "./DH/hu.json";
import DHPl from "./DH/pl.json";

const resources = {
	en: {
		common: commonEn,
		SU: SUEn,
		DH: DHEn,
	},
	de: {
		common: commonDe,
		SU: SUDe,
		DH: DHDe,
	},
	cs: {
		common: commonCs,
		SU: SUCs,
		DH: DHCs,
	},
	fr: {
		common: commonFr,
		SU: SUFr,
		DH: DHFr,
	},
	hu: {
		common: commonHu,
		SU: SUHu,
		DH: DHHu,
	},
	pl: {
		common: commonPl,
		SU: SUPl,
		DH: DHPl,
	}
};

export default resources;
