// @flow
import {
	TOGGLE_CUSTOMER_MODE,
	SET_FAQ,
	SET_MENU,
	SET_SUPPORT,
	SET_GUIDE,
} from "../actions/option";

export type OptionState = {
	isCustomerMode: boolean,
	isMenuOpen: boolean,
	isSupportOpen: boolean,
	isFaqOpen: boolean,
	isGuideOpen: boolean
}

const initialState: OptionState = {
	isCustomerMode: false,
	isMenuOpen: false,
	isSupportOpen: false,
	isFaqOpen: false,
	isGuideOpen: false,
};

const option = (state: OptionState = initialState, action: any) => {
	switch (action.type) {
		case TOGGLE_CUSTOMER_MODE:
			return {
				...state,
				isCustomerMode: action.payload || !state.isCustomerMode
			};

		case SET_MENU:
			return {
				...state,
				isMenuOpen: action.payload
			};

		case SET_GUIDE:
			return {
				...state,
				isGuideOpen: action.payload
			};

		case SET_SUPPORT:
			return {
				...state,
				isSupportOpen: action.payload
			};

		case SET_FAQ:
			return {
				...state,
				isFaqOpen: action.payload
			};

		default:
			return state;
	}
};

export default option;
