import {CALL_API} from "../../middleware/apiMiddleware";

export const SEND_DOCUMENT_REQUEST = "SEND_DOCUMENT_REQUEST";
export const SEND_DOCUMENT_SUCCESS = "SEND_DOCUMENT_SUCCESS";
export const SEND_DOCUMENT_FAILURE = "SEND_DOCUMENT_FAILURE";

export function sendDocument(documentId, addressee, message) {
	return dispatch => {
		return dispatch({
			[CALL_API]: {
				types: [SEND_DOCUMENT_REQUEST, SEND_DOCUMENT_SUCCESS, SEND_DOCUMENT_FAILURE],
				endpoint: "/v1/document/send",
				method: "POST",
				body: JSON.stringify({
					documentId,
					addressee,
					message
				}),
				authenticated: true
			}
		});
	};
}
