import ThunkAction from "redux-thunk";
import type {Fitment} from "../../mandator/SU/components/productSearch/ProductTable";

export const PRODUCT_FITMENT_MODAL_OPEN = "PRODUCT_FITMENT_MODAL_OPEN";
export const PRODUCT_FITMENT_MODAL_CLOSE = "PRODUCT_FITMENT_MODAL_CLOSE";

export function openProductFitmentModal(fitments: Fitment[]): ThunkAction {
	return dispatch => {
		return dispatch(
			{
				type: PRODUCT_FITMENT_MODAL_OPEN,
				payload: fitments
			}
		);
	};
}

export function closeProductFitmentModal(): ThunkAction {
	return dispatch => {
		return dispatch(
			{
				type: PRODUCT_FITMENT_MODAL_CLOSE
			}
		);
	};
}