/* eslint-disable react-hooks/exhaustive-deps */
// @flow
import React, {Fragment} from "react";
import type {Channel} from "../model/Channel";
import type {Series} from "../model/Series";
import {ContactBox} from "./ContactBox";
import {PlaylistBox} from "./PlaylistBox";
import type {Video} from "../model/Video";
import QuestionnaireContainer from "../container/QuestionnaireContainer";
import {Link} from "react-router-dom";
import RouteDictionary from "../../RouteDictionary";

type AcademyCoursePageProps = {
	currentChannel: Channel,
	currentSeries: Series,
	onPageLoad: number => void
};

export const SeriesPage = (props: AcademyCoursePageProps) => {
	React.useEffect(() => {
		if (props.currentChannel && props.currentSeries) {
			props.onPageLoad(props.currentSeries.id);
		}
	}, []);
	const [cinemaMode, setCinemaMode] = React.useState(false);
	const [currentVideoIndex, setCurrentVideoIndex] = React.useState(0);
	const [showQuestionnaire, setShowQuestionnaire] = React.useState(false);
	if (!props.currentChannel || !props.currentSeries) return null;
	const video: Video = props.currentSeries.videos[currentVideoIndex];

	const renderSeriesDescription = () => {
		return props.currentSeries.seriesDescription
			? <div className="paragraph"
				   dangerouslySetInnerHTML={{__html: props.currentSeries.seriesDescription}}/>
			: null;
	};

	const renderQuestionnaire = () => {
		return props.currentSeries.questionnaire
			? <React.Fragment>
				<hr/>
				<QuestionnaireContainer currentChannel={props.currentChannel} currentSeries={props.currentSeries}/>
			</React.Fragment>
			: null;
	};

	return <React.Fragment>
		<div className={cinemaMode ? "seriesPage cinemaMode" : "seriesPage"}>
			<div>
				<Link to={`${RouteDictionary.getRoutes().MEDIA_LIB}/channel/${props.currentChannel.id}`}
					  className={"channelLine"}>
					<div style={{backgroundImage: `url(${props.currentChannel.avatarUrl})`}}
						 className="avatar"/>
					<h2>{props.currentChannel.channelName}</h2>
					<h3>{props.currentSeries.seriesName}</h3>
				</Link>
				{!showQuestionnaire &&
					<Fragment>
						<section className={"videoContainer"}>
							<div className={cinemaMode ? "cinemaBar cinemaMode" : "cinemaBar"}>
								<button onClick={() => setCinemaMode(!cinemaMode)}/>
							</div>
							<iframe
								title={video.videoName} src={`${video.videoUrl}?rel=0`}
								frameBorder="0"
								allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen
							/>
						</section>
						<section>
							<h2>{props.currentSeries.seriesName}</h2>
						</section>
						{renderSeriesDescription()}
					</Fragment>
				}
				{showQuestionnaire && renderQuestionnaire()}

			</div>
			<div>
				{props.currentSeries.videos.length > 1 &&
					<PlaylistBox
						currentVideoIndex={currentVideoIndex}
						setCurrentVideoIndex={videoIndex => {
							setCurrentVideoIndex(videoIndex);
							setShowQuestionnaire(false);
						}}
						switchToQuestionnaire={() => {
							setShowQuestionnaire(true);
							setCurrentVideoIndex(-1);
						}}
						questionnaireIsActive={showQuestionnaire}
						renderQuestionnaireBox={!!props.currentSeries.questionnaire}
						videos={props.currentSeries.videos}
					/>}
				<br/>
				<ContactBox currentChannel={props.currentChannel}/>
			</div>
		</div>
	</React.Fragment>;
};
