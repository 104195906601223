import {formatDate} from "../../i18n/dateService";
import type {CustomField} from "../reducer/articlesReducer";
import type {CreateTicketParams} from "../container/Tickets/TicketAddendum";

export type VehicleModel = {
	vin: string,
	type: string,
	modelYear: string,
	subType: string,
	engine: string,
	gear: string,
	variant: string,
	configuration: string,
	colorCode: string,
	color: string,
	upholstery: string,
	destinationCode: string,
	initialRegistrationDate: string,
}

export type CategoryKeysType =
	""
	| "engine"
	| "gear"
	| "electrics"
	| "bodywork"
	| "infotainment"
	| "chassis"
	| "diagnostic_tool"
	| "other"

export const CategoryKey = {
	ENGINE: "engine",
	GEAR: "gear",
	ELECTRICS: "electrics",
	BODYWORK: "bodywork",
	INFOTAINMENT: "infotainment",
	CHASSIS: "chassis",
	DIAGNOSTIC_TOOL: "diagnostic_tool",
	OTHER: "other",
};

export type Status = {
	NEW: string,
	PENDING: string,
	OPEN: string,
	CLOSED: string,
};

const customFields = {
	"CATEGORY": {id: 360030184017, ordinal: 0},
	"DEALER_NUMBER": {id: 360028535257, ordinal: 5},
	"USER_NAME": {id: 360029031918, ordinal: 1},
	"USER_FUNCTION": {id: 360029248337, ordinal: 2},
	"SYMPTOM_CODE": {id: 1900005558273, ordinal: 21},
	"ERROR_CODE": {id: 1900004649273, ordinal: 20},
	"VIN": {id: 360029248357, ordinal: 5},
	"MILE_AGE": {id: 360029117438, ordinal: 18},
	"CONFIGURATION": {id: 4415537499025, ordinal: 11},
	"REGISTRATION_DATE": {id: 360029248377, ordinal: 17},
	"TYPE": {id: 1900004649433, ordinal: 6},
	"ENGINE": {id: 360029117598, ordinal: 9},
	"GEAR": {id: 1900004649453, ordinal: 10},
	"MODEL_YEAR": {id: 360029248397, ordinal: 7},
	"SUBTYPE": {id: 1900004649473, ordinal: 8},
	"TARGET_CODE": {id: 1900004649573, ordinal: 16},
	"VARIANT": {id: 1900004649513, ordinal: 12},
	"VEHICLE_COLOR": {id: 1900004649593, ordinal: 13},
	"COLOR_CODE": {id: 360029118058, ordinal: 14},
	"UPHOLSTERY": {id: 360029248757, ordinal: 15},
	"NOT_ROADWORTHY": {id: 13193970158353, ordinal: 22},
};

export const getCustomFieldId = (key: string) => {
	return customFields[key].id;
};

export const getCustomFieldKey = (id: number) => {
	const entry = Object.entries(customFields).find(entry => entry[1].id === id);
	if (entry) {
		return {
			name: entry[0],
			ordinal: entry[1].ordinal
		};
	}
	return {name: "ERR", ordinal: -1};
};

export const createCustomFieldsFromUser = (params: CreateTicketParams, companyNumber): CustomField[] => {
	const result = [];
	if (companyNumber) {
		result.push({
			id: getCustomFieldId("DEALER_NUMBER"),
			content: [companyNumber]
		});
	}
	if (params.name) {
		result.push({
			id: getCustomFieldId("USER_NAME"),
			content: [params.name]
		});
	}
	if (params.job) {
		result.push({
			id: getCustomFieldId("USER_FUNCTION"),
			content: [params.job]
		});
	}
	if (params.category) {
		result.push({
			id: getCustomFieldId("CATEGORY"),
			content: [params.category]
		});
	}
	return result;
};

export const createCustomFieldsFromVehicle = (vehicle, mileage, errorCode, notRoadworthy): CustomField[] => {
	const enhancedVehicle: VehicleModel = vehicle
		? {
			vin: vehicle.vin,
			type: vehicle.translations["de"].type,
			modelYear: vehicle.translations["de"].modelYear,
			subType: vehicle.translations["de"].subType,
			engine: vehicle.translations["de"].engine,
			gear: vehicle.translations["de"].gear,
			variant: vehicle.translations["de"].variant,
			configuration: vehicle.translations["de"].configuration,
			colorCode: vehicle.vkCode.color,
			color: vehicle.translations["de"].color,
			upholstery: vehicle.translations["de"].upholstery,
			destinationCode: vehicle.destinationCode,
			initialRegistrationDate: vehicle.initialRegistrationDate ? formatDate(vehicle.initialRegistrationDate, "DD.MM.yyyy") : null
		}
		: {
			vin: null,
			type: null,
			modelYear: null,
			subType: null,
			engine: null,
			gear: null,
			variant: null,
			configuration: null,
			colorCode: null,
			color: null,
			upholstery: null,
			destinationCode: null,
			initialRegistrationDate: null
		};

	const result = [];

	if (mileage) {
		result.push({
			id: getCustomFieldId("MILE_AGE"),
			content: [mileage]
		});
	}
	if (errorCode) {
		result.push({
			id: getCustomFieldId("ERROR_CODE"),
			content: [errorCode]
		});
	}

	if (notRoadworthy) {
		result.push({
			id: getCustomFieldId("NOT_ROADWORTHY"),
			content: [notRoadworthy]
		});
	}

	if (enhancedVehicle.vin) {
		result.push({
			id: getCustomFieldId("VIN"),
			content: [enhancedVehicle.vin]
		});
	}
	if (enhancedVehicle.type) {
		result.push({
			id: getCustomFieldId("TYPE"),
			content: [enhancedVehicle.type]
		});
	}
	if (enhancedVehicle.modelYear) {
		result.push({
			id: getCustomFieldId("MODEL_YEAR"),
			content: [enhancedVehicle.modelYear]
		});
	}
	if (enhancedVehicle.subType) {
		result.push({
			id: getCustomFieldId("SUBTYPE"),
			content: [enhancedVehicle.subType]
		});
	}
	if (enhancedVehicle.engine) {
		result.push({
			id: getCustomFieldId("ENGINE"),
			content: [enhancedVehicle.engine]
		});
	}
	if (enhancedVehicle.gear) {
		result.push({
			id: getCustomFieldId("GEAR"),
			content: [enhancedVehicle.gear]
		});
	}
	if (enhancedVehicle.variant) {
		result.push({
			id: getCustomFieldId("VARIANT"),
			content: [enhancedVehicle.variant]
		});
	}
	if (enhancedVehicle.configuration) {
		result.push({
			id: getCustomFieldId("CONFIGURATION"),
			content: [enhancedVehicle.configuration]
		});
	}
	if (enhancedVehicle.colorCode) {
		result.push({
			id: getCustomFieldId("COLOR_CODE"),
			content: [enhancedVehicle.colorCode]
		});
	}
	if (enhancedVehicle.color) {
		result.push({
			id: getCustomFieldId("VEHICLE_COLOR"),
			content: [enhancedVehicle.color]
		});
	}
	if (enhancedVehicle.upholstery) {
		result.push({
			id: getCustomFieldId("UPHOLSTERY"),
			content: [enhancedVehicle.upholstery]
		});
	}
	if (enhancedVehicle.destinationCode) {
		result.push({
			id: getCustomFieldId("TARGET_CODE"),
			content: [enhancedVehicle.destinationCode]
		});
	}
	if (enhancedVehicle.initialRegistrationDate) {
		result.push({
			id: getCustomFieldId("REGISTRATION_DATE"),
			content: [enhancedVehicle.initialRegistrationDate]
		});
	}

	return result;
};
