// @flow
import {
	DELETE_DISPOSAL_FAILURE,
	DELETE_DISPOSAL_REQUEST,
	DELETE_DISPOSAL_SUCCESS,
	ENHANCE_ORDER_FAILURE,
	ENHANCE_ORDER_REQUEST,
	ENHANCE_ORDER_SUCCESS,
	FETCH_ORDERS_FAILURE,
	FETCH_ORDERS_REQUEST,
	FETCH_ORDERS_SUCCESS,
	PLACE_DISPOSALS_FAILURE,
	PLACE_DISPOSALS_REQUEST,
	PLACE_DISPOSALS_SUCCESS
} from "../actions/orders";
import type {Order} from "../models";

type OrderState = {
	fetchingOrders: boolean,
	fetchingOrdersErrorMessage: string,
	disposals: Order[]
}

const initialState: OrderState = {
	fetchingOrders: false,
	fetchingOrdersErrorMessage: "",
	disposals: []
};

const cart = (state: OrderState = initialState, action: any) => {
	switch (action.type) {
		case FETCH_ORDERS_REQUEST:
			return {
				...state,
				fetchingOrders: true,
				fetchingOrdersErrorMessage: ""
			};
		case FETCH_ORDERS_SUCCESS:
			return {
				...state,
				disposals: action.orders,
				fetchingOrders: false,
				fetchingOrdersErrorMessage: ""
			};
		case FETCH_ORDERS_FAILURE:
			return {
				...state,
				fetchingOrders: false
			};
		case ENHANCE_ORDER_REQUEST:
			return {
				...state,
			};
		case ENHANCE_ORDER_SUCCESS:
			const enhancedDisposals = state.disposals.map(disposal => (disposal.orderId === action.enhancedOrder.orderId) ? action.enhancedOrder : disposal);
			return {
				...state,
				disposals: enhancedDisposals
			};
		case ENHANCE_ORDER_FAILURE:
			return {
				...state,
			};
		case PLACE_DISPOSALS_REQUEST:
			return {
				...state,
				fetchingOrders: true,
				fetchingOrdersErrorMessage: ""
			};
		case PLACE_DISPOSALS_SUCCESS:
			return {
				...state
			};
		case PLACE_DISPOSALS_FAILURE:
			return {
				...state,
				fetchingOrders: false,
				fetchingOrdersErrorMessage: action.errorMessage
			};
		case DELETE_DISPOSAL_REQUEST:
			return {
				...state,
				fetchingOrders: true,
				fetchingOrdersErrorMessage: ""
			};
		case DELETE_DISPOSAL_SUCCESS:
			return {
				...state
			};
		case DELETE_DISPOSAL_FAILURE:
			return {
				...state,
				fetchingOrders: false,
				fetchingOrdersErrorMessage: action.errorMessage
			};
		default:
			return {
				...state
			};
	}
};

export default cart;
