// @flow

import type {Recall, Vehicle, VkCode} from "../model";
import type {VehicleAction} from "../action";
import {
	CLEAR_VEHICLES,
	GET_VEHICLE_RECALLS_SUCCESS,
	HIDE_VEHICLE_HISTORY,
	RESET_VEHICLE,
	RESET_VEHICLE_CODE,
	RESTORE_VEHICLE,
	SEARCH_VEHICLE_FAILURE,
	SEARCH_VEHICLE_FROM_HISTORY_SUCCESS,
	SEARCH_VEHICLE_REQUEST,
	SEARCH_VEHICLE_SUCCESS,
	SET_VEHICLE,
	SET_VEHICLE_CODE,
	SET_VEHICLE_MENU,
	SET_VIN_SEARCH_PHRASE,
	SHOW_VEHICLE_HISTORY,
	VK_FACET_SUCCESS,
} from "../action";
import type {PagedDocuments} from "../../documentsearch/api/documentService";
import type {VehicleDocumentAction} from "../../documentsearch/actions/searchVehicle";
import {SEARCH_SUCCESS} from "../../documentsearch/actions/searchVehicle";
import {VEHICLE} from "../../dms/action";
import type {DmsVehicle} from "../../dms/event/model/vehicle";
import type {FacetModel} from "../../components/filter/model";


export type VehicleState = {
	vehicleSuggestions: Vehicle[],
	vehicle: ?Vehicle,
	search: {
		vkCode: ?VkCode,
		vinPhrase: string
	},
	isSearchingVehicles: boolean,
	vehicleHistoryIsActive: boolean,
	searchVehiclesError: string,
	vkFacets: FacetModel[],
	documents: {
		fetching: boolean,
		items: ?PagedDocuments
	},
	recalls: ?Recall[],
	dmsVehicles: {[vin:string]: DmsVehicle },
	isMenuOpen: boolean,
	fetchingDmsData: boolean
};

const initialState: VehicleState = {
	vehicleSuggestions: [],
	vehicle: null,
	search: {
		vkCode: null,
		vinPhrase: ""
	},
	isSearchingVehicles: false,
	vehicleHistoryIsActive: false,
	searchVehiclesError: "",
	vkFacets: [],
	documents: {
		fetching: false,
		items: null
	},
	recalls: [],
	dmsVehicles: {},
	isMenuOpen: false,
	fetchingDmsData: false
};

const vehicleReducer = (state: VehicleState = initialState, action: VehicleAction | VehicleDocumentAction): VehicleState => {
	switch (action.type) {
		case SET_VEHICLE_MENU:
			return {
				...state,
				isMenuOpen: action.payload
			};
		case VEHICLE.REQUEST: {
			return {
				...state,
				vehicleSuggestions: [],
				dmsVehicles: {},
				fetchingDmsData: true
			};
		}
		case VEHICLE.NOT_FOUND: {
			return {
				...state,
				vehicleSuggestions: [],
				dmsVehicles: {},
				fetchingDmsData: false
			};
		}
		case VEHICLE.FOUND: {
			return {
				...state,
				dmsVehicles: {...state.dmsVehicles, [action.payload.vin]: action.payload},
				fetchingDmsData: false
			};
		}
		case SET_VEHICLE_CODE: {
			return {
				...state,
				search: {
					...state.search,
					vkCode: action.vkCode
				}
			};
		}
		case RESET_VEHICLE_CODE: {
			return {
				...state,
				search: {
					...state.search,
					vkCode: null
				},
				recalls: []
			};
		}
		case SET_VIN_SEARCH_PHRASE: {
			return {
				...state,
				search: {
					...state.search,
					vinPhrase: action.vinPhrase
				}
			};
		}
		case SEARCH_VEHICLE_REQUEST: {
			return {
				...state,
				isSearchingVehicles: true
			};
		}
		case SEARCH_VEHICLE_SUCCESS: {
			return {
				...state,
				vehicleSuggestions: action.vehicles,
				isSearchingVehicles: false,
				searchVehiclesError: ""
			};
		}
		case SEARCH_VEHICLE_FAILURE: {
			return {
				...state,
				searchVehiclesError: action.error,
				isSearchingVehicles: false
			};
		}
		case SEARCH_VEHICLE_FROM_HISTORY_SUCCESS: {
			return {
				...state,
				vehicleSuggestions: action.vehicles,
			};
		}
		case RESTORE_VEHICLE:
		case SET_VEHICLE: {
			sessionStorage.setItem("vehicle", JSON.stringify(action.vehicle));
			return {
				...state,
				vehicle: action.vehicle,
				search: {
					vinPhrase: "",
					vkCode: action.vehicle.vkCode
				},
				recalls: [],
				dmsVehicles: {},
			};
		}

		case GET_VEHICLE_RECALLS_SUCCESS: {
			return {
				...state,
				recalls: action.payload
			};
		}

		case CLEAR_VEHICLES: {
			return {
				...state,
				vehicleSuggestions: [],
				dmsVehicles: {},
			};
		}
		case RESET_VEHICLE: {
			sessionStorage.removeItem("vehicle");
			return {
				...state,
				vehicle: null,
				search: {
					vkCode: null,
					vinPhrase: ""
				},
				dmsVehicles: {},
			};
		}
		case VK_FACET_SUCCESS: {
			return {
				...state,
				vkFacets: action.facets
			};
		}
		case SEARCH_SUCCESS: {
			return {
				...state,
				documents: {
					fetching: false,
					items: action.vehicleDocuments
				}
			};
		}
		case SHOW_VEHICLE_HISTORY: {
			return {
				...state,
				vehicleHistoryIsActive: true
			};
		}
		case HIDE_VEHICLE_HISTORY: {
			return {
				...state,
				vehicleHistoryIsActive: false
			};
		}
		default:
			return state;
	}
};

export default vehicleReducer;
