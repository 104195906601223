import {LocalizedOrderItem} from "../order/models";

export type EcommerceItem = {
	item_id: string,
	item_name: string,
	affiliation: string,
	coupon: string,
	discount: number,
	index: number,
	item_brand: string,
	item_category: string,
	item_category2: string,
	item_category3: string,
	item_category4: string,
	item_category5: string,
	item_list_id: string,
	item_list_name: string,
	item_variant: string,
	location_id: string,
	price: number,
	quantity: number
};

class EcommerceItemFactory {
	productListLimit = 200;
	customPropertiesLimit = 27;

	fromAspProduct = (product: LocalizedOrderItem, customProperties: { ... }): EcommerceItem => {
		return {
			item_id: product.partNumber,
			item_name: product.partNumber,
			price: product.price,
			quantity: product.orderedQuantity,
			...customProperties,
		};
	};

	fromLocalizedOrderItems = (productList: Array<LocalizedOrderItem>, customProperties: { ... } = {}) => {
		if (productList.length > this.productListLimit) throw new Error("Cannot send more than 200 products in a single ecommerce event");
		if (Object.keys(customProperties).length > this.customPropertiesLimit) throw new Error("Cannot send more than 27 custom properties in a single ecommerce event");

		return productList.map(product => this.fromAspProduct(product, customProperties));
	};
}

const ecommerceItemFactoryInstance = new EcommerceItemFactory();
export default ecommerceItemFactoryInstance;
