import {axiosProductInstance} from "../api";
import type {Axios} from "axios";

class SuggestionService {
	api: Axios;

	constructor(api: Axios) {
		this.api = api;
	}

	async getSuggestionsByPartNumber(partNumber: string): Promise<String[]> {
		const newVar = await this.api.get("/v1/product/suggestions/part-number/" + partNumber);
		return newVar;
	};

	async getSuggestionsBySearchPhrase(searchPhrase: string): Promise<String[]> {
		return this.api.get("/v1/product/suggestions/text/" + searchPhrase);
	};

	async getSuggestionsByPartCode(partCode: string): Promise<String[]> {
		return this.api.get("/v1/product/suggestions/part-code/" + partCode);
	};
}

const SuggestionServiceInstance = new SuggestionService(axiosProductInstance);
export default SuggestionServiceInstance;
