// @flow

import type {Dispatch} from "../../cart/actions";
import MediaLibService from "../api/MediaLibService";
import type {Channel} from "../model/Channel";
import type {QuestionnaireSubmitData} from "../components/Questionnaire/Questionnaire";

export const FETCH_CHANNELS = {
	"REQUEST": "FETCH_CHANNELS_REQUEST",
	"SUCCESS": "FETCH_CHANNELS_SUCCESS",
	"FAILURE": "FETCH_CHANNELS_FAILURE",
};

export const QUESTIONNAIRE_FORM_SUBMIT = {
	"REQUEST": "QUESTIONNAIRE_FORM_SUBMIT_REQUEST",
	"SUCCESS": "QUESTIONNAIRE_FORM_SUBMIT_SUCCESS",
	"FAILURE": "QUESTIONNAIRE_FORM_SUBMIT_FAILURE",
};

export const NOTIFY_MISSING_QUESTIONS = "NOTIFY_MISSING_QUESTIONS";

export const getCourses = () => {
	return (dispatch: Dispatch) => {
		dispatch({
			type: FETCH_CHANNELS.REQUEST
		});
		const mediaLibService = new MediaLibService();
		mediaLibService.getAll()
			.then(res => {
				dispatch({
					type: FETCH_CHANNELS.SUCCESS,
					payload: res
				});
			}).catch(e => {
				dispatch({
					type: FETCH_CHANNELS.FAILURE
				});
			});
	};
};
export const notifyMissingQuestions = (count: number, questionIds: string) => {
	return (dispatch: Dispatch) => {
		dispatch({
			type: NOTIFY_MISSING_QUESTIONS,
			payload: questionIds,
			count
		});
	};
};

export const handleQuestionnaireSubmit = (data: QuestionnaireSubmitData) => {
	return (dispatch: Dispatch) => {
		dispatch({
			type: QUESTIONNAIRE_FORM_SUBMIT.REQUEST
		});

		const mediaLibService = new MediaLibService();
		mediaLibService.sendQuestionnaire(data)
			.then(() => {
				dispatch({
					type: QUESTIONNAIRE_FORM_SUBMIT.SUCCESS,
					payload: data.seriesId
				});
			})
			.catch(() => {
				return dispatch({
					type: QUESTIONNAIRE_FORM_SUBMIT.FAILURE
				});
			});
	};
};

export type FetchChannelsRequest = { type: typeof FETCH_CHANNELS.REQUEST };
export type FetchChannelsSuccess = { type: typeof FETCH_CHANNELS.SUCCESS, payload: Channel[] };
export type FetchChannelsFailure = { type: typeof FETCH_CHANNELS.FAILURE, errorMessage: string };

export type QuestionnaireFormSubmitRequest = { type: typeof QUESTIONNAIRE_FORM_SUBMIT.REQUEST };
export type QuestionnaireFormSubmitSuccess = { type: typeof QUESTIONNAIRE_FORM_SUBMIT.SUCCESS };
export type QuestionnaireFormSubmitFailure = { type: typeof QUESTIONNAIRE_FORM_SUBMIT.FAILURE, errorMessage: string };

export type AcademyAction =
	| FetchChannelsRequest
	| FetchChannelsSuccess
	| FetchChannelsFailure
	| QuestionnaireFormSubmitRequest
	| QuestionnaireFormSubmitSuccess
	| QuestionnaireFormSubmitFailure;
