// @flow
import type {Announcement} from "../model/Announcement";
import {FETCH_CLEARANCE_NOTES, FETCH_MAINTENANCE_NOTES, FETCH_MALFUNCTION_NOTES, FETCH_RELEASE_NOTES} from "../actions";

export type AnnouncementState = {
	releaseNotes: Announcement[],
	malfunctionNotes: Announcement[],
	maintenanceNotes: Announcement[],
	clearanceNotes: Announcement[],
	isFetchingAnnouncements: boolean
}

const initialState = {
	releaseNotes: [],
	malfunctionNotes: [],
	maintenanceNotes: [],
	clearanceNotes: [],
	isFetchingAnnouncements: false
};

export const announcement = (state: AnnouncementState = initialState, action): AnnouncementState => {
	switch (action.type) {
		case FETCH_RELEASE_NOTES.REQUEST:
			return {
				...state,
				isFetchingAnnouncements: true
			};
		case FETCH_RELEASE_NOTES.FAILURE:
			return {
				...state,
				isFetchingAnnouncements: false
			};
		case FETCH_RELEASE_NOTES.SUCCESS:
			return {
				...state,
				releaseNotes: action.payload,
				isFetchingAnnouncements: false
			};
		case FETCH_MALFUNCTION_NOTES.REQUEST:
			return {
				...state,
				isFetchingAnnouncements: true
			};
		case FETCH_MALFUNCTION_NOTES.FAILURE:
			return {
				...state,
				isFetchingAnnouncements: false
			};
		case FETCH_MALFUNCTION_NOTES.SUCCESS:
			return {
				...state,
				malfunctionNotes: action.payload,
				isFetchingAnnouncements: false
			};
		case FETCH_MAINTENANCE_NOTES.REQUEST:
			return {
				...state,
				isFetchingAnnouncements: true
			};
		case FETCH_MAINTENANCE_NOTES.FAILURE:
			return {
				...state,
				isFetchingAnnouncements: false
			};
		case FETCH_MAINTENANCE_NOTES.SUCCESS:
			return {
				...state,
				maintenanceNotes: action.payload,
				isFetchingAnnouncements: false
			};
		case FETCH_CLEARANCE_NOTES.REQUEST:
			return {
				...state,
				isFetchingAnnouncements: true
			};
		case FETCH_CLEARANCE_NOTES.FAILURE:
			return {
				...state,
				isFetchingAnnouncements: false
			};
		case FETCH_CLEARANCE_NOTES.SUCCESS:
			return {
				...state,
				clearanceNotes: action.payload,
				isFetchingAnnouncements: false
			};
		default:
			return state;
	}
};
