import {LOGIN_SUCCESS} from "../service/authService";

export type AuthState = {
	isAuthenticated: boolean,
	errorMessage: string,
	displayName: string,
	phone: string,
	mail: string,
	dealerId: string,
	dealerGroup: string,
	isAuthenticating: boolean,
	accessToken: string

}

const initialState : AuthState = {
	isAuthenticated: false,
	errorMessage: "",
	displayName: "",
	phone: "",
	mail: "",
	dealerId: "",
	dealerGroup: "",
	isAuthenticating: false,
	accessToken: ""
};

const auth = (state: AuthState = initialState, action) => {
	switch (action.type) {
		case LOGIN_SUCCESS:
			return {
				...state,
				isAuthenticated: true,
				errorMessage: "",
				displayName: action.displayName,
				phone: action.phone,
				mail: action.mail,
				dealerId: action.dealerId,
				dealerGroup: action.dealerGroup,
				isAuthenticating: false,
				accessToken: action.accessToken
			};
		default:
			return state;
	}
};

export default auth;
