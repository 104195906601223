import * as Axios from "axios";
import type {PromotionModel, PromotionOrder} from "../model/Promotion";
import {PackagePosition} from "../model/Promotion";
import {axiosInstance} from "../../api";
import type {Vehicle, VkCode} from "../../vehicle/model";

class PromotionService {
	client: Axios;

	constructor() {
		this.client = axiosInstance;
	};

	async getPromotions(): Promise<PromotionModel[]> {
		const response = await this.client.get("/promotion");
		return response.data;
	}

	async getPromotionsForVehicle(vkCode: VkCode): Promise<PromotionModel[]> {
		const response = await this.client.post("/promotion", vkCode);
		return response.data;
	}

	async getPromotionBundle(packageId: string, vehicle: Vehicle): Promise<PackagePosition[]> {
		const response = await this.client.post(`/promotion/bundle/${packageId}`, {
			vin: vehicle.vin,
			vehicleInformation: {
				vkCode: vehicle.vkCode,
				modelCode: vehicle.modelCode,
				factoryCode: vehicle.factoryCode,
				optionCodes: vehicle.optionCodes,
				colorCode: vehicle.colorCode,
				interiorColorCode: vehicle.interiorColorCode,
				destinationCode: vehicle.destinationCode,
				initialRegistrationDate: vehicle.initialRegistrationDate
			}
		});
		return response.data;
	}

	async orderPromotionPackage(order: PromotionOrder): Promise<any> {
		const response = await this.client.post("/promotion/dispose", order);
		return response.data;
	}
}

const PromotionServiceInstance = new PromotionService();
export default PromotionServiceInstance;
