import {LOGIN_SUCCESS} from "../service/authService";
import dealerService from "../profile/service/dealerService";
import {getDealerDetails} from "../profile/actions";
import store from "./../configureStore";
import MandatorProvider from "../mandator/MandatorProvider";

export const dealerInitializer = () => next => action => {
	switch (action.type) {
		case LOGIN_SUCCESS:
			MandatorProvider.set(action.mandator);
			dealerService.createDealer()
				.then(() => store.dispatch(getDealerDetails()));
			break;
		default:
			break;
	}
	return next(action);
};
