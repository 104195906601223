import {
	CLEAR_SUCCESS,
	DMS_CART_IMPORT_FAILURE,
	DMS_CART_IMPORT_REQUEST,
	EPC_CART_IMPORT_FAILURE,
	EPC_CART_IMPORT_REQUEST,
	ITEM_ADD_FAILURE,
	ITEM_ADD_SUCCESS,
	ITEM_ADD_WARNING,
	ITEM_EDIT_FAILURE,
	ITEM_REMOVE_FAILURE,
	ITEM_REMOVE_SUCCESS,
	ITEMS_ADD_SUCCESS,
} from "../cart/actions";
import {toast} from "react-toastify";
import React from "react";
import Icon from "../components/buttons/Icon";
import {Trans} from "react-i18next";
import {CREATE_ORDERS_FAILURE, CREATE_ORDERS_SUCCESS} from "../order/actions/offer";
import {FETCH_ORDERS_FAILURE, PRINT_DISPOSAL_FAILURE} from "../order/actions/orders";
import {
	SEND_MISSING_PART_NUMBER_NOTIFICATION_FAILURE,
	SEND_MISSING_PART_NUMBER_NOTIFICATION_SUCCESS,
	SEND_MISSING_PARTCODE_NOTIFICATION_FAILURE,
	SEND_MISSING_PARTCODE_NOTIFICATION_SUCCESS
} from "../productsearch/actions/search";
import {
	WATCH_LIST_CREATE_FAILURE,
	WATCH_LIST_CREATE_SUCCESS,
	WATCH_LIST_ITEM_ADD_REQUEST,
	WATCH_LIST_ITEM_TRANSFER_FAILURE,
	WATCH_LIST_ITEM_TRANSFER_SUCCESS
} from "../watchlist/actions";
import {CREATE_TICKET_FAILURE, CREATE_TICKET_SUCCESS} from "../actions/ticket";
import {FETCH_CONSUMABLE_PRICE} from "../profile/actions";
import {NOTIFY_MISSING_QUESTIONS, QUESTIONNAIRE_FORM_SUBMIT} from "../medialib/actions/medialib";
import {ADD_SPARE_POSITIONS, ADD_TASK_POSITION, FINISH_PROPOSAL} from "../proposal/action";
import {CREATE_ZENDESK_TICKET} from "../susy/actions/tickets";
import {DMS_ERROR, VEHICLE} from "../dms/action";
import {CATALOGS_FAILURE} from "../gta/actions/catalogs";
import {CREATE_RESHIPMENT, DELETE_RESHIPMENT, REQUEST_RESHIPMENT} from "../reshipment/actions";
import {SEARCH_WHEELS} from "../wheelsearch/actions";

export const notificator = () => next => action => {
	switch (action.type) {
		case VEHICLE.NOT_FOUND:
			toast(<div><Icon name="dms-notification-error"/>
				<div className={"notification-text"}><Trans i18nKey="notification.customerImport.failure"/></div>
			</div>, {
				className: "notification-white-background",
				bodyClassName: "notification-cart",
				progressClassName: "notification-white-progress-bar"
			});
			break;
		case DMS_ERROR:
			toast(
				<div><Icon name="error-notification"/>
					<div className={"notification-text"}><Trans i18nKey={action.error}/></div>
				</div>, {
					className: "notification-white-background",
					progressClassName: "notification-white-progress-bar",
					closeOnClick: false,
					autoClose: false
				}
			);
			break;
		case ITEM_ADD_SUCCESS:
			toast(<div><Icon name="cart-notification"/>
				<div className={"notification-text"}><Trans i18nKey="notification.productAdded"/></div>
			</div>, {
				className: "notification-white-background",
				bodyClassName: "notification-cart",
				progressClassName: "notification-white-progress-bar"
			});
			break;
		case ITEMS_ADD_SUCCESS:
			toast(<div><Icon name="cart-notification"/>
				<div className={"notification-text"}><Trans count={action.amount} i18nKey="notification.productsAdded"/>
				</div>
			</div>, {
				className: "notification-white-background",
				bodyClassName: "notification-cart",
				progressClassName: "notification-white-progress-bar"
			});
			break;
		case DMS_CART_IMPORT_REQUEST:
		case EPC_CART_IMPORT_REQUEST:
			toast(<div><Icon name="cart-notification"/>
				<div className={"notification-text"}><Trans i18nKey="notification.productsImported"/></div>
			</div>, {
				className: "notification-white-background",
				bodyClassName: "notification-cart",
				progressClassName: "notification-white-progress-bar"
			});
			break;
		case ITEM_ADD_FAILURE:
			toast(<div><Icon name="error-notification"/>
				<div className={"notification-text"}><Trans
					i18nKey="notification.productAddFailure"/>&nbsp;{action.partNumber}</div>
			</div>, {
				className: "notification-white-background",
				bodyClassName: "notification-error",
				progressClassName: "notification-white-progress-bar",
				closeOnClick: false,
				autoClose: false
			});
			break;
		case DMS_CART_IMPORT_FAILURE:
		case EPC_CART_IMPORT_FAILURE:
			toast(<div><Icon name="error-notification"/>
				<div className={"notification-text"}>{action.message}</div>
			</div>, {
				className: "notification-white-background",
				bodyClassName: "notification-error",
				progressClassName: "notification-white-progress-bar"
			});
			break;
		case QUESTIONNAIRE_FORM_SUBMIT.FAILURE:
			toast(<div><Icon name="error-notification">
				Es ist ein Fehler aufgetreten, bitte Versuchen Sie es später erneut.
			</Icon></div>);
			break;
		case ITEM_ADD_WARNING:
			toast(<div><Icon name="error-notification"/>
				<div className={"notification-text"}><Trans
					i18nKey="notification.productDoesntExist"/>&nbsp;{action.partNumber}</div>
			</div>, {
				className: "notification-white-background",
				bodyClassName: "notification-error",
				progressClassName: "notification-white-progress-bar",
				closeOnClick: false,
				autoClose: false
			});
			break;
		case CREATE_ORDERS_SUCCESS:
			toast(<div>
				<div className={"notification-text"}><Trans i18nKey="notification.orderCreated"/></div>
			</div>, {
				className: "notification-white-background",
				bodyClassName: "notification-cart",
				progressClassName: "notification-white-progress-bar"
			});
			break;
		case CREATE_ORDERS_FAILURE:
		case FETCH_ORDERS_FAILURE:
			toast(<div><Icon name="error-notification"/>
				<div className={"notification-text"}>Fehler beim Laden von Bestellungsdaten</div>
			</div>, {
				className: "notification-white-background",
				bodyClassName: "notification-error",
				progressClassName: "notification-white-progress-bar",
				closeOnClick: false,
				autoClose: false
			});
			break;
		case ITEM_REMOVE_SUCCESS:
			toast(<div><Icon name="cart-notification"/>
				<div className={"notification-text"}><Trans i18nKey="notification.productDeleted"/></div>
			</div>, {
				className: "notification-white-background",
				bodyClassName: "notification-cart",
				progressClassName: "notification-white-progress-bar"
			});
			break;
		case ITEM_REMOVE_FAILURE:
			toast(<div><Icon name="error-notification"/>
				<div className={"notification-text"}><Trans i18nKey="notification.productCouldntBeDeleted"/></div>
			</div>, {
				className: "notification-white-background",
				bodyClassName: "notification-error",
				progressClassName: "notification-white-progress-bar"
			});
			break;
		case ITEM_EDIT_FAILURE:
			toast(<div><Icon name="error-notification"/>
				<div className={"notification-text"}><Trans i18nKey="notification.couldntRefreshCart"/></div>
			</div>, {
				className: "notification-white-background",
				bodyClassName: "notification-error",
				progressClassName: "notification-white-progress-bar"
			});
			break;
		case CLEAR_SUCCESS:
			toast(<div><Icon name="cart-notification"/>
				<div className={"notification-text"}><Trans i18nKey="notification.cartEmptied"/></div>
			</div>, {
				className: "notification-white-background",
				bodyClassName: "notification-cart",
				progressClassName: "notification-white-progress-bar"
			});
			break;
		case SEND_MISSING_PARTCODE_NOTIFICATION_SUCCESS:
			toast(<div><Icon name="mail"/>
				<div className={"notification-text"}><Trans i18nKey="notification.partCodeSent.success"/></div>
			</div>, {
				className: "notification-white-background",
				bodyClassName: "notification-cart",
				progressClassName: "notification-white-progress-bar",
				autoClose: false
			});
			break;
		case SEND_MISSING_PART_NUMBER_NOTIFICATION_SUCCESS:
			toast(<div><Icon name="mail"/>
				<div className={"notification-text"}><Trans i18nKey="notification.partCodeSent.success"/></div>
			</div>, {
				className: "notification-white-background",
				bodyClassName: "notification-cart",
				progressClassName: "notification-white-progress-bar",
				autoClose: false
			});
			break;
		case SEND_MISSING_PARTCODE_NOTIFICATION_FAILURE:
			toast(<div><Icon name="error-notification"/>
				<div className={"notification-text"}><Trans i18nKey="notification.partCodeSent.failure"/></div>
			</div>, {
				className: "notification-white-background",
				bodyClassName: "notification-error",
				progressClassName: "notification-white-progress-bar",
				autoClose: false
			});
			break;
		case SEND_MISSING_PART_NUMBER_NOTIFICATION_FAILURE:
			toast(<div><Icon name="error-notification"/>
				<div className={"notification-text"}><Trans i18nKey="notification.partCodeSent.failure"/></div>
			</div>, {
				className: "notification-white-background",
				bodyClassName: "notification-error",
				progressClassName: "notification-white-progress-bar",
				autoClose: false
			});
			break;
		case WATCH_LIST_ITEM_ADD_REQUEST:
			toast(
				<div><Icon name="cart-notification"/>
					<div className={"notification-text"}><Trans
						i18nKey="notification.watchlist.itemAdded">{action.watchListName}</Trans></div>
				</div>, {
					className: "notification-white-background",
					bodyClassName: "notification-cart",
					progressClassName: "notification-white-progress-bar"
				}
			);
			break;
		case WATCH_LIST_ITEM_TRANSFER_SUCCESS:
			toast(
				<div>
					<div className={"notification-text"}>
						<Trans count={action.amount} i18nKey="notification.watchlist.itemsTransferred"/>
					</div>
				</div>, {
					className: "notification-white-background",
					bodyClassName: "notification-cart",
					progressClassName: "notification-white-progress-bar"
				}
			);
			break;
		case WATCH_LIST_CREATE_SUCCESS:
			toast(
				<div>
					<div className={"notification-text"}>
						<Trans
							i18nKey="notification.watchlist.watchListCreated">{action.watchListName ? action.watchListName : ""}</Trans>
					</div>
				</div>, {
					className: "notification-white-background",
					bodyClassName: "notification-cart",
					progressClassName: "notification-white-progress-bar"
				}
			);
			break;
		case CREATE_TICKET_SUCCESS:
			toast(
				<div>
					<div className={"notification-text"}><Trans i18nKey="notification.supportRequest.created"/></div>
				</div>, {
					className: "notification-white-background",
					bodyClassName: "notification-cart",
					progressClassName: "notification-white-progress-bar"
				}
			);
			break;
		case CREATE_TICKET_FAILURE:
			toast(
				<div>
					<div className={"notification-text"}>
						<Trans i18nKey="notification.supportRequest.failure">
							{action.error}
						</Trans>
					</div>
				</div>, {
					className: "notification-white-background",
					bodyClassName: "notification-cart",
					progressClassName: "notification-white-progress-bar"
				}
			);
			break;
		case WATCH_LIST_CREATE_FAILURE:
			toast(
				<div>
					<div className={"notification-text"}>
						<Trans i18nKey="notification.watchlist.watchListCreatedFailure">
							{action.watchListName}
							{action.error}
						</Trans>
					</div>
				</div>, {
					className: "notification-white-background",
					bodyClassName: "notification-cart",
					progressClassName: "notification-white-progress-bar"
				}
			);
			break;
		case WATCH_LIST_ITEM_TRANSFER_FAILURE:
			toast(
				<div>
					<div className={"notification-text"}>
						<Trans i18nKey="notification.watchlist.watchListTransferFailure">
							{action.watchListName}
							{action.error}
						</Trans>
					</div>
				</div>, {
					className: "notification-white-background",
					bodyClassName: "notification-cart",
					progressClassName: "notification-white-progress-bar"
				}
			);
			break;
		case PRINT_DISPOSAL_FAILURE:
			toast(
				<div>
					<div className={"notification-text"}>
						<Trans i18nKey="notification.order.printFailure"/>
					</div>
				</div>, {
					className: "notification-white-background",
					bodyClassName: "notification-cart",
					progressClassName: "notification-white-progress-bar"
				}
			);
			break;
		case FETCH_CONSUMABLE_PRICE.FAILURE:
			toast(
				<div>
					<Icon name="error-notification"/>
					<div className={"notification-text"}>
						{action.errorMessage}
					</div>
				</div>, {
					className: "notification-white-background",
					bodyClassName: "notification-cart",
					progressClassName: "notification-white-progress-bar",
					autoClose: false
				}
			);
			break;
		case NOTIFY_MISSING_QUESTIONS:
			toast.warn(
				<div>
					<div className={"notification-text"}>
						<Trans i18nKey="notification.questionnaire.missingQuestions_interval"
							   count={action.count}
							   tOptions={{postProcess: "interval"}}
						>
							{action.payload}
						</Trans>
					</div>
				</div>, {
					className: "notification-white-background",
					bodyClassName: "notification-cart",
					progressClassName: "notification-white-progress-bar"
				}
			);
			break;
		case FINISH_PROPOSAL.FAILURE:
			toast(
				<div>
					<Icon name="error-notification"/>
					<div className={"notification-text"}>
						{action.errorMessage}
					</div>
				</div>, {
					className: "notification-white-background",
					bodyClassName: "notification-cart",
					progressClassName: "notification-white-progress-bar",
					autoClose: false
				}
			);
			break;

		case CATALOGS_FAILURE:
			toast(
				<div>
					<Icon name="error-notification"/>
					<div className={"notification-text"}>
						<Trans i18nKey={"catalogs.error"}
							   count={action.count}
							   tOptions={{postProcess: "interval"}}
						>
							{action.payload}
						</Trans>
					</div>
				</div>, {
					className: "notification-white-background",
					bodyClassName: "notification-cart",
					progressClassName: "notification-white-progress-bar",
					autoClose: false
				}
			);
			break;
		case CREATE_ZENDESK_TICKET.SUCCESS:
			toast(
				<div>
					<div className={"notification-text"}><Trans i18nKey="notification.susy.ticketCreated"/></div>
				</div>, {
					className: "notification-white-background",
					bodyClassName: "notification-cart",
					progressClassName: "notification-white-progress-bar"
				}
			);
			break;
		case ADD_TASK_POSITION.SUCCESS:
			toast(
				<div>
					<div className={"notification-text"}>
						<Trans
							i18nKey="notification.proposal.taskAdded_interval"
							count={action.count}
							tOptions={{postProcess: "interval"}}
						/>
					</div>
				</div>, {
					className: "notification-white-background",
					bodyClassName: "notification-cart",
					progressClassName: "notification-white-progress-bar"
				}
			);
			break;
		case ADD_SPARE_POSITIONS.SUCCESS:
			toast(
				<div>
					<div className={"notification-text"}>
						<Trans
							i18nKey="notification.proposal.spareAdded_interval"
							count={action.count}
							tOptions={{postProcess: "interval"}}
						/>
					</div>
				</div>, {
					className: "notification-white-background",
					bodyClassName: "notification-cart",
					progressClassName: "notification-white-progress-bar"
				}
			);
			break;
		case CREATE_RESHIPMENT.SUCCESS:
			toast(
				<div>
					<div className={"notification-text"}>
						<Trans
							i18nKey="notification.reshipment.created"
							count={action.count}
							tOptions={{postProcess: "interval"}}
						/>
					</div>
				</div>, {
					className: "notification-white-background",
					bodyClassName: "notification-cart",
					progressClassName: "notification-white-progress-bar"
				}
			);
			break;
		case REQUEST_RESHIPMENT.SUCCESS:
			toast(
				<div>
					<div className={"notification-text"}>
						<Trans i18nKey="notification.reshipment.requested"/>
					</div>
				</div>, {
					className: "notification-white-background",
					bodyClassName: "notification-cart",
					progressClassName: "notification-white-progress-bar"
				}
			);
			break;
		case DELETE_RESHIPMENT.SUCCESS:
			toast(
				<div>
					<div className={"notification-text"}>
						<Trans i18nKey="notification.reshipment.deleted"/>
					</div>
				</div>, {
					className: "notification-white-background",
					bodyClassName: "notification-cart",
					progressClassName: "notification-white-progress-bar"
				}
			);
			break;
		case SEARCH_WHEELS.FAILURE:
			toast(
				<div>
					<Icon name="error-notification"/>
					<div className={"notification-text"}>
						<Trans i18nKey={"wheelConfigurator.search.errorMessage"}></Trans>
					</div>
				</div>, {
					className: "notification-white-background",
					bodyClassName: "notification-cart",
					progressClassName: "notification-white-progress-bar",
					autoClose: false
				}
			);
			break;
		default:
			break;
	}
	return next(action);
};
