import type {VkCode} from "../model";

class VkCodeConverter {
	convertVkCodeToString = ({modelYear, type, subType, engine, gear, configuration, variant}: VkCode): string => {
		if (!modelYear || !type || !subType || !engine || !gear || !configuration || !variant) {
			throw new Error("VkCodeConverter.toString: invalid VkCode");
		}
		return modelYear + type + subType + engine + gear + configuration + variant;
	};
}

const vkCodeConverter = new VkCodeConverter();
export default vkCodeConverter;
