import type {WatchList} from "../models";
import {
	SET_ACTIVE_WATCHLIST,
	UPDATE_WATCH_LISTS,
	WATCH_LIST_ITEM_ENHANCE_REQUEST,
	WATCH_LIST_ITEM_ENHANCE_SUCCESS,
	WATCH_LIST_ITEM_REMOVE_REQUEST,
	WATCH_LIST_MODAL_CLOSE,
	WATCH_LIST_MODAL_OPEN,
	WATCH_LIST_RESET
} from "../actions";
import type {CartItemModel, EnhancedCartItemModel} from "../../cart/models";

export type WatchListState = {
	watchLists: WatchList[],
	activeWatchlist: string,
	isEnhancing: boolean,
	isModalOpen: boolean
}

const initialState: WatchListState = {
	watchLists: [],
	activeWatchlist: "",
	isEnhancing: false,
	isModalOpen: false
};

const watchList = (state: WatchListState = initialState, action: any) => {
	switch (action.type) {
		case UPDATE_WATCH_LISTS:
			const watchLists1 = action.watchLists.map(watchList => {
				const previousWatchList = state.watchLists.find(foo => foo.name === watchList.name);
				return {...watchList, enhancedItems: previousWatchList ? previousWatchList.enhancedItems : []};
			});
			return {
				...state,
				watchLists: watchLists1
			};
		case WATCH_LIST_ITEM_ENHANCE_REQUEST:
			return {
				...state,
				isEnhancing: true
			};

		case WATCH_LIST_MODAL_OPEN:
			return {
				...state,
				isModalOpen: true
			};
		case WATCH_LIST_MODAL_CLOSE:
			return {
				...state,
				isModalOpen: false
			};

		case WATCH_LIST_ITEM_REMOVE_REQUEST:
			const {partNumber, watchListName} = action;
			return {
				...state,
				watchLists: state.watchLists
					.map(watchList => {
						if (watchList.name === watchListName) {
							return {
								...watchList,
								enhancedItems: (watchList.enhancedItems.filter((item: EnhancedCartItemModel) => item.partNumber !== partNumber): Array<EnhancedCartItemModel>),
								cartItems: (watchList.cartItems.filter((item: CartItemModel) => item.partNumber !== partNumber): Array<CartItemModel>),
							};
						}
						return watchList;
					})
			};

		case WATCH_LIST_ITEM_ENHANCE_SUCCESS:
			let watchLists = state.watchLists
				.map(watchList => {
					if (watchList.name === action.name) {
						return {...watchList, enhancedItems: action.enhancedItems};
					}
					return watchList;
				}
				);
			return {
				...state,
				watchLists,
				isEnhancing: false
			};
		case SET_ACTIVE_WATCHLIST:
			return {
				...state,
				activeWatchlist: action.activeWatchlist,
				isModalOpen: false
			};
		case WATCH_LIST_RESET:
			const lists = state.watchLists
							   .map(watchList => {
									   return {...watchList, enhancedItems: []};
								   });
			return {
				...state,
				watchLists: lists
			};
		default:
			return {
				...state
			};
	}
};

export default watchList;
