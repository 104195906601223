import {
	CREATE_RESHIPMENT,
	FETCH_RESHIPMENTS, REQUEST_RESHIPMENT, SEND_RESHIPMENT,
	SET_RESHIPMENT_CREATE_MODAL,
	SET_RESHIPMENT_REQUEST_MODAL,
	SET_RESHIPMENT_SEND_MODAL,
	UPDATE_RESHIPMENT,
	DELETE_RESHIPMENT,
	DELETE_RESHIPMENT_ITEM
} from "../actions";
import {ReshipmentModel, ReshipmentStatus} from "../model";

export type ReshipmentState = {
	isReshipmentCreateModalOpen: boolean,
	isReshipmentRequestModalOpen: boolean,
	isReshipmentSendModalOpen: boolean,
	isCreatingReshipment: boolean,
	isDeletingReshipment: boolean,
	isDeletingReshipmentItem: boolean,
	reshipments: ReshipmentModel[],
	isFetchingReshipments: boolean,
	filterByStatus?: $Values<typeof ReshipmentStatus>,
	dateRange: {
		from: Date,
		to: Date
	},
	dateSpan: number,
}

const defaultFromDate = new Date();
const defaultDateRange = 7;

const initialState: ReshipmentState = {
	isReshipmentCreateModalOpen: false,
	isReshipmentRequestModalOpen: false,
	isReshipmentSendModalOpen: false,
	isCreatingReshipment: false,
	isDeletingReshipment: false,
	isDeletingReshipmentItem: false,
	reshipments: [],
	isFetchingReshipments: false,
	filterByStatus: [],
	dateRange: {
		from: defaultFromDate,
		to: new Date(),
	},
	dateSpan: defaultDateRange,
};
const reshipment = (state: ReshipmentState = initialState, action: any) => {
	switch (action.type) {
		case CREATE_RESHIPMENT.REQUEST:
			return {
				...state,
				isCreatingReshipment: true
			};
		case CREATE_RESHIPMENT.SUCCESS:
		case CREATE_RESHIPMENT.FAILURE:
		return {
			...state,
			isCreatingReshipment: false
		};
		case SET_RESHIPMENT_CREATE_MODAL:
			return {
				...state,
				isReshipmentCreateModalOpen: action.payload
			};
		case SET_RESHIPMENT_REQUEST_MODAL:
			return {
				...state,
				isReshipmentRequestModalOpen: action.payload
			};
		case SET_RESHIPMENT_SEND_MODAL:
			return {
				...state,
				isReshipmentSendModalOpen: action.payload
			};
		case FETCH_RESHIPMENTS.REQUEST:
			return  {
				...state,
				isFetchingReshipments: true
			};
		case FETCH_RESHIPMENTS.SUCCESS:
			return  {
				...state,
				isFetchingReshipments: false,
				reshipments: action.payload,
			};
		case FETCH_RESHIPMENTS.FAILURE:
			return  {
				...state,
				isFetchingReshipments: false,
			};
		case REQUEST_RESHIPMENT.SUCCESS:
			return  {
				...state,
				reshipments: state.reshipments.map(reshipment => (reshipment.id === action.payload.id) ? action.payload : reshipment)
			};
		case SEND_RESHIPMENT.SUCCESS:
			return  {
				...state,
				reshipments: state.reshipments.map(reshipment => (reshipment.id === action.payload.id) ? action.payload : reshipment)
			};
		case UPDATE_RESHIPMENT.REQUEST:
			return  {
				...state,
			};
		case UPDATE_RESHIPMENT.SUCCESS:
			return  {
				...state,
				reshipments: state.reshipments.map(reshipment => (reshipment.id === action.payload.id) ? action.payload : reshipment)
			};
		case UPDATE_RESHIPMENT.FAILURE:
			return  {
				...state,
			};
		case DELETE_RESHIPMENT.REQUEST:
			return  {
				...state,
				isDeletingReshipment: true
			};
		case DELETE_RESHIPMENT.SUCCESS:
			return  {
				...state,
				reshipments: state.reshipments.map(reshipment => (reshipment.id === action.payload.id) ? action.payload : reshipment),
				isDeletingReshipment: false
			};
		case DELETE_RESHIPMENT.FAILURE:
			return  {
				...state,
				isDeletingReshipment: false
			};
		case DELETE_RESHIPMENT_ITEM.REQUEST:
			return  {
				...state,
				isDeletingReshipmentItem: true
			};
		case DELETE_RESHIPMENT_ITEM.SUCCESS:
			return  {
				...state,
				reshipments: state.reshipments.map(reshipment => (reshipment.id === action.payload.id) ? action.payload : reshipment),
				isDeletingReshipmentItem: false
			};
		case DELETE_RESHIPMENT_ITEM.FAILURE:
			return  {
				...state,
				isDeletingReshipmentItem: false
			};
		default:
			return {
				...state
			};
	}
};
export default reshipment;
