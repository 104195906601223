//flow
import Configuration from "../Configuration";

export const MANDATOR = {
	SUBARU: {
		DE: "SU",
	},
	DAIHATSU: {
		DE: "DH",
	},
	UNKNOWN: "unknown",
};

class MandatorProvider {
	mandator;

	constructor() {
		this.mandator = Configuration.value("mandator");
	}

	get() {
		return this.mandator;
	}

	isTrackingEnabled() {
		return this.get() === MANDATOR.SUBARU.DE;
	}

	set(mandator: string) {
		this.mandator = mandator;
	}

	getI18nDefaultInterpolationSet() {
		const mandator = this.get();
		switch (mandator) {
			case MANDATOR.SUBARU.DE:
				return {
					mandatorCompany: "SUBARU Deutschland GmbH",
					mandatorName: "SUBARU Deutschland",
					brandName: "Subaru",
					brandNameUppercase: "SUBARU",
					brandNameLowercase: "subaru",
				};
			case MANDATOR.DAIHATSU.DE:
				return {
					mandatorCompany: "EF D TEILE UND SERVICE GMBH",
					mandatorName: "EF D Teile und Service GmbH",
					brandName: "Daihatsu",
					brandNameUppercase: "DAIHATSU",
				};
			default:
				return {};
		}
	}

	getVehicleFilterOptions() {
		const mandator = this.get();
		if (mandator === MANDATOR.DAIHATSU.DE) {
			return {
				vin: true,
				customer: false,
				vkCode: true,
				gta: true
			};
		}

		return {
			vin: true,
			customer: true,
			vkCode: true,
			gta: true
		};
	}
}

const MandatorProviderInstance = new MandatorProvider();
export default MandatorProviderInstance;
