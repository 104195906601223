// @flow
import React from "react";
import "./Spinner.scss";
import {spinnerType} from "./index";

function Spinner() {
	return (
		<div className={`spinner ${spinnerType()}`}>
			<div/>
			<div/>
			<div/>
			<div/>
			<div/>
		</div>
	);
}

export default Spinner;
