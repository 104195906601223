// @flow
import type {FigureModel, IllustrationGroup} from "../models";
import gtaService from "../services/gtaService";
import {ThunkAction} from "redux-thunk";
import type {Dispatch, GetState} from "../../actions";

export const ILLUSTRATION_GROUPS_REQUEST = "ILLUSTRATION_GROUPS_REQUEST";
export const ILLUSTRATION_GROUPS_SUCCESS = "ILLUSTRATION_GROUPS_SUCCESS";
export const ILLUSTRATION_GROUPS_FAILURE = "ILLUSTRATION_GROUPS_FAILURE";
export const FIGURES_REQUEST = "FIGURES_REQUEST";
export const FIGURES_SUCCESS = "FIGURES_SUCCESS";
export const FIGURES_FAILURE = "FIGURES_FAILURE";
export const FIGURE_GALLERY_SET_PAGE = "FIGURE_GALLERY_SET_PAGE";

export type IllustrationGroupsRequest = {
	type: typeof ILLUSTRATION_GROUPS_REQUEST
}

export type IllustrationGroupsSuccess = {
	type: typeof ILLUSTRATION_GROUPS_SUCCESS,
	illustrationGroups: IllustrationGroup[]
}

export type IllustrationGroupsFailure = {
	type: typeof ILLUSTRATION_GROUPS_FAILURE
}

export type FiguresRequest = {
	type: typeof FIGURES_REQUEST
}

export type FiguresSuccess = {
	type: typeof FIGURES_SUCCESS,
	figures: FigureModel[]
}

export type FiguresFailure = {
	type: typeof FIGURES_FAILURE
}

export type GtaAction =
		| IllustrationGroupsRequest
		| IllustrationGroupsSuccess
		| IllustrationGroupsFailure
		| FiguresRequest
		| FiguresSuccess
		| FiguresFailure;

export function loadIllustrationGroups(): ThunkAction {
	return async (dispatch: Dispatch, getState: GetState) => {
		dispatch({
			type: ILLUSTRATION_GROUPS_REQUEST
		});
		let modelCode = getState().vehicle.vehicle.modelCode;
		let factoryCode = getState().vehicle.vehicle.factoryCode;
		try {
			let illustrationGroups = await gtaService.getIllustrationGroups(modelCode, factoryCode);
			return dispatch({
				type: ILLUSTRATION_GROUPS_SUCCESS,
				illustrationGroups: illustrationGroups
			});
		} catch (e) {
			return dispatch({
				type: ILLUSTRATION_GROUPS_FAILURE
			});
		}
	};
}

export function onSelectFigureGalleryPage(selectedFigureGalleryIndex: number): ThunkAction {
	return (dispatch: Dispatch) => {
		dispatch({
			type: FIGURE_GALLERY_SET_PAGE,
			selectedFigureGalleryIndex
		});
	};
}

export function onSelectFigure(figureNumber: string): ThunkAction {
	return async (dispatch: Dispatch, getState: GetState) => {
		dispatch({
			type: FIGURES_REQUEST
		});

		try {
			let modelCode = getState().vehicle.vehicle.modelCode;
			let factoryCode = getState().vehicle.vehicle.factoryCode;
			let figures = await gtaService.getFigures(figureNumber, modelCode, factoryCode);
			dispatch({
				type: FIGURES_SUCCESS,
				figures
			});
		} catch (e) {
			dispatch({
				type: FIGURES_FAILURE
			});
		}
	};
}
