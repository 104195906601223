//flow
import dmsResourceService from "../service/dmsResourceService";
import store from "../../configureStore";
import {AxiosError} from "axios";

export const DmsNotEnabledError = new Error("profile.general.dms.error.disabled");
export const DmsCommunicationError = new Error("profile.general.dms.error.generic");
export const DmsGatewayError = new Error("profile.general.dms.error.gateway");

const handleDisabledDmsClient = (reject: Function) => {
	if (!store.getState().profile.dealer.useDmsClient) {
		return reject(DmsNotEnabledError);
	}
};

const handleError = (error: AxiosError, reject: Function) => {
	if (error.response && error.response.status === 502) {
		return reject(DmsGatewayError);
	}
	return reject(DmsCommunicationError);
};

class DmsCommunicationService {
	async getLocalStock(partNumbers: Array<string>): Promise<void> {
		return new Promise((resolve, reject) => {
			handleDisabledDmsClient(reject);
			dmsResourceService.getLocalStock(partNumbers)
				.catch((error: Error) => {
					handleError(error, reject);
				});
		});
	}

	async getVehicleByVin(vin: string): Promise<void> {
		return new Promise((resolve, reject) => {
			handleDisabledDmsClient(reject);
			resolve(dmsResourceService.getVehicleByVin(vin)
				.catch((error: Error) => {
					handleError(error, reject);
				})
			);
		});
	}

	async searchVehicleByLicensePlate(licensePlate: string): Promise<void> {
		return new Promise((resolve, reject) => {
			handleDisabledDmsClient(reject);
			resolve(dmsResourceService.searchVehiclesByLicensePlate(licensePlate)
				.catch((error: Error) => {
					handleError(error, reject);
				})
			);
		});
	}

	async searchVehicleByCustomerName(name: string): Promise<void> {
		return new Promise((resolve, reject) => {
			handleDisabledDmsClient(reject);
			resolve(dmsResourceService.searchVehiclesByCustomerName(name)
				.catch((error: Error) => {
					handleError(error, reject);
				})
			);
		});
	}

	async searchVehicleByCustomerEmail(email: string): Promise<void> {
		return new Promise((resolve, reject) => {
			handleDisabledDmsClient(reject);
			resolve(dmsResourceService.searchVehiclesByCustomerEmail(email)
				.catch((error: Error) => {
					handleError(error, reject);
				})
			);
		});
	}

	async searchVehicleByCustomerPhone(phone: string): Promise<void> {
		return new Promise((resolve, reject) => {
			handleDisabledDmsClient(reject);
			resolve(dmsResourceService.searchVehiclesByCustomerPhone(phone)
				.catch((error: Error) => {
					handleError(error, reject);
				})
			);
		});
	}
}

const DmsCommunicationServiceInstance = new DmsCommunicationService();

export default DmsCommunicationServiceInstance;
