// @flow

import {ChannelPage} from "../components/ChannelPage";
import type {MenuProps} from "../../components/layout/menu/Menu";
import type {State} from "../../reducers/rootReducer";
import connect from "react-redux/es/connect/connect";

export const CHANNEL_PAGE_VISITED = "CHANNEL_PAGE_VISITED";

function mapStateToProps(state: State, ownProps) {
	const {channelId} = ownProps.match.params;

	const currentChannel = state.medialib.channels.find(channel => channel.id.toString() === channelId);

	return {
		currentChannel,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		onPageLoad: (channelId: number) => dispatch(
			{
				type: CHANNEL_PAGE_VISITED,
				payload: channelId
			}
		)
	};
}

export default connect<MenuProps, _, _, _, _, _>(mapStateToProps, mapDispatchToProps)(ChannelPage);
