// @flow
import type {Axios} from "axios";
import {axiosInstance} from "../../api";
import type {ArticleFilter} from "../components/HelpCenter/HelpCenterSectionList";
import {TicketFilterCriteria, TicketFilterCriteriaType} from "../reducer/conversationsReducer";
import TicketFilterStatusMapper from "../service/TicketFilterStatusMapper";

class ArticleService {
	api: Axios;

	constructor() {
		this.api = axiosInstance;
	}

	async createTicket(formData) {
		return this.api.post("zendesk/ticket/my", formData, {
			headers: {
				"Content-Type": "multipart/form-data"
			}
		})
			.then(res => res.data);
	}

	async addMessageToTicket(ticketId, formData) {
		return this.api.post(`zendesk/ticket/${ticketId}/message`, formData, {
			headers: {
				"Content-Type": "multipart/form-data"
			}
		})
			.then(res => res.data);
	}

	async getTicketMessages(ticketId: number) {
		return this.api.get(`zendesk/ticket/${ticketId}/message`)
			.then(res => res.data);
	}

	async getTicket(ticketId: number) {
		return this.api.get(`zendesk/ticket/${ticketId}`)
			.then(res => res.data);
	}

	async getTickets() {
		return this.api.get("zendesk/ticket/my")
			.then(res => res.data);
	}


	async findTickets(criteria: Array<TicketFilterCriteria>) {
		const body = criteria.map(c => {
				if (c.field === TicketFilterCriteriaType.TICKET_STATUS) return TicketFilterStatusMapper.fromStatus(c.value);
				return c;
			}
		);
		return this.api.post("zendesk/ticket/search", body)
			.then(res => res.data);
	}

	async getZendeskUsers() {
		return this.api.get("zendesk/user")
			.then(res => res.data);
	}

	async getArticle(articleId: number) {
		return this.api.get(`article/${articleId}`)
			.then(res => res.data);
	}

	async getNewsArticles() {
		return this.api.get("article/news")
			.then(res => res.data);
	}

	async getArticleAttachments(articleId: number) {
		return this.api.get(`article/${articleId}/attachments`)
			.then(res => res.data);
	}

	async getSupportArticles() {
		return this.api.get("article/support")
			.then(res => res.data);
	}

	async searchSupportArticles(filter: ArticleFilter) {
		return this.api.post("article/support/search", {sectionId: filter.id, searchTerm: filter.searchTerm})
			.then(res => res.data);
	}

	async getSupportSections() {
		return this.api.get("article/support/section")
			.then(res => res.data);
	}
}

const ArticleServiceInstance = new ArticleService();
export default ArticleServiceInstance;
