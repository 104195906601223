import type Axios from "axios";
import qs from "query-string";
import {axiosInstance} from "../api";
import {InspectionData} from "../proposal/model";

type Maturity = {
	ageInMonths: number,
	mileageInKm: number
}

export type Inspection = {
	id: number,
	vehicle: string,
	name: string,
	category: string,
	maturity: Maturity
}

type InspectionResponse = {
	categories: { [key: string]: Inspection }
}

class InspectionService {
	api: Axios;

	constructor(api: Axios) {
		this.api = api;
	}

	async getInspectionElements(id: number, vin: string): InspectionData {
		const response = await this.api.get(`/inspection/${id}/elements`, {params: {vin}});
		if (response.status !== 200) {
			throw new InspectionServiceException();
		}
		return response.data;
	}

	searchForInspections(vehicle: string, ageInMonths: number, mileageInKm: number): Promise<InspectionResponse> {
		const groupByCategory = (categories, inspection) => {
			if (!categories[inspection.category]) {
				categories[inspection.category] = [];
			}
			categories[inspection.category].push(inspection);
			return categories;
		};

		return this.api.get("/inspection",
			{
				params: {
					vehicle,
					ageInMonths,
					mileageInKm
				},
				paramsSerializer: params => {
					return qs.stringify(params);
				}
			}
		).then(response => {
			return response.data.inspections.reduce(groupByCategory, {});
		});
	}
}

function InspectionServiceException() {
	this.message = "Server error";
	this.name = "InspectionServiceException";
}

const InspectionServiceInstance = new InspectionService(axiosInstance);
export default InspectionServiceInstance;
