import PromotionService from "../service/promotionService";
import type {PromotionOrder} from "../model/Promotion";
import {push} from "connected-react-router";
import RouteDictionary from "../../RouteDictionary";

export const GET_PROMOTION_OVERVIEW = {
	REQUEST: "GET_PROMOTION_OVERVIEW_REQUEST",
	SUCCESS: "GET_PROMOTION_OVERVIEW_SUCCESS",
	FAILURE: "GET_PROMOTION_OVERVIEW_FAILURE"
};

export const GET_VEHICLE_PROMOTION_OVERVIEW = {
	REQUEST: "GET_VEHICLE_PROMOTION_OVERVIEW_REQUEST",
	SUCCESS: "GET_VEHICLE_PROMOTION_OVERVIEW_SUCCESS",
	FAILURE: "GET_VEHICLE_PROMOTION_OVERVIEW_FAILURE"
};

export const COMPILE_PACKAGE = {
	REQUEST: "COMPILE_PACKAGE_REQUEST",
	SUCCESS: "COMPILE_PACKAGE_SUCCESS",
	FAILURE: "COMPILE_PACKAGE_FAILURE"
};

export const ORDER_PACKAGE = {
	REQUEST: "ORDER_PACKAGE_REQUEST",
	SUCCESS: "ORDER_PACKAGE_SUCCESS",
	FAILURE: "ORDER_PACKAGE_FAILURE"
};

export const getPromotionsForVehicle = () => {
	return (dispatch, getState) => {
		dispatch({
			type: GET_VEHICLE_PROMOTION_OVERVIEW.REQUEST
		});

		PromotionService.getPromotionsForVehicle(getState().vehicle.vehicle.vkCode)
			.then(
				response => dispatch({
					type: GET_VEHICLE_PROMOTION_OVERVIEW.SUCCESS,
					payload: response
				}),
			).catch(
			error => dispatch({
				type: GET_VEHICLE_PROMOTION_OVERVIEW.FAILURE,
				payload: error
			})
		);
	};
};

export const getPromotions = () => {
	return (dispatch, getState) => {
		dispatch({
			type: GET_PROMOTION_OVERVIEW.REQUEST
		});

		PromotionService.getPromotionsForVehicle(getState().vehicle.vehicle.vkCode)
			.then(
				response => dispatch({
					type: GET_PROMOTION_OVERVIEW.SUCCESS,
					payload: response
				}),
			).catch(
			error => dispatch({
				type: GET_PROMOTION_OVERVIEW.FAILURE,
				payload: error
			})
		);
	};
};

export const compilePackage = (packageId: string) => {
	return (dispatch, getState) => {
		dispatch({
			type: COMPILE_PACKAGE.REQUEST
		});
		PromotionService.getPromotionBundle(packageId, getState().vehicle.vehicle)
			.then(
				promotion => dispatch({
					type: COMPILE_PACKAGE.SUCCESS,
					payload: promotion
				}),
			).catch(
			error => dispatch({
				type: COMPILE_PACKAGE.FAILURE,
				payload: error
			})
		);
	};
};

export const orderPackage = (order: PromotionOrder) => {
	return dispatch => {
		dispatch({
			type: ORDER_PACKAGE.REQUEST
		});
		PromotionService.orderPromotionPackage(order)
			.then(
				() => {
					dispatch({
						type: ORDER_PACKAGE.SUCCESS,
					});
					dispatch(push(RouteDictionary.getRoutes().ORDER));
				},
			).catch(
			error => dispatch({
				type: ORDER_PACKAGE.FAILURE,
				payload: error
			})
		);
	};
};
