import AnnouncementService from "../service/AnnouncementService";

export const FETCH_RELEASE_NOTES = {
	REQUEST: "FETCH_RELEASE_NOTES_REQUEST",
	SUCCESS: "FETCH_RELEASE_NOTES_SUCCESS",
	FAILURE: "FETCH_RELEASE_NOTES_FAILURE",
};
export const FETCH_MAINTENANCE_NOTES = {
	REQUEST: "FETCH_MAINTENANCE_NOTES_REQUEST",
	SUCCESS: "FETCH_MAINTENANCE_NOTES_SUCCESS",
	FAILURE: "FETCH_MAINTENANCE_NOTES_FAILURE",
};
export const FETCH_MALFUNCTION_NOTES = {
	REQUEST: "FETCH_MALFUNCTION_NOTES_REQUEST",
	SUCCESS: "FETCH_MALFUNCTION_NOTES_SUCCESS",
	FAILURE: "FETCH_MALFUNCTION_NOTES_FAILURE",
};
export const FETCH_CLEARANCE_NOTES = {
	REQUEST: "FETCH_CLEARANCE_NOTES_REQUEST",
	SUCCESS: "FETCH_CLEARANCE_NOTES_SUCCESS",
	FAILURE: "FETCH_CLEARANCE_NOTES_FAILURE",
};

export const fetchReleaseNotes = () => {
	return dispatch => {
		dispatch({
			type: FETCH_RELEASE_NOTES.REQUEST
		});
		AnnouncementService.getReleaseNotes()
			.then(payload => dispatch({
				type: FETCH_RELEASE_NOTES.SUCCESS, payload
			}))
			.catch(() => {
				dispatch({
					type: FETCH_RELEASE_NOTES.FAILURE
				});
			});
	};
};

export const fetchMalfunctionNotes = () => {
	return dispatch => {
		dispatch({
			type: FETCH_MALFUNCTION_NOTES.REQUEST
		});
		AnnouncementService.getMalFunctionNotes()
			.then(payload => dispatch({
				type: FETCH_MALFUNCTION_NOTES.SUCCESS, payload
			}))
			.catch(() => {
				dispatch({
					type: FETCH_MALFUNCTION_NOTES.FAILURE
				});
			});
	};
};

export const fetchClearanceNotes = () => {
	return dispatch => {
		dispatch({
			type: FETCH_CLEARANCE_NOTES.REQUEST
		});
		AnnouncementService.getClearanceNotes()
			.then(payload => dispatch({
				type: FETCH_CLEARANCE_NOTES.SUCCESS, payload
			}))
			.catch(() => {
				dispatch({
					type: FETCH_CLEARANCE_NOTES.FAILURE
				});
			});
	};
};

export const fetchMaintenanceNotes = () => {
	return dispatch => {
		dispatch({
			type: FETCH_MAINTENANCE_NOTES.REQUEST
		});
		AnnouncementService.getMaintenanceNotes()
			.then(payload => dispatch({
				type: FETCH_MAINTENANCE_NOTES.SUCCESS, payload
			}))
			.catch(() => {
				dispatch({
					type: FETCH_MAINTENANCE_NOTES.FAILURE
				});
			});
	};
};
