// @flow

import {
	CLEAR_ARTICLE,
	GET_ARTICLE,
	GET_ARTICLE_ATTACHMENTS,
	GET_NEWS_ARTICLES,
	GET_SUPPORT_ARTICLES,
	GET_SUPPORT_SECTIONS
} from "../actions/articles";
import type {Section} from "../model";

export type ConversationType = "TICKET" | "ARTICLE" | "UNKNOWN";

export type Attachment = {
	id: number,
	fileName: string,
	mappedContentUrl: string,
	contentType: string,
};

export type Article = {
	id: number;
	type: ConversationType,
	title: String;
	content: String;
	createDate: Date;
	editDate: Date;
}

export type HelpCenterArticle = Article & {
	labels: string[];
	sectionIds: number[];
}

export const TicketStatus = {
	NEW: "NEW",
	OPEN: "OPEN",
	PENDING: "PENDING",
	HOLD: "HOLD",
	SOLVED: "SOLVED",
	CLOSED: "CLOSED",
	DELETED: "DELETED"
};

export type TicketStatusType = "NEW" | "OPEN" | "PENDING" | "HOLD" | "SOLVED" | "CLOSED" | "DELETED";

export type CustomField = {
	id: number,
	content: string[]
}

export type ArticlesState = {
	news: HelpCenterArticle[],
	support: HelpCenterArticle[],
	sections: Section[],
	labels: string[],
	currentAttachments: Attachment[],
	currentArticle: HelpCenterArticle,
	isSubmitting: boolean,
	isFetching: boolean,
	selectedSectionId: ?number,
	notFound: boolean,
}

const initialState: ArticlesState = {
	news: [],
	support: [],
	sections: [],
	labels: [],
	currentAttachments: [],
	currentArticle: null,
	isSubmitting: false,
	isFetching: false,
	selectedSectionId: null,
	notFound: false,
};

export const articlesReducer = (state: ArticlesState = initialState, action): ArticlesState => {
	switch (action.type) {
		case GET_SUPPORT_ARTICLES.SUCCESS:
			return {
				...state,
				support: action.payload,
				labels: [...new Set(action.payload.flatMap(article => article.labels))],
				isFetching: false,
			};
		case GET_NEWS_ARTICLES.SUCCESS:
			return {
				...state,
				news: action.payload,
			};
		case GET_ARTICLE_ATTACHMENTS.REQUEST:
			return {
				...state,
				currentAttachments: []
			};
		case GET_ARTICLE_ATTACHMENTS.SUCCESS:
			return {
				...state,
				currentAttachments: action.payload.filter(attachment => !attachment.contentType.includes("image"))
			};
		case GET_ARTICLE_ATTACHMENTS.FAILURE:
			return {
				...state,
				currentAttachments: [],
				notFound: true
			};
		case CLEAR_ARTICLE:
			return {
				...state,
				currentAttachments: [],
				currentArticle: null,
				notFound: false
			};
		case GET_ARTICLE.REQUEST:
			return {
				...state,
				isFetching: true,
				notFound: false
			};
		case GET_ARTICLE.SUCCESS:
			return {
				...state,
				currentArticle: action.payload,
				notFound: false
			};
		case GET_ARTICLE.FAILURE:
			return {
				...state,
				isFetching: false,
				notFound: true
			};
		case GET_SUPPORT_SECTIONS.SUCCESS:
			return {
				...state,
				sections: action.payload,
			};
		case GET_SUPPORT_ARTICLES.REQUEST:
			return {
				...state,
				selectedSectionId: action.payload ? action.payload.id : null,
				isFetching: true,
			};
		case GET_SUPPORT_ARTICLES.FAILURE:
			return {
				...state,
				isFetching: false,
			};
		default:
			return state;
	}
};
