// @flow
import * as React from "react";
import "./Questionnaire.scss";
import type {Answer as QuestionnaireAnswer, AnswerType} from "../../model/Questionnaire";
import type {Series} from "../../model/Series";
import type {Channel} from "../../model/Channel";
import LoadingMessage from "../../../components/loading/LoadingMessage";
import {Answer} from "./Answer";

export type QuestionnaireSubmitData = {
	attendee: string,
	questions: {
		question: string,
		answers: string[]
	}[],
	seriesName: string,
	channelId: number,
	seriesId: number,
}

type Props = {
	currentSeries: Series,
	currentChannel: Channel,
	handleFormSubmit: QuestionnaireSubmitData => void,
	handleMissingQuestions: (number, string) => void,
	emailSendingIdle: boolean,
	emailSendingError: boolean,
};

type RefObject = { current: any, };

export const Questionnaire = (props: Props) => {
	const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
	const formRef: RefObject = React.useRef(null);
	React.useEffect(() => {
		if (props.emailSendingError) {
			setIsFormSubmitted(false);
		}
	}, [props.emailSendingError]);
	const handleSubmit = e => {
		e.preventDefault();
		if (!formRef.current) return;
		const formData = formRef && Array.from(formRef.current.elements).map(el => ({
			id: el.id,
			value: el.value,
			checked: el.checked
		}));
		const allQuestionsAnswered =
			Object.values(
				Array.from(formRef.current.elements).reduce(
					(prev, current) => {
						if (current.name && !prev[current.name]) {
							prev[current.name] = current.checked;
						}
						return prev;
					}, {}
				)
			).reduce((prev, current) => prev && current, true);
		if (allQuestionsAnswered) {
			const attendee = formData[0].value;
			const answersRaw = formData
				.filter(answers => answers.checked)
				.map(answers => answers.id)
				.map(answer => answer.split("-"));

			const questions = props.currentSeries.questionnaire && props.currentSeries.questionnaire.questions.map((question, qIndex) => {
				let markedAnswers = [];
				markedAnswers.push(["-1", "-1"]);
				answersRaw.forEach(rawAnswer => {
					if (rawAnswer[0] === qIndex.toString()) markedAnswers.push(rawAnswer);
				});

				return {
					question: question.text,
					answers: question.answers
						.filter((answer, aIndex) => markedAnswers
							.filter(markedAnswer => markedAnswer[1] === aIndex.toString()).length)
						.map(answer => answer.text)
				};
			});

			props.handleFormSubmit({
				attendee,
				questions,
				seriesName: props.currentSeries.seriesName,
				channelId: props.currentChannel.id,
				seriesId: props.currentSeries.id,
			});

			setIsFormSubmitted(true);
		} else {
			let unansweredQuestionIds = [];
			Object.entries(
				Array.from(formRef.current.elements).reduce(
					(prev, current) => {
						if (current.name && !prev[current.name]) {
							prev[current.name] = current.checked;
						}
						return prev;
					}, {}
				)
			).reduce((prev, current) => {
				if (!current[1]) {
					prev.push(parseInt(current[0], 10) + 1);
				}
				return prev;
			}, unansweredQuestionIds);
			props.handleMissingQuestions(unansweredQuestionIds.length, unansweredQuestionIds.join(", "));
		}
	};

	const renderAnswers = (questionId: number, answerType: AnswerType, answers: QuestionnaireAnswer[]) => {
		return answers.map((answer: QuestionnaireAnswer, index) => <Answer key={index} index={index} answer={answer}
																		   questionId={questionId} type={answerType}/>);
	};

	const renderSubmitText = () => {
		return <p>Vielen Dank für das Ausfüllen des Fragebogens. Dieser wurde an SUBARU Deutschland übermittelt.</p>;
	};

	return <form className={"Questionnaire"} ref={formRef} onSubmit={handleSubmit}>
		<h2>Fragebogen</h2>
		{isFormSubmitted
			? props.emailSendingIdle
				? renderSubmitText()
				: <LoadingMessage/>
			: <React.Fragment>
				<p>{props.currentSeries.questionnaire.introduction}</p>
				<br/>
				<input required={true} id={"attendee"} placeholder={"Name des Schulungsteilnehmers"} type="text"/>
				{props.currentSeries.questionnaire.questions.map((question, index) => {
					return <div key={index}>
						{!!index && <hr/>}
						<h3>{index + 1}.</h3>
						<h3>{question.text}</h3>
						{renderAnswers(index, question.answerType, question.answers)}
					</div>;
				})}
				<br/>
				<div className={"control"}>
					<button type={"submit"}>absenden</button>
				</div>
			</React.Fragment>
		}
	</form>;
};
