// @flow
import type {Axios} from "axios";
import {axiosInstance} from "../../api";
import type {QuestionnaireSubmitData} from "../components/Questionnaire/Questionnaire";

export default class MediaLibService {
	api: Axios;
	apiPrefix: string;

	constructor() {
		this.api = axiosInstance;
	}

	getAll() {
		return this.api.get("/channel/", {
			params: {
				page: 0,
				pageSize: 1000
			}
		}).then(response => {
			return response.data.content;
		});
	}

	sendQuestionnaire(request: QuestionnaireSubmitData) {
		return this.api.post("/series/sendQuestionnaire", request)
			.then(response => {
				return response.data;
			});
	}
}
