// @flow
import {axiosInstance} from "../../api";
import type {FigureModel, IllustrationGroup} from "../models";
import type {$AxiosXHR, Axios} from "axios";
import i18next from "i18next";

type CoverResponse = {
	covers: Array<{
		coverGroups: Array<{
			key: string
		}>,
		figureNumber: string,
		imageUrl: string,
		ordinal: number,
		vkCode: any
	}>
};

type FigureResponse = {
	figures: Array<{
		imageUrl: string,
		label: string,
		page: number,
		content: Array<any>
	}>
};

export type Catalog = {
	series: string;
	model: string;
	catalogNumber: string;
	productionMonth: string;
	imageUri: string;
}

class GtaService {
	axios: Axios;

	constructor(axios: Axios) {
		this.axios = axios;
	}

	async getCatalogs(): Promise<Catalog[]> {
		const response: $AxiosXHR<any> = await this.axios.get("/v1/parts/catalog");
		return response.data.catalogs;
	}

	async getIllustrationGroups(modelCode: string, factoryCode: string): Promise<IllustrationGroup[]> {
		const response: $AxiosXHR<CoverResponse> = await this.axios.get("/v1/parts/covers", {
			params: {
				modelCode,
				factoryCode
			}
		});

		const results = {};
		response.data.covers.forEach(cover => {
			cover.coverGroups.forEach(coverGroup => {
				if (results.hasOwnProperty(coverGroup.key)) {
					results[coverGroup.key].push(cover);
				} else {
					results[coverGroup.key] = [cover];
				}
			});
		});
		const final: IllustrationGroup[] = [];
		for (let [key, value] of Object.entries(results)) {
			final.push({
				label: i18next.t(`gta.groups.${key}`),
				items: value
			});
		}
		return final;
	}

	async getFigures(figureNumber: string, modelCode: string, factoryCode: string): Promise<FigureModel[]> {
		const response: $AxiosXHR<FigureResponse> = await this.axios.get("/v1/parts/figures", {
			params: {
				modelCode,
				figureNumber,
				factoryCode
			}
		});
		return await Promise.all(response.data.figures.map(async figure => {
			const image = new Image();
			image.src = figure.imageUrl;
			await image.decode();
			return {
				...figure,
				image: figure.imageUrl,
				content: figure.content,
				height: image.height,
				width: image.width
			};
		}));
	}
}

const GtaServiceInstance = new GtaService(axiosInstance);
export default GtaServiceInstance;
