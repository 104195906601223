import consumableService from "../service/consumableService";

export const FETCH_CONSUMABLES = {
	REQUEST: "FETCH_CONSUMABLES_REQUEST",
	SUCCESS: "FETCH_CONSUMABLES_SUCCESS",
	FAILURE: "FETCH_CONSUMABLES_FAILURE",
};

export function getConsumables() {
	return async dispatch => {
		dispatch({type: FETCH_CONSUMABLES.REQUEST});
		try {
			const consumables = await consumableService.getConsumables();
			dispatch({
				type: FETCH_CONSUMABLES.SUCCESS,
				payload: consumables
			});
		} catch (e) {
			dispatch({
				type: FETCH_CONSUMABLES.FAILURE,
				error: e
			});
		}
	};
}
