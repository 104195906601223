import ArticleService from "../api/articleService";
import {push} from "connected-react-router";
import {createCustomFieldsFromUser, createCustomFieldsFromVehicle} from "../components/customFields";
import type {SubmitMessageRequest} from "../components/Tickets/TicketModal";
import TicketsCache from "./TicketsCache";
import type {CreateTicketParams} from "../components/Tickets/Ticket";
import {getZendeskUsers} from "./conversations";
import RouteDictionary from "../../RouteDictionary";
import {TicketStatusFilterOptions} from "../components/Tickets/TicketFilter";
import {TicketFilterCriteriaType} from "../reducer/conversationsReducer";
import type {State} from "../../reducers/rootReducer";

export const GET_ZENDESK_TICKET = {
	REQUEST: "GET_ZENDESK_TICKET_REQUEST",
	SUCCESS: "GET_ZENDESK_TICKET_SUCCESS",
	FAILURE: "GET_ZENDESK_TICKET_FAILURE"
};

export const TICKET_OVERDUE_MODAL = {
	SHOW: "SHOW_TICKET_OVERDUE_MODAL",
	HIDE: "HIDE_TICKET_OVERDUE_MODAL"
};

export const RESET_ZENDESK_TICKET = "RESET_ZENDESK_TICKET";

export const GET_ZENDESK_TICKETS = {
	REQUEST: "GET_ZENDESK_TICKETS_REQUEST",
	SUCCESS: "GET_ZENDESK_TICKETS_SUCCESS",
	FAILURE: "GET_ZENDESK_TICKETS_FAILURE"
};

export const CREATE_ZENDESK_TICKET = {
	REQUEST: "CREATE_ZENDESK_TICKET_REQUEST",
	SUCCESS: "CREATE_ZENDESK_TICKET_SUCCESS",
	FAILURE: "CREATE_ZENDESK_TICKET_FAILURE"
};
export const CREATE_ZENDESK_TICKET_MESSAGE = {
	REQUEST: "CREATE_ZENDESK_TICKET_MESSAGE_REQUEST",
	SUCCESS: "CREATE_ZENDESK_TICKET_MESSAGE_SUCCESS",
	FAILURE: "CREATE_ZENDESK_TICKET_MESSAGE_FAILURE"
};

export const SET_TICKET_FILTER = "SET_TICKET_FILTER";
export const SET_TICKET_ADDENDUM = "SET_TICKET_ADDENDUM";

export const setTicketAddendum = (payload: boolean) => {
	return {
		type: SET_TICKET_ADDENDUM,
		payload
	};
};

export const setTicketStatusFilter = (value: $Values<typeof TicketStatusFilterOptions>) => {
	return {
		type: SET_TICKET_FILTER,
		criteria: {
			field: TicketFilterCriteriaType.TICKET_STATUS,
			value
		}
	};
};

export const getTicket = (ticketId: number) => {
	return dispatch => {
		dispatch({
			type: GET_ZENDESK_TICKET.REQUEST
		});
		Promise.all(
			[
				ArticleService.getTicket(ticketId),
				ArticleService.getTicketMessages(ticketId)
			]
		)
			.then(payloads => {
				dispatch({
					type: GET_ZENDESK_TICKET.SUCCESS,
					payload: {
						content: payloads[0],
						messages: payloads[1]
					}
				});
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: GET_ZENDESK_TICKET.FAILURE,
					payload: error
				});
			});
	};
};

export const submitMessage = (conversationId: number, params: SubmitMessageRequest) => {
	return dispatch => {
		dispatch({
			type: CREATE_ZENDESK_TICKET_MESSAGE.REQUEST
		});
		const request = new FormData();
		request.append("form", JSON.stringify({
			message: params.message,
		}));
		params.uploads.forEach(upload => {
			request.append("uploads", upload);
		});
		ArticleService.addMessageToTicket(conversationId, request)
			.then(() => {
				dispatch({
					type: CREATE_ZENDESK_TICKET_MESSAGE.SUCCESS
				});
				dispatch(getTicket(conversationId));
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: CREATE_ZENDESK_TICKET_MESSAGE.FAILURE
				});
			});
	};
};

export const createTicket = (params: CreateTicketParams) => {
	return (dispatch, getState) => {
		const vehicle = getState().vehicle.vehicle;
		const dealerGroup = getState().auth.dealerGroup;
		const customFields = [
			...createCustomFieldsFromUser(params, dealerGroup),
			...createCustomFieldsFromVehicle(vehicle, params.mileAge, params.errorCode, params.notRoadworthy)
		];
		const request = new FormData();
		request.append("form", JSON.stringify({
			title: params.title,
			description: params.description,
			customFields
		}));
		params.uploads.forEach(upload => {
			request.append("uploads", upload);
		});
		dispatch({
			type: CREATE_ZENDESK_TICKET.REQUEST
		});
		ArticleService.createTicket(request)
			.then(res => {
				dispatch({
					type: CREATE_ZENDESK_TICKET.SUCCESS
				});
				dispatch(getTickets());
				TicketsCache.addTicket(res.id);
				dispatch(push(`/${RouteDictionary.getRoutes().SUSY}/${res.id}`));
			})
			.catch(err => {
				console.error(err);
				dispatch({
					type: CREATE_ZENDESK_TICKET.FAILURE
				});
			});
	};
};

export const getAllTickets = () => {
	return dispatch => {
		dispatch({
			type: GET_ZENDESK_TICKETS.REQUEST
		});
		dispatch(getZendeskUsers());

		ArticleService.getTickets()
			.then(payload => {
				dispatch({
					type: GET_ZENDESK_TICKETS.SUCCESS,
					payload
				});
			})
			.catch(err => {
					console.error(err);
					dispatch({
						type: GET_ZENDESK_TICKETS.FAILURE
					});
				}
			);
	};
};

export const getTickets = () => {
	return (dispatch, getState: () => State) => {
		dispatch({
			type: GET_ZENDESK_TICKETS.REQUEST
		});
		dispatch(getZendeskUsers());

		const {ticketFilter} = getState().susy.conversations;

		ArticleService.findTickets(ticketFilter)
			.then(payload => {
				dispatch({
					type: GET_ZENDESK_TICKETS.SUCCESS,
					payload
				});
			})
			.catch(err => {
					console.error(err);
					dispatch({
						type: GET_ZENDESK_TICKETS.FAILURE
					});
				}
			);
	};
};


export const computeOverdueTickets = () => {
	return dispatch => {
		dispatch(getZendeskUsers());
		ArticleService.getTickets()
			.then(payload => {
				const threshold = 14 * 24 * 60 * 60 * 1000;
				const now = new Date().getTime();

				const overdueTickets = payload
					.filter(ticket => ticket.status === "PENDING")
					.filter(ticket => {
						const lastUpdated = new Date(ticket.editDate).getTime();
						return now - lastUpdated - threshold > 0;
					});

				if (overdueTickets.length > 0) {
					dispatch({
						type: TICKET_OVERDUE_MODAL.SHOW,
						payload: overdueTickets.length
					});
				}
			});
	};
};

export const hideTicketOverdueModal = () => {
	return dispatch => {
		dispatch({
			type: TICKET_OVERDUE_MODAL.HIDE,
		});
	};
};
