import {TicketStatusFilterOptions} from "../components/Tickets/TicketFilter";

class TicketFilterStatusMapper {
	fromStatus(status: $Values<typeof TicketStatusFilterOptions>) {
		switch (status) {
			case TicketStatusFilterOptions.OPEN:
				return {
					field: "status",
					value: "HOLD",
					comparator: "less_than"
				};
			case TicketStatusFilterOptions.CLOSED:
				return {
					field: "status",
					value: "HOLD",
					comparator: "greater_than"
				};
			case TicketStatusFilterOptions.PENDING:
				return {
					field: "status",
					value: "PENDING",
					comparator: "equals"
				};
			default:
				return null;
		}
	}
}

const ticketFilterStatusMapperInstance = new TicketFilterStatusMapper();
export default ticketFilterStatusMapperInstance;
