/* eslint-disable */
import React from "react";
import {AuthProvider} from "react-oidc-context";
import {createRoot} from "react-dom/client";
import App from "./App";
import Configuration from "./Configuration";
import {unregister} from "./registerServiceWorker";
import "./i18n/i18n.js";
import {oidcConfig} from "./service/authService";

console.info("Active configuration: ", Configuration.CONFIG);

const container = document.getElementById("root");

createRoot(container).render(
	<AuthProvider {...oidcConfig}>
		<App/>
	</AuthProvider>
);
if (module.hot) {
	module.hot.accept("./App", () => {
		const NextApp = require("./App").default;
		createRoot(container).render(<NextApp/>);
	});
}

unregister();
