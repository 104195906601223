// @flow

interface TextShortening {
	shortenText(text: string): string;
}

class DescriptionShortener implements TextShortening {
	shortenText(text: string) {
		if (text.length <= 75) return text;
		if (text.indexOf(".", 75) >= 0) {
			return text.substring(0, text.indexOf(".", 75) + 1);
		}
		if (text.indexOf(",", 75) >= 0) {
			return `${text.substring(0, text.indexOf(",", 75))}...`;
		}
		if (text.indexOf(" ", 75) >= 0) {
			return `${text.substring(0, text.indexOf(" ", 75))}...`;
		}
		return `${text.substring(0, 75)}...`;
	}
}

const DescriptionShortenerInstance = new DescriptionShortener();
export default DescriptionShortenerInstance;
