import type {Axios} from "axios";
import {axiosProductInstance} from "../../api";
import type {FacetedWheels, RimModel, VehicleModel} from "../models";
import {WheelFilter} from "../models";

class WheelFinder {
	productClient: Axios;

	constructor() {
		this.productClient = axiosProductInstance;
	}

	getWheelVehicleModels() : Promise<Array<VehicleModel>> {
		return this.productClient.get("products/wheel/vehicles")
			.then(response => response.data);
	}

	buildApiFilterParameter(filterParameters: WheelFilter) {
		return {
			vehicleModel: filterParameters.vehicle.model,
			rimPartNumber: filterParameters.rim.partNumber,
			rimDiameter: filterParameters.rim.diameter,
			rimWidth: filterParameters.rim.width,
			rimOffset: filterParameters.rim.offset,
			tireWidth: filterParameters.tire.width,
			tireBrand: filterParameters.tire.brand,
			tireDiameter: filterParameters.tire.diameter,
			tireType: filterParameters.tire.type,
			tireAspectRatio: filterParameters.tire.aspectRatio,
			tireLoadCapacityIndex: filterParameters.tire.loadCapacityIndex
		};
	}
	getByFilterParameters(filterParameters: WheelFilter): Promise<FacetedWheels> {
		return this.productClient.get("/products/wheel/search",
			{
				params: this.buildApiFilterParameter(filterParameters)
			})
			.then(response => response.data);
	}

	getRim(partNumber: string): Promise<RimModel> {
		return this.productClient.get("/products/wheel/rim",
			{
				params: {partNumber}
			})
			.then(response => response.data);
	}
}

const WheelFinderInstance = new WheelFinder();
export default WheelFinderInstance;