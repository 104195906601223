// @flow

import type {AcademyAction} from "../actions/medialib";
import {FETCH_CHANNELS, QUESTIONNAIRE_FORM_SUBMIT} from "../actions/medialib";
import type {Channel} from "../model/Channel";

export type MediaLibReducerState = {
	isIdle: boolean,
	channels: Channel[],
	emailSendingIdle: boolean,
	emailSendingError: boolean
}

const initialState = {
	isIdle: true,
	channels: [],
	emailSendingIdle: true,
	emailSendingError: false,
};

const mediaLib = (state: MediaLibReducerState = initialState, action: AcademyAction) => {
	switch (action.type) {
		case FETCH_CHANNELS.REQUEST:
			return {
				...state,
				isIdle: false,
			};
		case FETCH_CHANNELS.SUCCESS:
			return {
				...state,
				isIdle: true,
				channels: action.payload,
			};
		case FETCH_CHANNELS.FAILURE:
			return {
				isIdle: true,
				...state
			};

		case QUESTIONNAIRE_FORM_SUBMIT.REQUEST:
			return {
				...state,
				emailSendingIdle: false,
				emailSendingError: false,
			};

		case QUESTIONNAIRE_FORM_SUBMIT.SUCCESS:
			return {
				...state,
				emailSendingIdle: true,
				emailSendingError: false,
			};

		case QUESTIONNAIRE_FORM_SUBMIT.FAILURE:
			return {
				...state,
				emailSendingIdle: true,
				emailSendingError: true,
			};
		default:
			return state;
	}
};

export default mediaLib;
