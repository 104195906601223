// @flow

import type {News} from "../models/News";
import type {NewsAction} from "../actions";
import {
	FETCH_ALL_NEWS_FAILURE,
	FETCH_ALL_NEWS_REQUEST,
	FETCH_ALL_NEWS_SUCCESS, FETCH_NEWS_FAILURE, FETCH_NEWS_REQUEST, FETCH_NEWS_SUCCESS,
	FETCH_RECENT_NEWS_FAILURE,
	FETCH_RECENT_NEWS_REQUEST,
	FETCH_RECENT_NEWS_SUCCESS,
	RESET_RECENT_NEWS
} from "../actions";

export type NewsReducerState = {
	availableNews: News[],
	isIdle: boolean,
	isFetchingRecentNews: boolean,
	isFetchingNews: boolean,
	recentNews: News[],
	selectedNews: News,
	fetchRecentNewsError: string
}

const initialState = {
	isIdle: true,
	availableNews: [],
	isFetchingRecentNews: false,
	isFetchingNews: false,
	recentNews: [],
	selectedNews: null,
	fetchRecentNewsError: ""
};

const news = (state: NewsReducerState = initialState, action: NewsAction) => {
	switch (action.type) {
		case FETCH_ALL_NEWS_REQUEST:
			return {
				...state,
				isIdle: false
			};
		case FETCH_ALL_NEWS_SUCCESS:
			return {
				...state,
				availableNews: action.payload,
				isIdle: true
			};
		case FETCH_ALL_NEWS_FAILURE:
			return {
				...state,
				isIdle: true
			};
		case FETCH_RECENT_NEWS_REQUEST:
			return {
				...state,
				isFetchingRecentNews: true
			};
		case FETCH_RECENT_NEWS_SUCCESS:
			return {
				...state,
				isFetchingRecentNews: false,
				recentNews: action.recentNews
			};
		case FETCH_RECENT_NEWS_FAILURE:
			return {
				...state,
				isFetchingRecentNews: false,
				fetchRecentNewsError: action.errorMessage
			};
		case FETCH_NEWS_REQUEST:
			return {
				...state,
				isFetchingNews: true
			};
		case FETCH_NEWS_SUCCESS:
			return {
				...state,
				isFetchingNews: false,
				selectedNews: action.news
			};
		case FETCH_NEWS_FAILURE:
			return {
				...state,
				isFetchingNews: false
			};
		case RESET_RECENT_NEWS:
			return {
				...state,
				recentNews: []
			};
		default:
			return state;
	}
};

export default news;
