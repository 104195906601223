import type {State} from "../../reducers/rootReducer";
import {CLOSE_GALLERY, SHOW_GALLERY} from "../../productsearch/actions/imageGallery";

const initialState = {
	product: null,
	showGallery: false
};

const suggestions = (state: State = initialState, action) => {
	switch (action.type) {
		case SHOW_GALLERY:
			return {
				...state,
				product: action.product,
				showGallery: true
			};
		case CLOSE_GALLERY:
			return {
				...state,
				showGallery: false
			};
		default:
			return state;
	}
};

export default suggestions;
