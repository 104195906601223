import {Axios} from "axios";
import {axiosDmsInstance} from "../../api";


class DmsService {
	axios: Axios;

	constructor() {
		this.axios = axiosDmsInstance;
	}

	async getLocalStock(partNumbers: string[]): Promise<void> {
		return this.axios.post("/products/stock", {partNumbers});
	}

	async getVehicleByVin(vin: string): Promise<void> {
		return this.axios.get(`/vehicle/vin/${vin}`);
	}

	async searchVehiclesByLicensePlate(licensePlate: string): Promise<void> {
		return this.axios.post("/vehicle/licensePlate", {licensePlate});
	}

	async searchVehiclesByCustomerName(name: string): Promise<void> {
		return this.axios.post("/vehicle/customer/name", {name});
	}

	async searchVehiclesByCustomerEmail(email: string): Promise<void> {
		return this.axios.post("/vehicle/customer/email", {email});
	}

	async searchVehiclesByCustomerPhone(phone: string): Promise<void> {
		return this.axios.post("/vehicle/customer/phone", {phone});
	}
}

const DmsServiceInstance = new DmsService();
export default DmsServiceInstance;
