// @flow
import React from "react";
import "./buttons.scss";

type IconProps = {
	name?: string,
	onClick?: () => void,
	url?: string,
	download?: string,
	title?: string,
	target?: string,
	disabled?: boolean
};

const Icon = (props: IconProps) => {
	if (props.onClick || props.url) {
		return (
			<a title={props.title} className={`${props.name} ${props.disabled ? "disabled" : ""}`} onClick={props.disabled ? null : props.onClick} href={props.url}
			   download={props.download} target={props.target}> </a>
		);
	} else {
		return (
			<span title={props.title} className={`${props.name} ${props.disabled ? "disabled" : ""}`}/>
		);
	}
};

export default Icon;
