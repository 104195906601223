import type {Amount, Currency} from "../../service/productService";
import type {Consumable} from "../../profile/reducer/profile";

export type PartAmounts = {
	[partNumber: string]: number
}
export type Price = {
	value: number,
	currency: Currency
}

export type Customer = {
	address: string,
	city: string,
	zipCode: string,
	email: string,
	name: string,
	phone: string,
	vehicleRegistrationNumber: string,
	salutation: "MR" | "MS" | "NONE",
	vehicleMileage: number,
}

export type InspectionTask = {
	name: string,
	parts: {
		partNumber: string,
		amount: number
	},
	consumables: Consumable[]
}

export type InspectionData = {
	name: string,
	workingHours: number,
	tasks: InspectionTask[],
}

export type TaskPositionType = {
	id: number,
	operationNumber: string,
	name: string,
	productNumber: string,
	workingHours: number,
	tasklets: { name: string }[],
	customizable: boolean
}

export type ConsumablePositionType = {
	name: string,
	amount: Amount,
	unitPrice: Price,
	positionPrice: Price,
}
export type SparePositionType = {
	name: string,
	productNumber: string,
	unitPrice: Price,
	retailPrice: Price,
	amount: Amount,
	positionPrice: Price,
	partCode: string,
	partCodeSymbol: string
}

export type ProposalState =
	"OPEN"
	| "FINISHED"
	| "ACCEPTED"
	| "REJECTED"

export type Proposal = {
	aggregateId: string,
	identification: string,
	vehicle: string,
	hourlyRate: number,
	vat: number,
	customer: Customer,
	tasks: TaskPositionType[],
	spares: SparePositionType[],
	consumables: ConsumablePositionType[],
	proposalPrice: Price,
	sparePositionsPrice: Price,
	sparePositionsDiscount: { value: number },
	discountedSparePositionsPrice: Price,
	discountedTaskPositionsPrice: Price,
	taskPositionsPrice: Price,
	taskPositionsDiscount: { value: number },
	consumablePositionsPrice: Price,
	consumablePositionsDiscount: { value: number },
	discountedConsumablePositionsPrice: Price,
	vatPrice: Price,
	state: ProposalState,
	createdAt: Date,
	finishedAt: Date,
	validFrom: Date,
	validUntil: Date,
}

export type Sort = [
	{
		[field:string]: "asc" | "desc"
	}
]

export type ProposalPagination = {
	size: number,
	page: number,
	totalPages: number,
	sort: Sort
}

export type ProposalFilter = {
	proposalName?: string,
	customerName?: string,
	startDate?: Date,
	endDate?: Date,
	vin?: string,
	vehicleRegistrationNumber?: string,
	state?: ProposalState
}

export type UpdateDiscountsRequest = {
	sparePositionsDiscount: { value: number },
	taskPositionsDiscount: { value: number },
	consumablePositionsDiscount: { value: number },
}

export type ProposalPrintData = {
	id: string,
	name: string
}

export const AddendumType = Object.freeze({
	CREATE_PROPOSAL: "CREATE_PROPOSAL",
	IMPORT_MAINTENANCE_LIST: "IMPORT_MAINTENANCE_LIST",
	ADD_CONSUMABLE: "ADD_CONSUMABLE",
	ADD_PRODUCT: "ADD_PRODUCT",
	ADD_TASK: "ADD_TASK",
	ADD_SPARE_PART_TASK: "ADD_SPARE_PART_TASK",
	EDIT_COSTUMER: "EDIT_COSTUMER",
	CONFIRM_PROPOSAL: "CONFIRM_PROPOSAL",
	PRINT_PROPOSAL: "PRINT_PROPOSAL"
});