import {COMPILE_PACKAGE, GET_PROMOTION_OVERVIEW, GET_VEHICLE_PROMOTION_OVERVIEW} from "../action";
import {RESET_VEHICLE, RESTORE_VEHICLE, SET_VEHICLE} from "../../vehicle/action";
import type {PromotionBundle, PromotionModel} from "../model/Promotion";

export type PromotionReducerState = {
	vehiclePromotions: PromotionModel[],
	promotions: PromotionModel[],
	vehiclePackage: ?PromotionBundle,
	loadingQueue: string[],
};

const initialState: PromotionReducerState = {
	vehiclePromotions: [],
	promotions: [],
	vehiclePackage: null,
	loadingQueue: [],
};

export default function promotionReducer(state: PromotionReducerState = initialState, action: any) {
	switch (action.type) {
		case RESTORE_VEHICLE:
		case SET_VEHICLE:
		case RESET_VEHICLE:
			return {
				...initialState
			};

		case COMPILE_PACKAGE.REQUEST:
			return {
				...state,
				loadingQueue: [...state.loadingQueue, action.type],
				vehiclePackage: null
			};

		case COMPILE_PACKAGE.SUCCESS:
			return {
				...state,
				loadingQueue: state.loadingQueue.filter((type: string) => type !== COMPILE_PACKAGE.REQUEST),
				vehiclePackage: action.payload
			};

		case COMPILE_PACKAGE.FAILURE:
			return {
				...state,
				vehiclePackage: null,
				loadingQueue: state.loadingQueue.filter((type: string) => type !== COMPILE_PACKAGE.REQUEST)
			};

		case GET_VEHICLE_PROMOTION_OVERVIEW.REQUEST:
			return {
				...state,
				loadingQueue: [...state.loadingQueue, action.type],
			};

		case GET_VEHICLE_PROMOTION_OVERVIEW.SUCCESS:
			return {
				...state,
				vehiclePromotions: action.payload.promotions,
				loadingQueue: state.loadingQueue.filter((item: string) => item !== GET_VEHICLE_PROMOTION_OVERVIEW.REQUEST),
			};

		case GET_VEHICLE_PROMOTION_OVERVIEW.FAILURE:
			return {
				...state,
				vehiclePromotions: [],
				loadingQueue: state.loadingQueue.filter((item: string) => item !== GET_VEHICLE_PROMOTION_OVERVIEW.REQUEST),
			};

		case GET_PROMOTION_OVERVIEW.REQUEST:
			return {
				...state,
				loadingQueue: [...state.loadingQueue, action.type],
			};

		case GET_PROMOTION_OVERVIEW.SUCCESS:
			return {
				...state,
				promotions: action.payload.promotions,
				loadingQueue: state.loadingQueue.filter((item: string) => item !== GET_PROMOTION_OVERVIEW.REQUEST),
			};

		case GET_PROMOTION_OVERVIEW.FAILURE:
			return {
				...state,
				promotions: [],
				loadingQueue: state.loadingQueue.filter((item: string) => item !== GET_PROMOTION_OVERVIEW.REQUEST),
			};

		default:
			return state;
	}
}
