import type {Facet} from "../../productsearch/productApi";
import type {Product, ProductDetail} from "../../service/productService";

export const WheelConfigurationStep = Object.freeze({
	VEHICLE: "VEHICLE",
	RIM: "RIM",
	TIRE: "TIRE",
	WHEEL: "WHEEL"
});

export type RimModel = {
	partNumber: string,
	name: string,
	diameter: string,
	width: string,
	offset: string,
	design: string,
	pitchCircleDiameter: string
}

export type TireModel = {
	name: string,
	description: string,
	brand: string,
	width: string,
	aspectRatio: string,
	diameter: string,
	loadCapacityIndex: string,
	type: string,
	speedIndex: string,
	imageUris: string[]
}

export type VehicleModel = {
	model: string,
	imageUri: string,
}

export type WheelModel = {
	partNumber: string,
	vehicle: VehicleModel,
	rim: RimModel,
	tire: TireModel,
}

export type FacetedWheels = {
	content: WheelModel[],
	rimFacets: Facet[],
	tireFacets: Facet[]
}

export type WheelFilter = {
	vehicle: VehicleModel,
	rim: RimModel,
	tire: TireModel
}

export type EnhancedRimModel = RimModel & {
	documents: Object[],
	detail: ProductDetail,
	product: Product
}

export type EnhancedWheelModel = WheelModel & {
	partNumber: string,
	detail: ProductDetail,
	product: Product
}