import type {EnhancedRimModel, EnhancedWheelModel, WheelModel} from "../models";
import productService from "../../service/productService";

export const enhanceRims = async (rimPartNumbers: string[]): Promise<Array<EnhancedRimModel>> => {
	return await productService.getDetailedProducts(rimPartNumbers, false, true, false);
};

export const enhanceWheels = async (wheels: WheelModel[]): Promise<Array<EnhancedWheelModel>> => {
	const partNumbers = wheels.map(wheel => wheel.partNumber);
	const wheelProductDetails = await productService.getDetailedProducts(partNumbers, false, true, false);
	return wheels.map(wheel => {
		const detailedProduct = wheelProductDetails.find(detailedProduct => detailedProduct.partNumber === wheel.partNumber);
		return {
			...wheel,
			detail: detailedProduct?.detail || {}
		};
	});
};