import ArticleService from "../api/articleService";

export const GET_ARTICLE = {
	REQUEST: "GET_ARTICLE_REQUEST",
	SUCCESS: "GET_ARTICLE_SUCCESS",
	FAILURE: "GET_ARTICLE_FAILURE"
};
export const GET_NEWS_ARTICLES = {
	REQUEST: "GET_NEWS_ARTICLES_REQUEST",
	SUCCESS: "GET_NEWS_ARTICLES_SUCCESS",
	FAILURE: "GET_NEWS_ARTICLES_FAILURE"
};
export const GET_ARTICLE_ATTACHMENTS = {
	REQUEST: "GET_NEWS_ARTICLE_ATTACHMENTS_REQUEST",
	SUCCESS: "GET_NEWS_ARTICLE_ATTACHMENTS_SUCCESS",
	FAILURE: "GET_NEWS_ARTICLE_ATTACHMENTS_FAILURE",
};

export const CLEAR_ARTICLE = "CLEAR_ARTICLE";

export const GET_SUPPORT_ARTICLES = {
	REQUEST: "GET_SUPPORT_ARTICLES_REQUEST",
	SUCCESS: "GET_SUPPORT_ARTICLES_SUCCESS",
	FAILURE: "GET_SUPPORT_ARTICLES_FAILURE"
};
export const GET_SUPPORT_SECTIONS = {
	REQUEST: "GET_SUPPORT_SECTIONS_REQUEST",
	SUCCESS: "GET_SUPPORT_SECTIONS_SUCCESS",
	FAILURE: "GET_SUPPORT_SECTIONS_FAILURE"
};

export const getSupportArticles = () => {
	return dispatch => {
		dispatch({
			type: GET_SUPPORT_ARTICLES.REQUEST
		});
		ArticleService.getSupportArticles()
			.then(payload => {
				dispatch({
					type: GET_SUPPORT_ARTICLES.SUCCESS, payload
				});
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: GET_SUPPORT_ARTICLES.FAILURE
				});
			});
	};
};

export const getArticle = (articleId: number) => {
	return dispatch => {
		dispatch({
			type: GET_ARTICLE.REQUEST
		});
		ArticleService.getArticle(articleId)
			.then(payload => {
				dispatch({
					type: GET_ARTICLE.SUCCESS,
					payload
				});
			})
			.catch(
				() => {
					dispatch({
						type: GET_ARTICLE.FAILURE
					});
				}
			);
	};
};

export const getNewsArticles = () => {
	return dispatch => {
		dispatch({
			type: GET_NEWS_ARTICLES.REQUEST
		});
		ArticleService.getNewsArticles()
			.then(payload => dispatch({
				type: GET_NEWS_ARTICLES.SUCCESS, payload
			}))
			.catch(
				() => {
					dispatch({
						type: GET_NEWS_ARTICLES.FAILURE
					});
				}
			);
	};
};

export const clearArticle = () => {
	return dispatch => {
		dispatch({
			type: CLEAR_ARTICLE
		});
	};
};

export const getArticleAttachments = (articleId: number) => {
	return dispatch => {
		dispatch({
			type: GET_ARTICLE_ATTACHMENTS.REQUEST
		});
		ArticleService.getArticleAttachments(articleId)
			.then(payload => dispatch({
				type: GET_ARTICLE_ATTACHMENTS.SUCCESS, payload
			}))
			.catch(
				() => {
					dispatch({
						type: GET_ARTICLE_ATTACHMENTS.FAILURE
					});
				}
			);
	};
};

export const searchSupportArticles = (filter: ArticleFilter) => {
	return dispatch => {
		dispatch({
			type: GET_SUPPORT_ARTICLES.REQUEST,
			payload: filter
		});

		ArticleService.searchSupportArticles(filter)
			.then(payload => dispatch({
				type: GET_SUPPORT_ARTICLES.SUCCESS, payload
			}))
			.catch(
				() => {
					dispatch({
						type: GET_SUPPORT_ARTICLES.FAILURE
					});
				}
			);
	};
};

export const fetchSupportSections = () => {
	return dispatch => {
		dispatch({
			type: GET_SUPPORT_SECTIONS.REQUEST
		});
		ArticleService.getSupportSections()
			.then(payload => dispatch({
				type: GET_SUPPORT_SECTIONS.SUCCESS, payload
			}))
			.catch(
				() => {
					dispatch({
						type: GET_SUPPORT_SECTIONS.FAILURE
					});
				}
			);
	};
};
