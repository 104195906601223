export const SHOW_GALLERY = "SHOW_GALLERY";
export const CLOSE_GALLERY = "CLOSE_GALLERY";

export function showGallery(product) {
	return {
		type: SHOW_GALLERY,
		product
	};
}

export function closeGallery() {
	return {
		type: CLOSE_GALLERY
	};
}
