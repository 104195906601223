// @flow
import type {Axios} from "axios";
import qs from "query-string";
import {axiosInstance} from "../../api";

export type PagedDocuments = {
	categories: [
		{
			categoryName: string,
			documents: [
				{
					documentCategoryName: string,
					documentType: string,
					id: string,
					language: string,
					name: string,
					url: string
				}
			]
		}
	],
	paging: {
		currentPage: number,
		pageSize: number,
		totalItems: number,
		totalPages: number
	}
};

export default class DocumentService {
	api: Axios;

	constructor() {
		this.api = axiosInstance;
	}

	getDocumentsByPartNumber(partNumber) {
		return this.api.get("/v1/document/search/part",
			{
				params: {
					partNumber,
					pageNumber: 0,
					pageSize: 100
				},
				headers: {"x-language": "de"},
				paramsSerializer: params => qs.stringify(params, {arrayFormat: "repeat"})
			}
		).then(response => response.data);
	}

	async getDocumentsByVkPattern(vkPattern: string, documentCategories: string[]): Promise<PagedDocuments> {
		return this.api.get("/v1/document/search/vehicle",
			{
				params: {
					vkPattern,
					documentCategories,
					pageNumber: 0,
					pageSize: 100
				},
				headers: {"x-language": "de"},
				paramsSerializer: params => qs.stringify(params, {arrayFormat: "repeat"})
			}
		).then(response => response.data);
	}
}
