import React, {useEffect} from "react";
import "./App.scss";
import {Provider} from "react-redux";
import store, {history} from "./configureStore";
import {ConnectedRouter} from "connected-react-router";
import ScrollToTop from "./components/scroll/ScrollToTop";
import {useAuth} from "react-oidc-context";
import authService from "./service/authService";
import Configuration from "./Configuration";
import MandatorProvider, {MANDATOR} from "./mandator/MandatorProvider";
import Spinner from "./components/loading/Spinner";
import {t} from "i18next";

const Subaru = React.lazy(() => import("./mandator/SU"));
const Daihatsu = React.lazy(() => import("./mandator/DH"));

const App = () => {
	const auth = useAuth();

	useEffect(() => {
		return auth.events.addAccessTokenExpiring(() => auth.signinSilent().then(user => store.dispatch(authService.loginSuccess(user))));
	});


	const renderMandator = () => {
		switch (MandatorProvider.get()) {
			case MANDATOR.SUBARU.DE:
				return <Subaru/>;
			case MANDATOR.DAIHATSU.AT:
			case MANDATOR.DAIHATSU.DE:
				return <Daihatsu/>;
			default:
				return <div>{t("mandatorUnset", {mandator: Configuration.value("mandator")})}</div>;
		}
	};

	return <Provider store={store}>
		<ConnectedRouter history={history}>
			<ScrollToTop>
				<React.Suspense fallback={<Spinner/>}>
					{renderMandator()}
				</React.Suspense>
			</ScrollToTop>
		</ConnectedRouter>
	</Provider>;
};

export default App;
