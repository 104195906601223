// @flow

import {
	DETAILS_FAILURE,
	DETAILS_REQUEST,
	DETAILS_SUCCESS,
	FIGURE_PRODUCT_SEARCH_FAILURE,
	FIGURE_PRODUCT_SEARCH_REQUEST,
	FIGURE_PRODUCT_SEARCH_SUCCESS,
	FOUND_ALTERNATIVE_PART,
	HIDE_PRODUCT_DETAILS,
	PRODUCTS_FAILURE,
	PRODUCTS_REQUEST,
	PRODUCTS_RESET,
	PRODUCTS_SET_PAGE,
	PRODUCTS_SET_PAGE_SIZE,
	PRODUCTS_SUCCESS,
	RESET_ALTERNATIVE_PART,
	RESET_PARTCODE_SEARCH,
	SET_PRODUCT_FILTER_PARAMETERS,
	SHOW_PRODUCT_DETAILS
} from "../../productsearch/actions/search";
import {PART_FAILURE, PART_REQUEST, PART_SUCCESS} from "../../documentsearch/actions/searchPart";
import type {Action} from "../../actions";
import {ProductModel} from "../../productsearch/productApi";
import type {PromotionSpecialPrice} from "../../service/productService";

export type ProductSearchState = {
	isFetching: boolean,
	isFetchingDetails: boolean,
	isFetchingDocuments: boolean,
	requestId: ?string,
	products: ProductModel[],
	unmatchedPartCodes: any[],
	productNotFoundPartNumbers: string[],
	documents: any[],
	storages: ProductStorage[],
	salesPromotions: SalesPromotion[],
	scalePrices: ScalePrice[],
	specialPrices: PromotionSpecialPrice[],
	errorMessage?: string,
	page: number,
	pageSize: number,
	totalPages: number,
	totalItems: number,
	hasSearched: boolean,
	suggestions: any[],
	suggestionsSearchPhrase: {
		fuzzy: string,
		filterParameter: ?{ [string]: any }
	},
	vehicleSuggestions: any[],
	vehicleSuggestionsSearchPhrase: {
		fuzzy: string,
		filterParameter: ?{ [string]: any }
	},
	productDetailsOpen: any[],
	filterParameter: {
		promotionIds: any[],
		partNumbers: any[],
	},
	filterFacets: any[],
	hasAlternativePart: boolean
};

const initialState: ProductSearchState = {
	isFetching: false,
	isFetchingDetails: false,
	isFetchingDocuments: false,
	requestId: null,
	products: [],
	unmatchedPartCodes: [],
	productNotFoundPartNumbers: [],
	documents: [],
	storages: {},
	salesPromotions: {},
	scalePrices: {},
	specialPrices: {},
	errorMessage: "",
	page: 0,
	pageSize: 10,
	totalPages: 0,
	totalItems: 0,
	hasSearched: false,
	suggestions: [],
	suggestionsSearchPhrase: {fuzzy: "", filterParameter: null},
	vehicleSuggestions: [],
	vehicleSuggestionsSearchPhrase: {fuzzy: "", filterParameter: null},
	productDetailsOpen: [],
	filterParameter: {
		promotionIds: [],
		partNumbers: [],
	},
	filterFacets: [],
	hasAlternativePart: false,
};

const productSearch = (state: ProductSearchState = initialState, action: Action): ProductSearchState => {
	switch (action.type) {
		case FOUND_ALTERNATIVE_PART:
			return {
				...state,
				hasAlternativePart: true,
			};
		case RESET_ALTERNATIVE_PART:
			return {
				...state,
				hasAlternativePart: false,
			};
		case PRODUCTS_REQUEST:
			return {
				...state,
				hasSearched: false,
				isFetching: true,
				requestId: action.requestId
			};
		case DETAILS_REQUEST:
			return state.requestId === action.requestId
				? {
					...state,
					isFetchingDetails: true,
				}
				: state;
		case PART_REQUEST:
			return {
				...state,
				isFetchingDocuments: true,
				documents: []
			};
		case PART_SUCCESS:
			return {
				...state,
				documents: [...state.documents, action.response],
				isFetchingDocuments: false,
			};
		case PART_FAILURE:
			return {
				...state,
				isFetchingDocuments: false,
				errorMessage: action.error,
				hasSearched: true
			};
		case PRODUCTS_SUCCESS:
			return state.requestId === action.requestId
				? {
					...state,
					products: [...action.products.content],
					page: action.products.number,
					totalItems: action.products.totalElements,
					totalPages: action.products.totalPages,
					isFetching: false,
					errorMessage: "",
					hasSearched: true,
				}
				: state;
		case RESET_PARTCODE_SEARCH:
			return {
				...state,
				products: [],
				unmatchedPartCodes: [],
			};
		case DETAILS_SUCCESS:
			if (state.requestId !== action.requestId) {
				return state;
			}

			const storages = {};
			const salesPromotions = {};
			const scalePrices = {};
			const specialPrices = {};
			let products = state.products;
			action.response.forEach(detail => {
				storages[detail.partNumber] = detail.storage;
				salesPromotions[detail.partNumber] = detail.salesPromotions;
				scalePrices[detail.partNumber] = detail.scalePrices;
				specialPrices[detail.partNumber] = detail.specialPrices;
			});
			return {
				...state,
				products,
				scalePrices,
				specialPrices,
				storages,
				salesPromotions,
				isFetchingDetails: false,
				requestId: null
			};
		case PRODUCTS_FAILURE:
			return state.requestId === action.requestId
				? {
					...state,
					isFetching: false,
					errorMessage: action.error,
					hasSearched: true,
					requestId: null
				}
				: state;
		case FIGURE_PRODUCT_SEARCH_REQUEST:
			return {
				...state,
				hasSearched: false,
				isFetching: true,
				requestId: action.requestId
			};
		case FIGURE_PRODUCT_SEARCH_SUCCESS:
			return state.requestId === action.requestId
				? {
					...state,
					products: [...action.products],
					unmatchedPartCodes: action.unmatchedPartCodes,
					productNotFoundPartNumbers: action.productNotFoundPartNumbers,
					totalItems: action.products.size,
					isFetching: false,
					errorMessage: "",
					hasSearched: true,
				}
				: state;
		case FIGURE_PRODUCT_SEARCH_FAILURE:
			return state.requestId === action.requestId
				? {
					...state,
					isFetching: false,
					errorMessage: action.error,
					hasSearched: true,
					requestId: null
				}
				: state;
		case DETAILS_FAILURE:
			return state.requestId === action.requestId
				? {
					...state,
					isFetchingDetails: false,
					requestId: null
				} : state;
		case PRODUCTS_RESET:
			return {
				...state,
				...initialState
			};
		case PRODUCTS_SET_PAGE:
			return {
				...state,
				page: action.page,
				hasSearched: false
			};
		case SET_PRODUCT_FILTER_PARAMETERS:
			let newParams = {...initialState.filterParameter};
			newParams[action.fieldName] = action.fieldValue;
			return {
				...state,
				filterParameter: newParams
			};
		case PRODUCTS_SET_PAGE_SIZE:
			return {
				...state,
				pageSize: action.pageSize
			};
		case SHOW_PRODUCT_DETAILS:
			const id = action.productId;
			if (state.productDetailsOpen.indexOf(id) < 0) {
				let newOpenState = [...state.productDetailsOpen];
				newOpenState.push(id);
				return {
					...state,
					productDetailsOpen: newOpenState
				};
			}
			return {
				...state
			};
		case HIDE_PRODUCT_DETAILS:
			if (state.productDetailsOpen.indexOf(action.productId) >= 0) {
				let newOpenState = state.productDetailsOpen.filter(productId => productId !== action.productId);
				return {
					...state,
					productDetailsOpen: newOpenState
				};
			}

			return {
				...state
			};
		default:
			return {...state};
	}
};

export default productSearch;
