import type {EventHandler, EventType, StockInformationFoundEvent} from "../index";
import store from "../../../configureStore";
import {STOCK_INFORMATION} from "../../action";

export const StockInformationFoundEventHandler: EventHandler = {
	handles(eventType: EventType): boolean {
		return eventType === "STOCK_INFORMATION_FOUND";
	},
	handle(event: StockInformationFoundEvent) {
		store.dispatch({
			type: STOCK_INFORMATION.FOUND,
			payload: event
		});
	}
};
