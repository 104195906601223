// @flow
import {combineReducers} from "redux";
import type {ArticlesState} from "./articlesReducer";
import {articlesReducer} from "./articlesReducer";
import type {ConversationsState} from "./conversationsReducer";
import {conversationsReducer} from "./conversationsReducer";

const susyRootReducer = combineReducers<_, Action>({
	articles: articlesReducer,
	conversations: conversationsReducer,
});

export type SusyState = {
	articles: ArticlesState,
	conversations: ConversationsState,
}

export default susyRootReducer;
