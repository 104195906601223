import {FETCH_CONSUMABLES} from "../actions/consumables";
import type {Consumable} from "../profile/reducer/profile";

export type ConsumableState = {
	isFetching: boolean,
	consumables: {
		[key: string]: Consumable[]
	}
}

const initialState : ConsumableState = {
	isFetching: false,
	consumables: {}
};

export const consumables = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_CONSUMABLES.REQUEST:
			return {
				...state,
				isFetching: true
			};
		case FETCH_CONSUMABLES.SUCCESS:
			return {
				...state,
				isFetching: false,
				consumables: action.payload
			};
		case FETCH_CONSUMABLES.FAILURE:
			return {
				...state,
				isFetching: false
			};
		default:
			return state;
	}
};
