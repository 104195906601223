// @flow
import * as React from "react";
import type {Video} from "../model/Video";

type Props = {
	videos: Video[],
	setCurrentVideoIndex: number => void,
	switchToQuestionnaire: void => void,
	renderQuestionnaireBox: boolean,
	questionnaireIsActive: boolean,
	currentVideoIndex: number
};
export const PlaylistBox = (props: Props) => {
	return <div>
		<section>
			<div className="box">
				<h2>Serieninhalte</h2>
				{props.videos.map((video, index) => {
					return props.currentVideoIndex === index
						? <div
							className={props.currentVideoIndex === index ? "active playlistItem" : "playlistItem"}
							key={video.id}
							onClick={() => props.setCurrentVideoIndex(index)}>
							<b>{index + 1}. {video.videoName}</b>
							<br/> {video.videoDescription}
						</div>
						: <div
							className={props.currentVideoIndex === index ? "active playlistItem" : "playlistItem"}
							key={video.id}
							onClick={() => props.setCurrentVideoIndex(index)}>
							{index + 1}. {video.videoName}
						</div>;
				})}
				{props.renderQuestionnaireBox &&
				<div
					className={props.questionnaireIsActive ? "active playlistItem" : "playlistItem"}
					onClick={() => props.switchToQuestionnaire()}
				>
					{props.videos.length + 1}. Fragebogen
				</div>}
			</div>
		</section>
	</div>;
};
