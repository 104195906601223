// @flow

import connect from "react-redux/es/connect/connect";
import type {State} from "../../reducers/rootReducer";
import type {MenuProps} from "../../components/layout/menu/Menu";
import {Questionnaire} from "../components/Questionnaire/Questionnaire";
import type {QuestionnaireSubmitData} from "../components/Questionnaire/Questionnaire";
import {handleQuestionnaireSubmit, notifyMissingQuestions} from "../actions/medialib";

function mapStateToProps(state: State, ownProps: any) {
	return {
		emailSendingIdle: state.medialib.emailSendingIdle,
		emailSendingError: state.medialib.emailSendingError,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		handleFormSubmit: (data: QuestionnaireSubmitData) => dispatch(handleQuestionnaireSubmit(data)),
		handleMissingQuestions: (count: number, questionIds: string) => dispatch(notifyMissingQuestions(count, questionIds)),
	};
}

export default connect<MenuProps, _, _, _, _, _>(mapStateToProps, mapDispatchToProps)(Questionnaire);
