import suggestionService from "../../service/suggestionService";

export const SUGGESTIONS_REQUEST = "SUGGESTIONS_REQUEST";
export const SUGGESTIONS_SUCCESS = "SUGGESTIONS_SUCCESS";
export const SUGGESTIONS_FAILURE = "SUGGESTIONS_FAILURE";
export const SUGGESTIONS_CLEAR = "SUGGESTIONS_CLEAR";
export const SUGGESTIONS_SEARCH_WORD = "SUGGESTIONS_SEARCH_WORD";
export const SUGGESTIONS_RESET_SEARCH_WORD = "SUGGESTIONS_RESET_SEARCH_WORD";
export const SUGGESTIONS_RESET_PARTNUMBER = "SUGGESTIONS_RESET_PARTNUMBER";
export const SUGGESTIONS_RESET_PARTCODE = "SUGGESTIONS_RESET_PARTCODE";
export const VEHICLE_SUGGESTIONS_SEARCH_WORD = "VEHICLE_SUGGESTIONS_SEARCH_WORD";
export const VEHICLE_SUGGESTIONS_RESET_SEARCH_WORD = "VEHICLE_SUGGESTIONS_RESET_SEARCH_WORD";
export const VEHICLE_SUGGESTIONS_SUCCESS = "VEHICLE_SUGGESTIONS_SUCCESS";
export const VEHICLE_SUGGESTIONS_CLEAR = "VEHICLE_SUGGESTIONS_CLEAR";

export function setSuggestionSearchPhrase(searchWord) {
	return {
		type: SUGGESTIONS_SEARCH_WORD,
		searchWord
	};
}

export function setVehicleSuggestionSearchPhrase(vehicleSearchWord) {
	return {
		type: VEHICLE_SUGGESTIONS_SEARCH_WORD,
		vehicleSearchWord
	};
}

export function resetSuggestionSearchPhrase() {
	return {
		type: SUGGESTIONS_RESET_SEARCH_WORD
	};
}

export function resetSuggestionPartNumber() {
	return {
		type: SUGGESTIONS_RESET_PARTNUMBER
	};
}
export function resetSuggestionPartCode() {
	return {
		type: SUGGESTIONS_RESET_PARTCODE
	};
}

export function resetVehicleSuggestionSearchPhrase() {
	return {
		type: VEHICLE_SUGGESTIONS_RESET_SEARCH_WORD
	};
}

export function getSuggestionsBySearchPhrase() {
	const thunk = (dispatch, getState) => {
		dispatch({
			type: SUGGESTIONS_REQUEST
		});
		const {searchWord} = getState().search.suggestions;
		suggestionService.getSuggestionsBySearchPhrase(searchWord)
						 .then(response => {
							 return dispatch({
								 type: SUGGESTIONS_SUCCESS,
								 suggestions: response.data
							 });
						 })
						 .catch(e => {
							 return dispatch({
								 type: SUGGESTIONS_FAILURE,
								 errorMessage: e.message
							 });
						 });
	};
	thunk.meta = {
		debounce: {
			time: 500,
			key: "TRACK_SEARCH_SUGGESTION"
		}
	};
	return thunk;
}

export function getSuggestionsByPartNumber() {
	const thunk = (dispatch, getState) => {
		dispatch({
			type: SUGGESTIONS_REQUEST
		});
		const {searchWord} = getState().search.suggestions;
		suggestionService.getSuggestionsByPartNumber(searchWord)
						 .then(response => {
							 return dispatch({
								 type: SUGGESTIONS_SUCCESS,
								 suggestions: response.data
							 });
						 })
						 .catch(e => {
							 return dispatch({
								 type: SUGGESTIONS_FAILURE,
								 errorMessage: e.message
							 });
						 });
	};
	thunk.meta = {
		debounce: {
			time: 500,
			key: "TRACK_SEARCH_SUGGESTION"
		}
	};
	return thunk;
}
export function getSuggestionsByPartCode() {
	const thunk = (dispatch, getState) => {
		dispatch({
			type: SUGGESTIONS_REQUEST
		});
		const {searchWord} = getState().search.suggestions;
		suggestionService.getSuggestionsByPartCode(searchWord)
						 .then(response => {
							 return dispatch({
								 type: SUGGESTIONS_SUCCESS,
								 suggestions: response.data
							 });
						 })
						 .catch(e => {
							 return dispatch({
								 type: SUGGESTIONS_FAILURE,
								 errorMessage: e.message
							 });
						 });
	};
	thunk.meta = {
		debounce: {
			time: 500,
			key: "TRACK_SEARCH_SUGGESTION"
		}
	};
	return thunk;
}

export function clearSuggestions() {
	return {
		type: SUGGESTIONS_CLEAR
	};
}
