import {
	ACCEPT_PROPOSAL,
	CONSUME_WATCHLIST,
	CREATE_PROPOSAL,
	DELETE_PROPOSAL,
	FETCH_PROPOSAL,
	FETCH_PROPOSALS,
	FETCH_SPARE_PART_DETAILS,
	FINISH_PROPOSAL,
	GET_INSPECTION_ELEMENTS,
	PROPOSALS_SET_PAGE,
	PROPOSALS_SET_PAGE_SIZE,
	REJECT_PROPOSAL,
	RESET_PROPOSAL_FILTER,
	SEARCH_PROPOSALS,
	SET_ADD_CONSUMABLE_ADDENDUM,
	SET_ADD_PRODUCT_ADDENDUM, SET_ADD_SPARE_TASK_ADDENDUM,
	SET_ADD_TASK_ADDENDUM,
	SET_CREATE_PROPOSAL_ADDENDUM,
	SET_DATE_SPAN,
	SET_EDIT_CUSTOMER_ADDENDUM,
	SET_IMPORT_MAINTENANCE_LIST_ADDENDUM,
	SET_PROPOSAL_CONFIRMATION_ADDENDUM,
	SET_PROPOSAL_FILTER,
	SET_PROPOSAL_PRINT_ADDENDUM,
	UPDATE_CURRENT_PROPOSAL,
	UPDATE_PROPOSAL
} from "../action";
import type {Vehicle} from "../../vehicle/model";
import type {Proposal, ProposalFilter, ProposalPagination} from "../model";
import {InspectionData} from "../model";

export type ProposalImport = {
	inspectionElements: InspectionData
}

export type ProposalDataState = {
	isFetchingProposal: boolean,
	isFetchingProposals: boolean,
	isSearchingProposals: boolean,
	isConsumingWatchList: boolean,
	vehicleDetails: Vehicle,
	proposals: Proposal[],
	current: Proposal,
	import: ProposalImport,
	filterParameter: ProposalFilter,
	pagination: ProposalPagination,
	dateRange: {
		from: Date,
		to: Date
	},
	dateSpan: number,
	workUnit: {},
	partCode: {},
	addendum: {
		isCreateProposalAddendumOpen: boolean,
		isImportMaintenanceListAddendumOpen: boolean,
		isAddConsumableAddendumOpen: boolean,
		isAddProductAddendumOpen: boolean,
		isAddTaskAddendumOpen: boolean,
		isAddSparePartTaskAddendumOpen: boolean,
		isEditCustomerAddendumOpen: boolean,
		isProposalConfirmationAddendumOpen: boolean,
		isProposalPrintAddendumOpen: boolean,
	}
}

const initialState = {
	vehicleDetails: null,
	isFetchingProposal: false,
	isSearchingProposals: false,
	isConsumingWatchList: false,
	proposals: [],
	import: {},
	current: null,
	filterParameter: {
		proposalName: null,
		customerName: null,
		startDate: null,
		endDate: null,
		vin: null,
		vehicleRegistrationNumber: null,
		state: null
	},
	pagination: {
		page: 0,
		size: 10,
		totalPages: 0,
		sort: [{
			createdAt: "desc"
		}]
	},
	dateRange: {
		from: new Date(),
		to: new Date()
	},
	dateSpan: 1,
	workUnit: {},
	partCode: {},
	addendum: {
		isCreateProposalAddendumOpen: false,
		isImportMaintenanceListAddendumOpen: false,
		isAddConsumableAddendumOpen: false,
		isAddProductAddendumOpen: false,
		isAddTaskAddendumOpen: false,
		isAddSparePartTaskAddendumOpen: false,
		isEditCustomerAddendumOpen: false,
		isProposalConfirmationAddendumOpen: false,
		isProposalPrintAddendumOpen: false,
	}
};

export const proposal = (state: ProposalDataState = initialState, action) => {
	switch (action.type) {
		case FETCH_PROPOSALS.REQUEST:
			return {
				...state,
				isFetchingProposals: true,
				current: null,
				vehicleDetails: null
			};
		case FETCH_PROPOSALS.SUCCESS:
			return {
				...state,
				proposals: action.payload,
				isFetchingProposals: false
			};
		case FETCH_PROPOSAL.SUCCESS:
			return {
				...state,
				current: action.payload.proposal,
				vehicleDetails: action.payload.vehicle
			};
		case SET_PROPOSAL_FILTER:
			return {
				...state,
				filterParameter: action.payload
			};
		case RESET_PROPOSAL_FILTER:
			return {
				...state,
				filterParameter: {...initialState.filterParameter},
				dateSpan: {...initialState.dateSpan}
			};
		case SET_DATE_SPAN:
			return {
				...state,
				dateSpan: action.payload
			};
		case PROPOSALS_SET_PAGE_SIZE:
			return {
				...state,
				pagination: {
					...state.pagination,
					size: action.pageSize
				}
			};
		case SEARCH_PROPOSALS.REQUEST:
			return {
				...state,
				isSearchingProposals: true,
				current: null,
				vehicleDetails: null
			};
		case SEARCH_PROPOSALS.SUCCESS:
			return {
				...state,
				proposals: action.payload.content,
				pagination: {
					...state.pagination,
					totalPages: action.payload.totalPages
				},
				isSearchingProposals: false
			};
		case SEARCH_PROPOSALS.FAILURE:
			return {
				...state,
				isSearchingProposals: false
			};
		case PROPOSALS_SET_PAGE:
			return {
				...state,
				pagination: {
					...state.pagination,
					page: action.page
				}
			};
		case FETCH_PROPOSALS.FAILURE:
			return {
				...state,
				isFetchingProposals: false
			};
		case CREATE_PROPOSAL.SUCCESS:
		case UPDATE_PROPOSAL.SUCCESS:
		case FINISH_PROPOSAL.SUCCESS:
		case UPDATE_CURRENT_PROPOSAL:
			return {
				...state,
				current: action.payload
			};
		case CONSUME_WATCHLIST.REQUEST:
			return {
				...state,
				isConsumingWatchList: true
			};
		case CONSUME_WATCHLIST.SUCCESS:
			return {
				...state,
				current: action.payload,
				isConsumingWatchList: false
			};
		case CONSUME_WATCHLIST.FAILURE:
			return {
				...state,
				isConsumingWatchList: false
			};
		case DELETE_PROPOSAL.SUCCESS:
			return {
				...state,
				proposals: state.proposals.filter(value => value.aggregateId !== action.payload)
			};
		case ACCEPT_PROPOSAL.SUCCESS:
			return {
				...state,
				current: action.payload,
				proposals: state.proposals.map(proposal => proposal.aggregateId !== action.payload.aggregateId ? proposal : {
					...proposal,
					state: "ACCEPTED"
				})
			};
		case REJECT_PROPOSAL.SUCCESS:
			return {
				...state,
				current: action.payload,
				proposals: state.proposals.map(proposal => proposal.aggregateId !== action.payload.aggregateId ? proposal : {
					...proposal,
					state: "REJECTED"
				})
			};
		case GET_INSPECTION_ELEMENTS.SUCCESS:
			return {
				...state,
				isFetchingProposal: false,
				import: {...state.import, inspectionElements: action.payLoad}
			};
		case GET_INSPECTION_ELEMENTS.FAILURE:
			return {
				...state,
				isFetchingProposal: false
			};
		case GET_INSPECTION_ELEMENTS.REQUEST:
			return {
				...state,
				isFetchingProposal: true
			};
		case FETCH_SPARE_PART_DETAILS.SUCCESS:
			return {
				...state,
				workUnit: {...state.workUnit, ...{[action.payload.partNo]: action.payload.defaultWorkUnits}},
				partCode: {
					...state.partCode,
					...{
						[action.payload.partNo]: {
							partCode: action.payload.partCode,
							partCodeSymbol: action.payload.partCodeSymbol
						}
					}
				},
			};
		case FETCH_SPARE_PART_DETAILS.FAILURE:
			return {
				...state
			};
		case FETCH_SPARE_PART_DETAILS.REQUEST:
			return {
				...state
			};
		case SET_CREATE_PROPOSAL_ADDENDUM:
			return {
				...state,
				addendum: {
					...state.addendum,
					isCreateProposalAddendumOpen: action.payload
				}
			};
		case SET_IMPORT_MAINTENANCE_LIST_ADDENDUM:
			return {
				...state,
				addendum: {
					...state.addendum,
					isImportMaintenanceListAddendumOpen: action.payload
				}
			};
		case SET_ADD_CONSUMABLE_ADDENDUM:
			return {
				...state,
				addendum: {
					...state.addendum,
					isAddConsumableAddendumOpen: action.payload
				}
			};
		case SET_ADD_PRODUCT_ADDENDUM:
			return {
				...state,
				addendum: {
					...state.addendum,
					isAddProductAddendumOpen: action.payload
				}
			};
		case SET_EDIT_CUSTOMER_ADDENDUM:
			return {
				...state,
				addendum: {
					...state.addendum,
					isEditCustomerAddendumOpen: action.payload
				}
			};
		case SET_PROPOSAL_CONFIRMATION_ADDENDUM:
			return {
				...state,
				addendum: {
					...state.addendum,
					isProposalConfirmationAddendumOpen: action.payload
				}
			};
		case SET_ADD_TASK_ADDENDUM:
			return {
				...state,
				addendum: {
					...state.addendum,
					isAddTaskAddendumOpen: action.payload
				}
			};
		case SET_ADD_SPARE_TASK_ADDENDUM:
			return {
				...state,
				addendum: {
					...state.addendum,
					isAddSparePartTaskAddendumOpen: action.payload
				}
			};
		case SET_PROPOSAL_PRINT_ADDENDUM:
			return {
				...state,
				addendum: {
					...state.addendum,
					isProposalPrintAddendumOpen: action.payload
				}
			};
		default:
			return state;
	}
};
