import {LOGIN_SUCCESS} from "../service/authService";
import CartService from "../cart/service/CartService";

export const cartInitializer = () => next => action => {
	switch (action.type) {
		case LOGIN_SUCCESS: {
			CartService.getCart()
				.catch(reason => CartService.createCart(null, "CART"));
			break;
		}
		default:
			break;
	}
	return next(action);
};

export default cartInitializer;
