/* eslint-disable react-hooks/exhaustive-deps */
// @flow
import React from "react";
import {SeriesThumbnail} from "./SeriesThumbnail";
import type {Channel} from "../model/Channel";
import {ContactBox} from "./ContactBox";
import {Trans} from "react-i18next";

type AcademyChannelPageProps = {
	currentChannel: Channel,
	onPageLoad: number => void
};

export const ChannelPage = (props: AcademyChannelPageProps) => {
	React.useEffect(() => {
		if (props.currentChannel) {
			props.onPageLoad(props.currentChannel.id);
		}
	}, []);
	if (!props.currentChannel) return null;

	return <div>
		<div>
			<div className={"channelLine"}>
				<div style={{backgroundImage: `url(${props.currentChannel.avatarUrl})`}}
					 className="avatar"/>
				<h2><Trans
					i18nKey={"mediaLib.channelPage.mainHeadline"}>{props.currentChannel.channelName}</Trans>
				</h2>
			</div>
			<section dangerouslySetInnerHTML={{__html: props.currentChannel.channelDescription}}/>
			{!!props.currentChannel.channelSeries.length &&
			<section>
				<h3><Trans
					i18nKey={"mediaLib.channelPage.subHeadline"}>{props.currentChannel.channelName}</Trans>
				</h3>
				<div className="row">
					{props.currentChannel.channelSeries.map(series => {
						return <SeriesThumbnail key={series.id}
							series={series}
							channel={props.currentChannel}
							channelId={props.currentChannel.id}/>;
					})}
					<div/>
					<div/>
				</div>
			</section>
			}
		</div>
		<ContactBox currentChannel={props.currentChannel}/>
	</div>;
};
