import queryString from "query-string";
import fetch from "isomorphic-fetch";
import Configuration from "../Configuration";
import i18n from "i18next";

function callApi(endpoint, authenticated, method, body, query, content, accessToken) {
	const config = {
		mode: "cors",
		headers: {
			"Accept": "application/json, text/plain, */*",
			"Content-Type": content || "application/json",
			"Accept-Language": i18n.language,
			"Authorization": `Bearer ${accessToken}`
		},
		method,
		body: body
	};

	let url = Configuration.value("apiBaseUrl") + endpoint;
	url += query ? `?${queryString.stringify(query)}` : "";

	return fetch(url, config)
		.then(response => {
			const responseCopy = response.clone();
			return responseCopy.json().catch(_ => response.text()).then(text => ({text, response}));
		}).then(({text, response}) => {
			if (!response.ok) {
				return Promise.reject(text);
			}

			return text;
		}).catch(err => {
			return Promise.reject(err);
		});
}

export const CALL_API = Symbol("Call API");

const apiMiddleware = store => next => action => {
	const callAPI = action[CALL_API];

	if (typeof callAPI === "undefined") {
		return next(action);
	}

	const {endpoint, types, authenticated, query, method, body, content, requestId} = callAPI;

	const [requestType, successType, errorType] = types;

	next({
		type: requestType,
		requestId
	});

	return callApi(endpoint, authenticated, method, body, query, content, store.getState().auth.accessToken).then(
		response =>
			next({
				response,
				authenticated,
				type: successType,
				requestId
			}),
		error => next({
			error: error.message || "There was an error.",
			type: errorType,
			requestId
		})
	);
};

export default apiMiddleware;
