import Configuration from "../Configuration";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";

class AuthService {
	loginSuccess = user => {
		return dispatch => {
			dispatch({
				type: LOGIN_SUCCESS,
				displayName: user.profile.display_name,
				phone: user.profile.phone,
				mail: user.profile.mail,
				accessToken: user.access_token,
				dealerGroup: user.profile.dealer_group,
				dealerId: user.profile.sub,
				mandator: user.profile.mandator
			});
		};
	};
}

const requiredScopes = "asp dms-read dms-write dealer-read dealer-write product-read order-read order-write vehicle-read news-read medialib-read proposal-read proposal-write promotion-read promotion-write reshipment-read reshipment-write user-read ticket-read ticket-write article-read article-write profile openid";

export const oidcConfig = {
	authority: Configuration.value("authServer"),
	client_id: "reach",
	client_secret: "asp",
	redirect_uri: window.location.origin,
	response_type: "code",
	scope: requiredScopes,
	popup_redirect_uri: window.location.origin,
	automaticSilentRenew: false,
	loadUserInfo: true
};


const authService = new AuthService();
export default authService;
