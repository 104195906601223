// @flow
import {
	FIGURE_GALLERY_SET_PAGE,
	FIGURES_FAILURE,
	FIGURES_REQUEST,
	FIGURES_SUCCESS,
	ILLUSTRATION_GROUPS_FAILURE,
	ILLUSTRATION_GROUPS_REQUEST,
	ILLUSTRATION_GROUPS_SUCCESS
} from "../actions/gta";
import type {FigureModel, IllustrationGroup} from "../models";
import type {Action} from "../../actions";

export type State = {
	selectedFigureGalleryIndex: number,
	illustrationsGroups: IllustrationGroup[],
	isRequestingIllustrationGroups: boolean,
	requestIllustrationGroupsError: string,
	loadingFigures: boolean,
	requestFiguresError: string,
	figures: Array<FigureModel>,
};

const initialState: State = {
	selectedFigureGalleryIndex: 0,
	illustrationsGroups: [],
	isRequestingIllustrationGroups: false,
	loadingFigures: false,
	figures: [],
	requestFiguresError: "",
	requestIllustrationGroupsError: "",
};

const gta = (state: State = initialState, action: Action) => {
	switch (action.type) {
		case ILLUSTRATION_GROUPS_REQUEST: {
			return {
				...state,
				isRequestingIllustrationGroups: true
			};
		}
		case ILLUSTRATION_GROUPS_SUCCESS: {
			return {
				...state,
				isRequestingIllustrationGroups: false,
				illustrationsGroups: action.illustrationGroups
			};
		}
		case ILLUSTRATION_GROUPS_FAILURE: {
			return {
				...state,
				requestIllustrationGroupsError: "Error occurred"
			};
		}
		case FIGURE_GALLERY_SET_PAGE: {
			return {
				...state,
				selectedFigureGalleryIndex: action.selectedFigureGalleryIndex
			};
		}
		case FIGURES_REQUEST: {
			return {
				...state,
				selectedFigureGalleryIndex: 0,
				loadingFigures: true
			};
		}
		case FIGURES_SUCCESS: {
			return {
				...state,
				loadingFigures: false,
				figures: action.figures
			};
		}
		case FIGURES_FAILURE: {
			return {
				...state,
				requestFiguresError: "Error occurred"
			};
		}
		default:
			return state;
	}
};

export default gta;
