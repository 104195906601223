export const ERROR_SET = "ERROR_SET";
export const ERROR_RESET = "ERROR_RESET";
export const ERROR_DIALOG_OPEN = "ERROR_DIALOG_SHOW";
export const ERROR_DIALOG_CLOSE = "ERROR_DIALOG_HIDE";

export function setErrorDescription(error: Error) {
	return {
		type: ERROR_SET,
		error,
	};
}
export function resetErrorDescription() {
	return {
		type: ERROR_RESET
	};
}
export function openReportDialog() {
	return {
		type: ERROR_DIALOG_OPEN
	};
}
export function closeReportDialog() {
	return {
		type: ERROR_DIALOG_CLOSE
	};
}
