// @flow
import {
	CALCULATE_OFFERS_FAILURE,
	CALCULATE_OFFERS_REQUEST,
	CALCULATE_OFFERS_SUCCESS, CREATE_ORDERS_FAILURE, CREATE_ORDERS_REQUEST, CREATE_ORDERS_SUCCESS
} from "../actions/offer";
import type {OfferBundle} from "../models";
import type {Action} from "../actions/offer";

const initialState = {
	isOptimizingCart: true,
	optimizingErrorMessage: "",
	offerBundle: null,
	hasCreatedOrders: false,
	isCreatingOrders: false
};

export type OfferState = {
	+isOptimizingCart: boolean,
	+optimizingErrorMessage: string,
	+offerBundle: ?OfferBundle,
	+hasCreatedOrders: boolean,
	+isCreatingOrders: boolean
};

const offer = (state: OfferState = initialState, action: Action): OfferState => {
	switch (action.type) {
		case CALCULATE_OFFERS_REQUEST:
			return {
				...state,
				isOptimizingCart: true,
				optimizingErrorMessage: ""
			};
		case CALCULATE_OFFERS_SUCCESS:
			return {
				...state,
				offerBundle: action.offerBundle,
				isOptimizingCart: false,
				optimizingErrorMessage: "",
				hasCreatedOrders: false
			};
		case CALCULATE_OFFERS_FAILURE:
			return {
				...state,
				isFetching: false,
				optimizingErrorMessage: action.errorMessage
			};
		case CREATE_ORDERS_REQUEST:
			return {
				...state,
				hasCreatedOrders: false,
				isCreatingOrders: true
			};
		case CREATE_ORDERS_SUCCESS:
			return {
				...state,
				hasCreatedOrders: true,
				isCreatingOrders: false
			};
		case CREATE_ORDERS_FAILURE:
			return {
				...state,
				hasCreatedOrders: false,
				isCreatingOrders: false
			};
		default:
			return {
				...state
			};
	}
};

export default offer;
