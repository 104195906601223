import {
	SUGGESTIONS_FAILURE,
	SUGGESTIONS_REQUEST,
	SUGGESTIONS_RESET_SEARCH_WORD,
	SUGGESTIONS_SEARCH_WORD,
	VEHICLE_SUGGESTIONS_SEARCH_WORD,
	VEHICLE_SUGGESTIONS_RESET_SEARCH_WORD,
	VEHICLE_SUGGESTIONS_SUCCESS,
	VEHICLE_SUGGESTIONS_CLEAR,
	SUGGESTIONS_CLEAR,
	SUGGESTIONS_SUCCESS
} from "../actions/suggestions";
import type {State} from "../../reducers/rootReducer";

const initialState: State = {
	searchWord: "",
	partNumber: "",
	vehicleSearchWord: "",
	suggestions: [],
	partNumberSuggestions: [],
	vehicleNameSuggestions: [],
	suggestionsLoading: false,
	suggestionsError: ""
};

const suggestions = (state = initialState, action) => {
	switch (action.type) {
		case SUGGESTIONS_SEARCH_WORD:
			return {
				...state,
				searchWord: action.searchWord
			};
		case VEHICLE_SUGGESTIONS_SEARCH_WORD:
			return {
				...state,
				vehicleSearchWord: action.vehicleSearchWord
			};
		case SUGGESTIONS_RESET_SEARCH_WORD:
			return {
				...state,
				searchWord: ""
			};
		case VEHICLE_SUGGESTIONS_RESET_SEARCH_WORD:
			return {
				...state,
				vehicleSearchWord: ""
			};
		case SUGGESTIONS_REQUEST:
			return {
				...state,
				suggestionsLoading: true,
				suggestionsError: ""
			};
		case SUGGESTIONS_SUCCESS:
			return {
				...state,
				suggestions: action.suggestions,
				suggestionsLoading: false,
				suggestionsError: ""
			};
		case SUGGESTIONS_CLEAR:
			return {
				...state,
				suggestions: [],
				suggestionsLoading: false,
				suggestionsError: ""
			};
		case VEHICLE_SUGGESTIONS_SUCCESS:
			return {
				...state,
				vehicleNameSuggestions: action.suggestions,
				suggestionsLoading: false,
				suggestionsError: ""
			};
		case VEHICLE_SUGGESTIONS_CLEAR:
			return {
				...state,
				vehicleNameSuggestions: [],
				suggestionsLoading: false
			};
		case SUGGESTIONS_FAILURE:
			return {
				...state,
				suggestions: [],
				suggestionsLoading: false,
				suggestionsError: action.errorMessage
			};
		default:
			return state;
	}
};

export default suggestions;
