// @flow
import {combineReducers} from "redux";
import type {AuthState} from "./auth";
import auth from "./auth";
import type {SearchState} from "../search/reducers";
import search from "../search/reducers";
import documentSearch from "../documentsearch/reducers";
import type {CartState} from "../cart/reducers/cart";
import cart from "../cart/reducers/cart";
import documents from "./documents";
import type {OfferState} from "../order/reducers/offer";
import offer from "../order/reducers/offer";
import type {OrderState} from "../order/reducers/order";
import order from "../order/reducers/order";
import {connectRouter} from "connected-react-router";
import type {OptionState} from "./option";
import option from "./option";
import type {NewsReducerState} from "../news/reducers/news";
import news from "../news/reducers/news";
import gta, {State as GtaState} from "../gta/reducers/gta";
import type {ErrorState} from "../error/reducers/error";
import error from "../error/reducers/error";
import type {VehicleState} from "../vehicle/reducer";
import vehicle from "../vehicle/reducer";
import type {MediaLibReducerState} from "../medialib/reducers/mediaLib";
import medialib from "../medialib/reducers/mediaLib";
import type {WatchListState} from "../watchlist/reducer/watchlist";
import watchList from "../watchlist/reducer/watchlist";
import type {ProposalState} from "../proposal/reducer/proposal";
import {proposal} from "../proposal/reducer/proposal";
import {profile, ProfileState} from "../profile/reducer/profile";
import type {ConsumableState} from "./consumables";
import {consumables} from "./consumables";
import {combineEpics} from "redux-observable";
import {proposalEpic} from "../middleware/proposalEpic";
import {catchError} from "rxjs";
import type {SusyState} from "../susy/reducer/susyRootReducer";
import susy from "../susy/reducer/susyRootReducer";
import type {PromotionReducerState} from "../promotion/reducer/promotion";
import promotion from "../promotion/reducer/promotion";
import {announcement} from "../announcement/reducer/announcement";
import type {AnnouncementState} from "../announcement/reducer/announcement";
import maintenanceGuideTime from "../maintenanceGuideTimes/reducer/maintenanceGuideTime";
import type {MaintenanceGuideTimeState} from "../maintenanceGuideTimes/reducer/maintenanceGuideTime";
import type {DocumentsState} from "./documents";
import reshipment from "../reshipment/reducer/reshipment";
import type {ReshipmentState} from "../reshipment/reducer/reshipment";
import type {WheelState} from "../wheelsearch/reducer/wheel";
import wheel from "../wheelsearch/reducer/wheel";

export const rootEpic = (action$, store$, dependencies) =>
	combineEpics(proposalEpic)(action$, store$, dependencies).pipe(
		catchError((error, source) => {
			console.error(error);
			return source;
		})
	);

export const rootReducer = history => combineReducers({
	router: connectRouter(history),
	auth,
	cart,
	consumables,
	documentSearch,
	documents,
	error,
	gta,
	medialib,
	news,
	offer,
	option,
	order,
	promotion,
	proposal,
	profile,
	reshipment,
	search,
	susy,
	vehicle,
	watchList,
	announcement,
	maintenanceGuideTime,
	wheel
});

export type State = {
	auth: AuthState,
	cart: CartState,
	consumables: ConsumableState,
	documents: DocumentsState,
	error: ErrorState,
	gta: GtaState,
	medialib: MediaLibReducerState,
	offer: OfferState,
	order: OrderState,
	search: SearchState,
	option: OptionState,
	news: NewsReducerState,
	promotion: PromotionReducerState,
	proposal: ProposalState,
	reshipment: ReshipmentState,
	susy: SusyState,
	vehicle: VehicleState,
	watchList: WatchListState,
	profile: ProfileState,
	announcement: AnnouncementState,
	maintenanceGuideTime: MaintenanceGuideTimeState,
	wheel: WheelState
}
