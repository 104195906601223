// @flow
import React from "react";
import "./LoadingMessage.scss";
import Spinner from "./Spinner";

type LoadingMessageProps = {
	message: string
}

const LoadingMessage = (props: LoadingMessageProps) => {
	return (
		<div className="loading">
			<Spinner/>
			<p>{props.message}</p>
		</div>
	);
};

export default LoadingMessage;
