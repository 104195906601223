// @flow

import {partSearchDocuments} from "../../documentsearch/actions/searchPart";
import {
	resetSuggestionPartCode,
	resetSuggestionPartNumber,
	resetSuggestionSearchPhrase,
	resetVehicleSuggestionSearchPhrase,
	VEHICLE_SUGGESTIONS_RESET_SEARCH_WORD
} from "../../search/actions/suggestions";
import {INSPECTION_RESET} from "../../search/actions/inspections";
import DocumentService from "../../documentsearch/api/documentService";
import type {Vehicle} from "../models";
import type {Dispatch, GetState} from "../../actions";
import {createUuid} from "../../api";
import type {PartSearchRequest} from "../productApi";
import productApi from "../productApi";
import {ThunkAction} from "redux-thunk";
import mailService from "../../service/mailService";
import type {PartCode} from "../../gta/models";
import type {VehicleState} from "../../vehicle/reducer";
import figureProductService from "../../gta/services/figureProductService";

export const PRODUCTS_REQUEST = "PRODUCTS_REQUEST";
export const PRODUCTS_SUCCESS = "PRODUCTS_SUCCESS";
export const PRODUCTS_FAILURE = "PRODUCTS_FAILURE";
export const FIGURE_PRODUCT_SEARCH_REQUEST = "FIGURE_PRODUCT_SEARCH_REQUEST";
export const FIGURE_PRODUCT_SEARCH_SUCCESS = "FIGURE_PRODUCT_SEARCH_SUCCESS";
export const FIGURE_PRODUCT_SEARCH_FAILURE = "FIGURE_PRODUCT_SEARCH_FAILURE";
export const SEND_MISSING_PARTCODE_NOTIFICATION_REQUEST = "SEND_MISSING_PARTCODE_NOTIFICATION_REQUEST";
export const SEND_MISSING_PARTCODE_NOTIFICATION_SUCCESS = "SEND_MISSING_PARTCODE_NOTIFICATION_SUCCESS";
export const SEND_MISSING_PARTCODE_NOTIFICATION_FAILURE = "SEND_MISSING_PARTCODE_NOTIFICATION_FAILURE";
export const SEND_MISSING_PART_NUMBER_NOTIFICATION_REQUEST = "SEND_MISSING_PART_NUMBER_NOTIFICATION_REQUEST";
export const SEND_MISSING_PART_NUMBER_NOTIFICATION_SUCCESS = "SEND_MISSING_PART_NUMBER_NOTIFICATION_SUCCESS";
export const SEND_MISSING_PART_NUMBER_NOTIFICATION_FAILURE = "SEND_MISSING_PART_NUMBER_NOTIFICATION_FAILURE";
export const DETAILS_REQUEST = "DETAILS_REQUEST";
export const DETAILS_SUCCESS = "DETAILS_SUCCESS";
export const DETAILS_FAILURE = "DETAILS_FAILURE";
export const SET_PRODUCTS_SEARCH_PHRASE = "SET_PRODUCTS_SEARCH_PHRASE";
export const SET_PRODUCTS_SEARCH_PARTNUMBER = "SET_PRODUCTS_SEARCH_PARTNUMBER";
export const SET_PRODUCTS_SEARCH_PARTCODE = "SET_PRODUCTS_SEARCH_PARTCODE";
export const PRODUCTS_RESET = "PRODUCTS_RESET";
export const PRODUCTS_SET_PAGE = "PRODUCTS_SET_PAGE";
export const PRODUCTS_SET_PAGE_SIZE = "PRODUCTS_SET_PAGE_SIZE";
export const SHOW_PRODUCT_DETAILS = "SHOW_PRODUCT_DETAILS";
export const HIDE_PRODUCT_DETAILS = "HIDE_PRODUCT_DETAILS";
export const FACETS_SET_FILTER = "FACETS_SET_FILTER";
export const FACETS_DELETE_FILTER = "FACETS_DELETE_FILTER";
export const FACETS_RESET_FILTERS = "FACETS_RESET_FILTERS";
export const SET_PRODUCT_FILTER_PARAMETERS = "SET_PRODUCT_FILTER_PARAMETERS";
export const DELETE_PRODUCT_FILTER_PARAMETERS = "DELETE_PRODUCT_FILTER_PARAMETERS";
export const SET_VIN_SEARCH_PHRASE = "SET_VIN_SEARCH_PHRASE";
export const RESET_VIN_SEARCH_PHRASE = "RESET_VIN_SEARCH_PHRASE";
export const RESET_PRODUCTS_SEARCH_PHRASE = "RESET_PRODUCTS_SEARCH_PHRASE";
export const SET_VEHICLESEARCH_TYPE = "SET_VEHICLESEARCH_TYPE";
export const SELECT_VKCODE = "SELECT_VKCODE";
export const RESET_PARTCODE_SEARCH = "RESET_PARTCODE_SEARCH";
export const FOUND_ALTERNATIVE_PART = "FOUND_ALTERNATIVE_PART";
export const RESET_ALTERNATIVE_PART = "RESET_ALTERNATIVE_PART";

export function setFuzzySearchPhrase(searchPhrase) {
	return dispatch => {
		dispatch({
			type: SET_PRODUCTS_SEARCH_PHRASE,
			searchPhrase
		});
	};
}

export function setPartNumberSearchPhrase(partNumber) {
	return dispatch => {
		dispatch({
			type: SET_PRODUCTS_SEARCH_PARTNUMBER,
			partNumber
		});
	};
}

export function setPartCodeSearchPhrase(partCode) {
	return dispatch => {
		dispatch({
			type: SET_PRODUCTS_SEARCH_PARTCODE,
			partCode
		});
	};
}

export function setVehicleSearchType(useFacets) {
	return dispatch => {
		dispatch({
			type: SET_VEHICLESEARCH_TYPE,
			useFacets
		});
	};
}

export function setVinSearchPhrase(searchPhrase) {
	return dispatch => {
		dispatch({
			type: SET_VIN_SEARCH_PHRASE,
			searchPhrase
		});
	};
}

export function resetVinSearchPhrase() {
	return dispatch => {
		dispatch({
			type: RESET_VIN_SEARCH_PHRASE
		});
	};
}

export function resetPartCodeSearch() {
	return dispatch => {
		dispatch({
			type: RESET_PARTCODE_SEARCH
		});
	};
}

export function resetProductsSearchPhrase() {
	return dispatch => {
		dispatch({
			type: RESET_PRODUCTS_SEARCH_PHRASE
		});
	};
}

export function searchProductsBySearchPhrase() {
	return (dispatch: Dispatch, getState: GetState) => {
		dispatch(resetAlternativePartFound());
		const productFilter = {};
		let vehicleFilter = getState().search.vehicleFilter;
		let productFilterParameter = getState().search.productSearch.filterParameter;

		productFilter.tags = vehicleFilter.searchPhrase.filterParameter.tags;
		productFilter.fuzzy = vehicleFilter.searchPhrase.fuzzy;
		productFilter.partCodes = vehicleFilter.partCode.partCode;
		productFilter.promotionIds = productFilterParameter.promotionIds;

		searchProducts(dispatch, getState, productFilter);
	};
}

export function searchByPartNumbers(partNumbers) {
	return async (dispatch: Dispatch, getState: GetState) => {
		dispatch(resetAlternativePartFound());
		const productFilter = {
			partNumbers,
		};

		const {page, pageSize} = getState().search.productSearch;
		const requestId = createUuid();
		dispatch({type: PRODUCTS_REQUEST, requestId});
		try {
			const products = await productApi.getByFilterParameters(productFilter, {}, page, pageSize);
			const productNumbers = products.content.map(p => p.partNo);
			dispatch(partSearchDocuments(productNumbers));
			dispatch(getProductDetails(true, productNumbers, requestId));

			return dispatch({type: PRODUCTS_SUCCESS, products, requestId});
		} catch (error) {
			dispatch({type: PRODUCTS_FAILURE, error});
		}
	};
}

export function searchProductByFigurePartIdentifierAndVehicle(partNumbers: string[], partCodes: PartCode[], figureNumber: string) {
	return async (dispatch: Dispatch, getState: GetState) => {
		const factoryCode = getState().vehicle.vehicle.factoryCode;
		const destinationCode = getState().vehicle.vehicle.destinationCode;
		const modelYearCode = getState().vehicle.vehicle.modelYearRestriction;
		const optionCodes = getState().vehicle.vehicle.optionCodes;
		const colorCode = getState().vehicle.vehicle.colorCode;
		const interiorColorCode = getState().vehicle.vehicle.interiorColorCode;
		const vehicleBuildDate = getState().vehicle.vehicle.productionDate;
		const vehicleDecommissionDate = getState().vehicle.vehicle.decommissionDate;
		const requestId = createUuid();
		let partNumberProductSearchResult = {
			products: [],
			productNotFoundPartNumbers: []
		};
		let partCodeProductSearchResult = {
			products: [],
			unmatchedPartCodes: [],
		};
		let productsFoundWithPartNumbers = [];
		let productsFoundWithPartCodes = [];
		dispatch({type: FIGURE_PRODUCT_SEARCH_REQUEST, requestId});

		try {
			if (partNumbers.length) {
				partNumberProductSearchResult = await figureProductService.getProductsForPartNumbers(partNumbers);
				productsFoundWithPartNumbers.push(...partNumberProductSearchResult.products);
			}
			if (partCodes.length) {
				partCodeProductSearchResult = await figureProductService.getProductsWithPartCodes(
					partCodes, factoryCode, destinationCode, modelYearCode, optionCodes, colorCode, interiorColorCode,
					figureNumber, vehicleBuildDate, vehicleDecommissionDate
				);
				productsFoundWithPartCodes.push(...partCodeProductSearchResult.products);
			}
			const products = productsFoundWithPartNumbers.concat(productsFoundWithPartCodes);

			if (products.length) {
				const productNumbers = products.map(p => p.partNo);
				dispatch(getProductDetails(true, productNumbers, requestId));
			}
			return dispatch({
				type: FIGURE_PRODUCT_SEARCH_SUCCESS,
				products: products,
				productNotFoundPartNumbers: partNumberProductSearchResult.productNotFoundPartNumbers,
				unmatchedPartCodes: partCodeProductSearchResult.unmatchedPartCodes,
				requestId
			});
		} catch (error) {
			dispatch({type: FIGURE_PRODUCT_SEARCH_FAILURE, error});
		}

	};
}

function createVehicleFilter(vehicleState: VehicleState, page: number, pageSize: number) {
	const filter: PartSearchRequest = {};
	let vkCode = null;
	if (vehicleState.vehicle) {
		vkCode = vehicleState.vehicle.vkCode;
		filter.factoryCode = vehicleState.vehicle.factoryCode;
		filter.destinationCode = vehicleState.vehicle.destinationCode;
		filter.modelYearCode = vehicleState.vehicle.modelYearRestriction;
		filter.optionCodes = vehicleState.vehicle.optionCodes;
		filter.colorCode = vehicleState.vehicle.colorCode;
		filter.interiorColorCode = vehicleState.vehicle.interiorColorCode;
		filter.vehicleBuildDate = vehicleState.vehicle.productionDate;
		filter.vehicleDecommissionDate = vehicleState.vehicle.decommissionDate;
	}
	if (vehicleState.search.vkCode) {
		vkCode = vehicleState.search.vkCode;
	}
	if (vkCode) {
		filter.vkModelYear = vkCode.modelYear;
		filter.vkType = vkCode.type;
		filter.vkSubType = vkCode.subType;
		filter.vkEngine = vkCode.engine;
		filter.vkGear = vkCode.gear;
		filter.vkConfiguration = vkCode.configuration;
		filter.vkVariant = vkCode.variant;
		filter.colorCode = vkCode.color;
	}
	filter.page = page;
	filter.pageSize = pageSize;
	return filter;
}

export function searchProductByPartNumberAndVehicle() {
	return async (dispatch: Dispatch, getState: GetState) => {
		dispatch(resetAlternativePartFound());
		const {page, pageSize} = getState().search.productSearch;
		const filter = createVehicleFilter(getState().vehicle, page, pageSize);
		const partNumber = getState().search.vehicleFilter.partNumber;
		filter.partNumber = partNumber;

		const requestId = createUuid();

		dispatch({type: PRODUCTS_REQUEST, requestId});
		try {
			const products = await productApi.getByPartNumberAndVehicle(filter);
			const hasAlternativePart = products.content.some(value => value.partNo !== partNumber);
			if (hasAlternativePart) {
				dispatch(alternativePartFound());
			}
			const productNumbers = products.content.map(p => p.partNo);
			dispatch(partSearchDocuments(productNumbers));

			if (products.content.length) {
				dispatch(getProductDetails(true, productNumbers, requestId));
			}
			return dispatch({type: PRODUCTS_SUCCESS, products, requestId});
		} catch (error) {
			dispatch({type: PRODUCTS_FAILURE, error});
		}
	};
}

export function searchProductsByPartCode() {
	return async (dispatch: Dispatch, getState: GetState) => {
		dispatch(resetAlternativePartFound());
		const requestId = createUuid();
		dispatch({type: PRODUCTS_REQUEST, requestId});
		try {
			let partCodes = [];
			partCodes.push(getState().search.vehicleFilter.partCode);
			const factoryCode = getState().vehicle.vehicle.factoryCode;
			const destinationCode = getState().vehicle.vehicle.destinationCode;
			const modelYearCode = getState().vehicle.vehicle.modelYearRestriction;
			const optionCodes = getState().vehicle.vehicle.optionCodes;
			const colorCode = getState().vehicle.vehicle.colorCode;
			const interiorColorCode = getState().vehicle.vehicle.interiorColorCode;
			const vehicleBuildDate = getState().vehicle.vehicle.productionDate;
			const vehicleDecommissionDate = getState().vehicle.vehicle.decommissionDate;
			const products = await productApi.getByPartCodeAndMostRelevantPrecisionDetails(partCodes, factoryCode, destinationCode, modelYearCode, optionCodes, colorCode, interiorColorCode, null, vehicleBuildDate, vehicleDecommissionDate);
			const partNumbers = products.content.map<string>(value => value.partNo);
			if (products.content.length) {
				dispatch(getProductDetails(true, partNumbers, requestId));
			}
			return dispatch({type: PRODUCTS_SUCCESS, products, requestId});
		} catch (error) {
			console.error(error);
			return dispatch({type: PRODUCTS_FAILURE, error});
		}
	};
}

async function searchProducts(dispatch: Dispatch, getState: GetState, productFilter) {
	let vehicleFilter = {};
	let vehicleState = getState().vehicle;
	let vkCode = null;
	if (vehicleState.vehicle) {
		vkCode = vehicleState.vehicle.vkCode;
	}
	if (vehicleState.search.vkCode) {
		vkCode = vehicleState.search.vkCode;
	}
	if (vkCode) {
		vehicleFilter.vkModelYear = vkCode.modelYear;
		vehicleFilter.vkType = vkCode.type;
		vehicleFilter.vkSubType = vkCode.subType;
		vehicleFilter.vkEngine = vkCode.engine;
		vehicleFilter.vkGear = vkCode.gear;
		vehicleFilter.vkConfiguration = vkCode.configuration;
		vehicleFilter.vkVariant = vkCode.variant;
		vehicleFilter.vkColor = vkCode.color;
	}

	const {page, pageSize} = getState().search.productSearch;
	const requestId = createUuid();
	dispatch({type: PRODUCTS_REQUEST, requestId});
	try {
		const products = await productApi.getByFilterParameters(productFilter, vehicleFilter, page, pageSize);
		const productNumbers = products.content.map(p => p.partNo);
		dispatch(partSearchDocuments(productNumbers));
		if (products.content.length) {
			dispatch(getProductDetails(true, productNumbers, requestId));
		}
		return dispatch({type: PRODUCTS_SUCCESS, products, requestId});
	} catch (error) {
		dispatch({type: PRODUCTS_FAILURE, error});
	}
}

export function sendNotificationAboutMissingPartCode(vin: string, partCode: string, figure: string): ThunkAction {
	return dispatch => {
		dispatch({type: SEND_MISSING_PARTCODE_NOTIFICATION_REQUEST});
		mailService.sendNotificationAboutMissingPartCode(partCode, vin, figure)
			.then(value =>
				dispatch({
					type: SEND_MISSING_PARTCODE_NOTIFICATION_SUCCESS,
					payload: {partCode, vin}
				})
			)
			.catch(error => {
				console.error(error);
				return dispatch({type: SEND_MISSING_PARTCODE_NOTIFICATION_FAILURE});
			});
	};
}

export function sendNotificationAboutMissingPartNumber(vin: string, partNumber: string, figure: string): ThunkAction {
	return dispatch => {
		dispatch({type: SEND_MISSING_PART_NUMBER_NOTIFICATION_REQUEST});
		mailService.sendNotificationAboutMissingPartNumber(partNumber, vin, figure)
			.then(value =>
				dispatch({
					type: SEND_MISSING_PART_NUMBER_NOTIFICATION_SUCCESS,
					payload: {partNumber, vin}
				})
			)
			.catch(error => {
				console.error(error);
				return dispatch({type: SEND_MISSING_PART_NUMBER_NOTIFICATION_FAILURE});
			});
	};
}

export function getProductDetails(withExternalStocks: boolean, partNumbers: string[], requestId: string): any {
	return async (dispatch: Dispatch, getState: GetState) => {
		dispatch({type: DETAILS_REQUEST, requestId});
		try {
			const details = await productApi.getProductDetails(withExternalStocks, partNumbers);
			return dispatch({type: DETAILS_SUCCESS, response: details, requestId});
		} catch (e) {
			console.error(e);
			return dispatch({type: DETAILS_FAILURE, requestId});
		}
	};
}

export function resetSearch() {
	return dispatch => {
		dispatch({
			type: PRODUCTS_RESET
		});
	};
}

export function resetProductSearch() {
	return dispatch => {
		dispatch(resetSearch());
		dispatch(resetAlternativePartFound());
		dispatch(resetSuggestionSearchPhrase());
		dispatch(resetSuggestionPartNumber());
		dispatch(resetSuggestionPartCode());
		dispatch(resetVehicleSuggestionSearchPhrase());
		dispatch(resetVinSearchPhrase());
		dispatch(setVehicleSearchType(false));
		dispatch(searchProductsBySearchPhrase());
		dispatch(findDocuments());
	};
}

export function loadNextPage(callback: () => void) {
	return (dispatch, getState) => {
		const {page, totalPages} = getState().search.productSearch;
		dispatch({
			type: PRODUCTS_SET_PAGE,
			page: (page + 1 < totalPages) ? page + 1 : page
		});
		dispatch(callback());
	};
}

export function loadPrevPage(callback: () => void) {
	return (dispatch, getState) => {
		const {page} = getState().search.productSearch;
		dispatch({
			type: PRODUCTS_SET_PAGE,
			page: (page > 0) ? page - 1 : page
		});
		dispatch(callback());
	};
}

export function setPage(page) {
	return dispatch => {
		dispatch({
			type: PRODUCTS_SET_PAGE,
			page
		});
	};
}

export function setPageSize(pageSize) {
	return dispatch => {
		dispatch({
			type: PRODUCTS_SET_PAGE_SIZE,
			pageSize: pageSize
		});
		dispatch(setPage(0));
		dispatch(searchProductsBySearchPhrase());
	};
}

export function setFilter(fieldName, fieldValue, checked) {
	return dispatch => {
		dispatch({
			type: checked ? DELETE_PRODUCT_FILTER_PARAMETERS : SET_PRODUCT_FILTER_PARAMETERS,
			fieldName: fieldName,
			fieldValue: fieldValue
		});
		dispatch(findDocuments());
	};
}

export function setVehicleFilter(fieldName, fieldValue, checked) {
	return dispatch => {
		dispatch({
			type: checked ? FACETS_DELETE_FILTER : FACETS_SET_FILTER,
			fieldName: fieldName,
			fieldValue: fieldValue
		});
		dispatch(findDocuments());
	};
}

export const findDocuments = () => {
	const mtcalcCategories = ["ACCESSORIES_BROCHURE"];
	const documentService = new DocumentService();

	return (dispatch, getState) => {
		const filterParameter = getState().search.vehicleFilter.searchPhrase.filterParameter;
		const vehicle: Vehicle = {
			vkCode: {
				modelYear: filterParameter.vkModelYear,
				type: filterParameter.vkType,
				subType: filterParameter.vkSubtype,
				engine: filterParameter.vkEngine,
				gear: filterParameter.vkGear,
				configuration: filterParameter.vkConfiguration,
				variant: filterParameter.vkVariant,
			}
		};

		const {modelYear, type, subType, engine, gear, configuration, variant} = vehicle.vkCode;
		if (modelYear && type && subType && engine && gear && configuration && variant) {
			const id = modelYear + type + subType + engine + gear + configuration + variant;
			documentService.getDocumentsByVkPattern(id, mtcalcCategories)
				.then(vehicleDocuments => {
					return dispatch({
						type: SELECT_VKCODE,
						vehicleDocuments
					});
				});
		}
	};
};

export function deleteFilter(fieldName, fieldValue) {
	return dispatch => {
		dispatch({
			type: FACETS_DELETE_FILTER,
			fieldName: fieldName,
			fieldValue: fieldValue
		});
		dispatch({
			type: VEHICLE_SUGGESTIONS_RESET_SEARCH_WORD
		});
		dispatch(findDocuments());
	};
}

export function resetFilters() {
	return dispatch => {
		dispatch({
			type: FACETS_RESET_FILTERS
		});
		dispatch({
			type: VEHICLE_SUGGESTIONS_RESET_SEARCH_WORD
		});
		dispatch({
			type: INSPECTION_RESET
		});
		dispatch(findDocuments());
	};
}

export function showProductDetails(productId) {
	return dispatch => {
		dispatch({
			type: SHOW_PRODUCT_DETAILS,
			productId: productId
		});
	};
}

export function hideProductDetails(productId) {
	return dispatch => {
		dispatch({
			type: HIDE_PRODUCT_DETAILS,
			productId: productId
		});
	};
}

export function alternativePartFound() {
	return dispatch => {
		dispatch({
			type: FOUND_ALTERNATIVE_PART
		});
	};
}

export function resetAlternativePartFound() {
	return dispatch => {
		dispatch({
			type: RESET_ALTERNATIVE_PART
		});
	};
}
