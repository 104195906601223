import type {State} from "./rootReducer";
import {SHOW_DOCUMENTS} from "../actions/document";
import {CATALOGS_FAILURE, CATALOGS_REQUEST, CATALOGS_SUCCESS} from "../gta/actions/catalogs";

export type DocumentsState = {
	partNumber: string,
	catalogs: Array<any>,
	loadingCatalogs: boolean
}

const initialState: DocumentsState = {
	partNumber: "",
	catalogs: [],
	loadingCatalogs: false,
};

const documents = (state: State = initialState, action): DocumentsState => {
	switch (action.type) {
		case SHOW_DOCUMENTS:
			return {
				...state,
				partNumber: action.partNumber
			};
		case CATALOGS_REQUEST: {
			return {
				...state,
				loadingCatalogs: true,
				catalogs: []
			};
		}
		case CATALOGS_SUCCESS: {
			return {
				...state,
				loadingCatalogs: false,
				catalogs: action.catalogs
			};
		}
		case CATALOGS_FAILURE:
			return {
				...state,
				loadingCatalogs: false,
				catalogs: []
			};
		default:
			return state;
	}
};

export default documents;
