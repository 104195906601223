// @flow
import {Link} from "react-router-dom";
import React from "react";
import DescriptionShortener from "../helper/DescriptionShortener";
import type {Series} from "../model/Series";
import RouteDictionary from "../../RouteDictionary";

type CourseProps = {
	series: Series,
	channelId: number
};

export const SeriesThumbnail = (props: CourseProps) => {
	return <Link to={`${RouteDictionary.getRoutes().MEDIA_LIB}/channel/${props.channelId}/course/${props.series.id}`}
				 className="block">
		<img src={props.series.thumbnailUrl} alt={""}/>
		<span>
			<h3>{props.series.seriesName}</h3>
			<span>{DescriptionShortener.shortenText(props.series.seriesDescription)}</span>
		</span>
	</Link>;
};
