import type {EcommerceItem} from "./EcommerceItemFactory";
import ecommerceItemFactory from "./EcommerceItemFactory";
import type {Order} from "../order/models";

export type PurchaseEventProperties = {
	transaction_id: string,
	value: number,
	tax: number,
	shipping: number,
	currency: "EUR",
	coupon: string,
	items: Array<EcommerceItem>
}

type OrderCustomPropierties = {
	orderType: string,
	localizedOrderType: string,
	salesPromotionName: string,
	promotionId: string,
};

const EcommerceEventType = {
	VIEW_ITEM_LIST: "view_item_list",
	SELECT_ITEM: "select_item",
	VIEW_ITEM: "view_item",
	ADD_TO_CART: "add_to_cart",
	VIEW_CART: "view_cart",
	REMOVE_FROM_CART: "remove_from_cart",
	ADD_TO_WISHLIST: "add_to_wishlist",
	BEGIN_CHECKOUT: "begin_checkout",
	ADD_SHIPPING_INFO: "add_shipping_info",
	ADD_PAYMENT_INFO: "add_payment_info",
	PURCHASE: "purchase",
	REFUND: "refund",
	VIEW_PROMOTION: "view_promotion",
	SELECT_PROMOTION: "select_promotion",
};

class EcommerceEventEmitter {
	createPurchaseEvent = (ga4Instance, order: Order) => {
		const eventName = EcommerceEventType.PURCHASE;
		const orderCustomProperties: OrderCustomPropierties = {
			orderType: order.orderType,
			localizedOrderType: order.localizedOrderType,
			salesPromotionName: order.salesPromotionName,
			promotionId: order.promotionId,
		};

		const eventProperties: PurchaseEventProperties = {
			transaction_id: order.orderId,
			value: order.price,
			currency: order.currency,
			items: ecommerceItemFactory.fromLocalizedOrderItems(order.localizedOrderItems),
			...orderCustomProperties,
		};
		ga4Instance.event(eventName, eventProperties);
	};
}

const ecommerceEventEmitterInstance = new EcommerceEventEmitter();

export default ecommerceEventEmitterInstance;
