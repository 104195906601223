// @flow
import store from "../../configureStore";
import Configuration from "../../Configuration";
import {updateCart} from "../actions";
import {EventSourcePolyfill} from "event-source-polyfill";
import {updateWatchLists} from "../../watchlist/actions";
import type {WatchList} from "../../watchlist/models";

class CartUpdater {
	eventSource: any;
	eventSourceUrl: string;

	constructor() {
		this.eventSourceUrl = `${Configuration.value("apiBaseUrl")}/cart/my-sse`;
	}

	createEventSource(accessToken: string) {
		return new EventSourcePolyfill(this.eventSourceUrl, {
			headers: {
				Authorization: `Bearer ${accessToken}`
			},
			heartbeatTimeout: 300000
		});
	}

	refreshEventSource(accessToken: string) {
		if (this.eventSource) {
			this.close(accessToken);
		}
		this.eventSource = this.createEventSource(accessToken);
		this.open(accessToken);
	}

	open(accessToken: string) {
		this.eventSource.onmessage = evt => {
			const carts: WatchList[] = JSON.parse(evt.data);
			const cart = carts.find(watchList => watchList.type === "CART");
			const watchLists = carts.filter(watchList => watchList.type === "WATCHLIST");
			if (cart) {
				store.dispatch(updateCart(cart.cartItems));
			}
			store.dispatch(updateWatchLists(watchLists));
		};
		this.eventSource.onerror = e => {
			if (e.error && e.error.message.includes("No activity within")) return;
			console.error(e);
			fetch(this.eventSourceUrl, {
				method: "delete",
				headers: new Headers({
					Authorization: `Bearer ${accessToken}`
				})
			}).catch(error => console.error(error));
		};
		this.eventSource.oncomplete = e => {
			console.error(e);
			fetch(this.eventSourceUrl, {
				method: "delete",
				headers: new Headers({
					Authorization: `Bearer ${accessToken}`
				})
			}).catch(error => console.error(error));
		};
	}

	close(accessToken: string) {
		this.eventSource.close();
		fetch(this.eventSourceUrl, {
			method: "delete",
			headers: new Headers({
				Authorization: `Bearer ${accessToken}`
			})
		}).catch(error => console.error(error));
	}
}

const CartUpdaterInstance = new CartUpdater();
export default CartUpdaterInstance;
