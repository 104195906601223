import type Axios from "axios";
import qs from "query-string";
import {axiosInstance} from "../api";

class ConsumableService {
	api: Axios;

	constructor(api: Axios) {
		this.api = api;
	}

	async getConsumables() {
		const consumables = await this.api.get("/inspection/consumable/grouped",
			{
				params: {
					page: 0,
					size: 1000
				},
				paramsSerializer: params => {
					return qs.stringify(params);
				}
			});
		return consumables.data;
	}
}

const ConsumableServiceInstance = new ConsumableService(axiosInstance);
export default ConsumableServiceInstance;
