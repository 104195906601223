// @flow
import type {Vehicle, VkCode} from "../model";
import type {VkFacetResponse} from "../service/vehicleClient";
import vehicleClient from "../service/vehicleClient";
import type {Dispatch, GetState} from "../../actions";
import {getPromotionsForVehicle} from "../../promotion/action";
import type {FacetModel} from "../../components/filter/model";

export const SET_VEHICLE_CODE = "SET_VEHICLE_CODE";
export const RESET_VEHICLE_CODE = "RESET_VEHICLE_CODE";
export const SET_VIN_SEARCH_PHRASE = "SET_VIN_SEARCH_PHRASE";

export const SEARCH_VEHICLE_REQUEST = "SEARCH_VEHICLE_REQUEST";
export const SEARCH_VEHICLE_SUCCESS = "SEARCH_VEHICLE_SUCCESS";
export const SEARCH_VEHICLE_FAILURE = "SEARCH_VEHICLE_FAILURE";
export const SEARCH_VEHICLE_FROM_HISTORY_REQUEST = "SEARCH_VEHICLE_FROM_HISTORY_REQUEST";
export const SEARCH_VEHICLE_FROM_HISTORY_SUCCESS = "SEARCH_VEHICLE_FROM_HISTORY_SUCCESS";
export const SEARCH_VEHICLE_FROM_HISTORY_FAILURE = "SEARCH_VEHICLE_FROM_HISTORY_FAILURE";

export const SHOW_VEHICLE_HISTORY = "SHOW_VEHICLE_HISTORY";
export const HIDE_VEHICLE_HISTORY = "HIDE_VEHICLE_HISTORY";

export const VK_FACET_SET = "VK_FACET_SET";
export const VK_FACET_REQUEST = "VK_FACET_REQUEST";
export const VK_FACET_SUCCESS = "VK_FACET_SUCCESS";
export const VK_FACET_FAILURE = "VK_FACET_FAILURE";

export const SET_VEHICLE = "SET_VEHICLE";
export const RESTORE_VEHICLE = "RESTORE_VEHICLE";

export const GET_VEHICLE_RECALLS_REQUEST = "GET_VEHICLE_RECALLS_REQUEST";
export const GET_VEHICLE_RECALLS_SUCCESS = "GET_VEHICLE_RECALLS_SUCCESS";
export const GET_VEHICLE_RECALLS_FAILURE = "GET_VEHICLE_RECALLS_FAILURE";

export const CLEAR_VEHICLES = "CLEAR_VEHICLES";
export const RESET_VEHICLE = "RESET_VEHICLE";

export const SET_VEHICLE_MENU = "SET_VEHICLE_MENU";

export type SetVehicleCodeAction = {
	type: typeof SET_VEHICLE_CODE,
	vkCode: VkCode
};

export type ResetVehicleCodeAction = {
	type: typeof RESET_VEHICLE_CODE
};

export type SetVinSearchPhraseAction = {
	type: typeof SET_VIN_SEARCH_PHRASE,
	vinPhrase: string
};

export type SearchVehicleRequest = {
	type: typeof SEARCH_VEHICLE_REQUEST
};

export type SearchVehicleSuccess = {
	type: typeof SEARCH_VEHICLE_SUCCESS,
	vehicles: Vehicle[]
};

export type SearchVehicleFailure = {
	type: typeof SEARCH_VEHICLE_FAILURE,
	error: string
};

export type VkFacetRequest = {
	type: typeof VK_FACET_REQUEST
};

export type VkFacetSuccess = {
	type: typeof VK_FACET_SUCCESS,
	facets: VkFacetResponse[]
};

export type VkFacetFailure = {
	type: typeof VK_FACET_FAILURE,
	error: string
};

export type SetVehicleAction = {
	type: typeof SET_VEHICLE,
	vehicle: Vehicle
};


export type RestoreVehicleAction = {
	type: typeof RESTORE_VEHICLE,
	vehicle: Vehicle
};


export type ClearVehiclesAction = {
	type: typeof CLEAR_VEHICLES
};

export type ResetVehicleAction = {
	type: typeof RESET_VEHICLE
};

export type ShowVehicleHistoryAction = {
	type: typeof SHOW_VEHICLE_HISTORY
};

export type HideVehicleHistoryAction = {
	type: typeof HIDE_VEHICLE_HISTORY
};

export type VehicleAction =
	| SearchVehicleRequest
	| SearchVehicleSuccess
	| SearchVehicleFailure
	| VkFacetRequest
	| VkFacetSuccess
	| VkFacetFailure
	| SetVinSearchPhraseAction
	| SetVehicleCodeAction
	| ResetVehicleCodeAction
	| SetVehicleAction
	| RestoreVehicleAction
	| ClearVehiclesAction
	| ResetVehicleAction;

export const setVehicleMenu = (payload: boolean) => {
	return {
		type: SET_VEHICLE_MENU,
		payload
	};
};

export const setVehicleCode = (vkCode: VkCode): SetVehicleCodeAction => {
	return {
		type: SET_VEHICLE_CODE,
		vkCode
	};
};

export const resetVehicleCode = (): ResetVehicleCodeAction => {
	return {
		type: RESET_VEHICLE_CODE
	};
};

const searchVehicleRequest = (): SearchVehicleRequest => {
	return {
		type: SEARCH_VEHICLE_REQUEST
	};
};

const searchVehicleSuccess = (vehicles: Vehicle[]): SearchVehicleSuccess => {
	return {
		type: SEARCH_VEHICLE_SUCCESS,
		vehicles
	};
};

const searchVehicleFailure = (error: string): SearchVehicleFailure => {
	return {
		type: SEARCH_VEHICLE_FAILURE,
		error
	};
};

const searchVehicleHistoryRequest = (): SearchVehicleRequest => {
	return {
		type: SEARCH_VEHICLE_FROM_HISTORY_REQUEST
	};
};

const searchVehicleHistorySuccess = (vehicles: Vehicle[]): SearchVehicleSuccess => {
	return {
		type: SEARCH_VEHICLE_FROM_HISTORY_SUCCESS,
		vehicles
	};
};

const searchVehicleHistoryFailure = (error: string): SearchVehicleFailure => {
	return {
		type: SEARCH_VEHICLE_FROM_HISTORY_FAILURE,
		error
	};
};

export const searchVehiclesByVinPhrase = () => {
	return async (dispatch: Dispatch, getState: GetState) => {
		let vinPhrase = getState().vehicle.search.vinPhrase;
		dispatch(searchVehicleRequest());
		try {
			let vehicles = await vehicleClient.getVehiclesByVinPhrase(vinPhrase);
			return dispatch(searchVehicleSuccess(vehicles));
		} catch (e) {
			return dispatch(searchVehicleFailure(e.message));
		}
	};
};

export const searchVehiclesByVinList = (vins: string[]) => {
	return async dispatch => {
		dispatch({type: "SEARCH_VEHICLE_FROM_HISTORY_REQUEST"});
		try {
			let vehicles = await vehicleClient.getVehiclesFromDealerVehicleHistoryByVin(vins);
			return dispatch({type: "SEARCH_VEHICLE_FROM_HISTORY_SUCCESS", vehicles});
		} catch (e) {
			return dispatch({type: "SEARCH_VEHICLE_FROM_HISTORY_FAILURE"});
		}
	};
};

export const searchVehiclesByDealerVehicleHistory = () => {
	return async (dispatch: Dispatch, getState: GetState) => {
		const dealerVehicles = getState().profile.dealerVehicles;
		dispatch(searchVehicleHistoryRequest());
		try {
			let vehicles = await vehicleClient.getVehiclesFromDealerVehicleHistory(dealerVehicles);
			return dispatch(searchVehicleHistorySuccess(vehicles));
		} catch (e) {
			return dispatch(searchVehicleHistoryFailure(e.message));
		}
	};
};

const getVkFacetsRequest = (): VkFacetRequest => {
	return {
		type: VK_FACET_REQUEST
	};
};

const getVkFacetsSuccess = (facets: FacetModel[]): VkFacetSuccess => {
	return {
		type: VK_FACET_SUCCESS,
		facets
	};
};

const getVkFacetsFailure = (error: string): VkFacetFailure => {
	return {
		type: VK_FACET_FAILURE,
		error
	};
};

export const searchVkFacetsByVkCode = () => {
	return async (dispatch: Dispatch, getState: GetState) => {
		let vkCode = getState().vehicle.search.vkCode || {};
		dispatch(getVkFacetsRequest());
		try {
			let facets = await vehicleClient.getVkCodeFacets(vkCode);
			dispatch(getVkFacetsSuccess(facets));
		} catch (e) {
			dispatch(getVkFacetsFailure(e.message));
		}
	};
};

export const restoreVehicle = (vehicle: Vehicle): SetVehicleAction => {
	return dispatch => {
		dispatch({
			type: RESTORE_VEHICLE,
			vehicle
		});
		dispatch(getVehicleRecalls(vehicle.vin));
		dispatch(getPromotionsForVehicle());
	};
};

export const setVehicle = (vehicle: Vehicle): SetVehicleAction => {
	return dispatch => {
		dispatch({
			type: SET_VEHICLE,
			vehicle
		});
		dispatch(getVehicleRecalls(vehicle.vin));
		dispatch(getPromotionsForVehicle());
	};
};

export const getVehicleRecalls = vin => {
	return dispatch => {
		dispatch({
			type: GET_VEHICLE_RECALLS_REQUEST,
		});
		vehicleClient.getRecalls(vin)
			.then(res => {
				dispatch({
					type: GET_VEHICLE_RECALLS_SUCCESS,
					payload: res
				});
			})
			.catch(err => {
				console.error(err);
				dispatch({
					type: GET_VEHICLE_RECALLS_FAILURE
				});
			});
	};
};

export const setVinSearchPhrase = (vinPhrase: string): SetVinSearchPhraseAction => {
	return {
		type: SET_VIN_SEARCH_PHRASE,
		vinPhrase: vinPhrase.replace(" ", "")
	};
};

export const clearVehicles = () => {
	return {
		type: CLEAR_VEHICLES
	};
};

export const resetVehicle = () => {
	return {
		type: RESET_VEHICLE
	};
};

export const showVehicleHistory = (): ShowVehicleHistoryAction => {
	return {
		type: SHOW_VEHICLE_HISTORY
	};
};

export const hideVehicleHistory = (): HideVehicleHistoryAction => {
	return {
		type: HIDE_VEHICLE_HISTORY,
	};
};
