// @flow
import type {Axios} from "axios";
import store from "../configureStore";
import qs from "query-string";
import {ITEM_ADD_WARNING, removeItem} from "../cart/actions";
import {axiosProductInstance} from "../api";
import type {LocalStock} from "../cart/models";

type Discount = {
	label: string,
	value: number
}

export type Currency = {
	currencySymbol: string,
	currencyCode: string
}

export type LabeledPrice = {
	currency: Currency,
	netLabel: string,
	grossLabel: string,
	value: number,
	grossValue: number
}

type StorageInformation = {
	stock: string,
	stockAvailability: string,
	expressDelivery: string,
	stockDelivery: string
}

export type Price = {
	label: string,
	currency: Currency,
	value: number
}

export type SalesPromotion = {
	id: number,
	name: string,
	specialPrice: Price
}

export type ProductStorage = {
	partNumber: string,
	deliveryCost: LabeledPrice,
	retailPrice: LabeledPrice,
	discontinued: boolean,
	canExpressOrder: boolean,
	limitedExpressOrderPrice: LabeledPrice,
	limitedExpressOrderDiscount: Discount,
	unlimitedExpressOrderPrice: LabeledPrice,
	unlimitedExpressOrderDiscount: Discount,
	stockOrderPrice: LabeledPrice,
	stockOrderDiscount: Discount,
	storedAmount: number,
	hasExpressDeliveryLimit: boolean,
	storageInformation: StorageInformation,
	salesPromotionIds: Array<number>,
	localStock: ?LocalStock
}

export type Amount = {
	value: number,
	amountUnit: string
}

export type ScalePrice = {
	amount: Amount,
	price: LabeledPrice,
	partNumber: string
}

export type PromotionSpecialPrice = {
	promotionId: number,
	price: Price
}

export type ProductDetail = {
	partNumber: string,
	storage: ProductStorage,
	salesPromotions: Array<SalesPromotion>,
	scalePrices: ScalePrice[],
	specialPrices: PromotionSpecialPrice[]
}

type WorkUnits = {
	value: number
}

export type Product = {
	partNo: string,
	name: string,
	imageUris: Array<string>,
	tags: Array<string>,
	description: string,
	storageDetails: Array<ProductDetail>,
	workUnits: Array<WorkUnits>,
	defaultWorkUnits: number,
	partCode: string;
	partCodeSymbol: string;
}

export type DetailedProduct = {
	partNumber: string,
	product: Product,
	detail: ?ProductDetail
}

class ProductFinder {
	productClient: Axios;

	constructor() {
		this.productClient = axiosProductInstance;
	}

	getProducts(partNumbers: Array<string>, removeMissingProducts: boolean): Promise<Array<Product>> {
		return Promise.all(partNumbers.map(partNumber => this.getProduct(partNumber, removeMissingProducts))).then(products => products.filter(product => product));
	};

	getProduct(partNumber: string, removeMissingProducts: boolean): Promise<Product> {
		return this.productClient.get(`/v1/product/${partNumber}`, {})
			.then(response => response.data)
			.catch(e => {
				if (e.response.status === 404 && removeMissingProducts) {
					store.dispatch({type: ITEM_ADD_WARNING, partNumber});
					store.dispatch(removeItem(partNumber));
				} else {
					console.error(e);
				}
			});
	};

	getProductDetails(withExternalStocks: boolean, partNumbers: Array<string>): Promise<Array<ProductDetail>> {
		return this.productClient
			.get(
				"/v1/product/details",
				{
					params: {
						withExternalStocks: withExternalStocks,
						partNumber: partNumbers
					},
					paramsSerializer: params => {
						return qs.stringify(params);
					}
				}
			)
			.then(response => response.data);
	};

	async getDetailedProducts(
		partNumbers: Array<string>,
		removeMissingProducts: boolean,
		withDetails: boolean = true,
		withExternalStocks: boolean
	): Promise<Array<DetailedProduct>> {
		const products = await this.getProducts(partNumbers, removeMissingProducts);
		let details = [];
		if (withDetails && products.length) {
			details = await this.getProductDetails(withExternalStocks, products.map(product => product.partNo));
		}

		return products.map(product => {
			const detail = details.find(detail => detail.partNumber === product.partNo);
			return {
				partNumber: product.partNo,
				product,
				detail
			};
		});
	};
}

const ProductFinderInstance = new ProductFinder();
export default ProductFinderInstance;
