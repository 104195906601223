// @flow
import type {BaseCartItem, Cart, CartItemModel, EnhancedCartItemModel} from "../models";
import {enhanceCartItems} from "../service/cartEnhancer";
import {Trans} from "react-i18next";
import React from "react";
import cartService from "../service/CartService";
import type {State} from "../../reducers/rootReducer";
import type {DmsErrorAction, StockInformationRequestAction, StockInformationSuccessAction} from "../../dms/action";

export const ITEM_REMOVE_REQUEST = "ITEM_REMOVE_REQUEST";
export const ITEM_REMOVE_SUCCESS = "ITEM_REMOVE_SUCCESS";
export const ITEM_REMOVE_FAILURE = "ITEM_REMOVE_FAILURE";
export const CLEAR_REQUEST = "CLEAR_REQUEST";
export const CLEAR_SUCCESS = "CLEAR_SUCCESS";
export const CLEAR_FAILURE = "CLEAR_FAILURE";
export const ITEM_ENHANCE_REQUEST = "ITEM_ENHANCE_REQUEST";
export const ITEM_ENHANCE_SUCCESS = "ITEM_ENHANCE_SUCCESS";
export const ITEM_EDIT_REQUEST = "ITEM_EDIT_REQUEST";
export const ITEM_EDIT_SUCCESS = "ITEM_EDIT_SUCCESS";
export const ITEM_EDIT_FAILURE = "ITEM_EDIT_FAILURE";
export const ITEM_ADD_REQUEST = "ITEM_ADD_REQUEST";
export const ITEM_ADD_SUCCESS = "ITEM_ADD_SUCCESS";
export const ITEM_ADD_FAILURE = "ITEM_ADD_FAILURE";
export const ITEMS_ADD_REQUEST = "ITEMS_ADD_REQUEST";
export const ITEMS_ADD_SUCCESS = "ITEMS_ADD_SUCCESS";
export const ITEMS_ADD_FAILURE = "ITEMS_ADD_FAILURE";
export const ITEM_ADD_WARNING = "ITEM_ADD_WARNING";
export const CART_REQUEST = "CART_REQUEST";
export const CART_SUCCESS = "CART_SUCCESS";
export const CART_FAILURE = "CART_FAILURE";
export const DMS_CART_IMPORT_REQUEST = "DMS_CART_IMPORT_REQUEST";
export const DMS_CART_IMPORT_FAILURE = "DMS_CART_IMPORT_FAILURE";
export const EPC_CART_IMPORT_REQUEST = "EPC_CART_IMPORT_REQUEST";
export const EPC_CART_IMPORT_FAILURE = "EPC_CART_IMPORT_FAILURE";
export const PRINT_WATCHLIST_FAILURE = "PRINT_WATCHLIST_FAILURE";

type ItemRemoveRequestAction = { type: typeof ITEM_REMOVE_REQUEST, partNumber: string }
type ItemRemoveFailureAction = { type: typeof ITEM_REMOVE_FAILURE, error: string }
type ItemRemoveSuccessAction = { type: typeof ITEM_REMOVE_SUCCESS }

type ClearRequestAction = { type: typeof CLEAR_REQUEST }
type ClearFailureAction = { type: typeof CLEAR_FAILURE, error: string }
type ClearSuccessAction = { type: typeof CLEAR_SUCCESS }

type ItemEnhanceRequestAction = { type: typeof ITEM_ENHANCE_REQUEST }
type ItemEnhanceSuccessAction = { type: typeof ITEM_ENHANCE_SUCCESS, enhancedItems: Array<EnhancedCartItemModel> }

type ItemEditRequestAction = {
	type: typeof ITEM_EDIT_REQUEST,
	partNumber: string,
	note: string,
	amount: number,
	isExpressOrder: boolean
}
type ItemEditFailureAction = { type: typeof ITEM_EDIT_FAILURE, error: string }
type ItemEditSuccessAction = { type: typeof ITEM_EDIT_SUCCESS }

type ItemAddRequestAction = { type: typeof ITEM_ADD_REQUEST, partNumber: string }
type ItemAddFailureAction = { type: typeof ITEM_ADD_FAILURE }
type ItemAddSuccessAction = { type: typeof ITEM_ADD_SUCCESS }

type ItemsAddRequestAction = { type: typeof ITEMS_ADD_REQUEST }
type ItemsAddFailureAction = { type: typeof ITEMS_ADD_FAILURE }
type ItemsAddSuccessAction = { type: typeof ITEMS_ADD_SUCCESS, amount: number }

type CartRequestAction = { type: typeof CART_REQUEST }
type CartFailureAction = { type: typeof CART_FAILURE, error: string }
type CartSuccessAction = {
	type: typeof CART_SUCCESS,
	cartItems: Array<CartItemModel>,
	enhancedCartItems: Array<EnhancedCartItemModel>
}

export type CartAction =
	| ItemRemoveRequestAction
	| ItemRemoveFailureAction
	| ItemRemoveSuccessAction
	| ClearRequestAction
	| ClearFailureAction
	| ClearSuccessAction
	| ItemEnhanceRequestAction
	| ItemEnhanceSuccessAction
	| CartRequestAction
	| CartFailureAction
	| CartSuccessAction
	| StockInformationRequestAction
	| StockInformationSuccessAction
	| DmsErrorAction

type GetState = () => State;
type PromiseAction = Promise<CartAction>;
// eslint-disable-next-line
type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;
export type Dispatch = (action: CartAction | ThunkAction | PromiseAction) => any;

export const printWatchListFailed = (error: string) => {
	return {
		type: PRINT_WATCHLIST_FAILURE,
		error
	};
};
const itemRemoveRequest = (partNumber: string): ItemRemoveRequestAction => {
	return {
		type: ITEM_REMOVE_REQUEST,
		partNumber
	};
};

const itemRemoveFailure = (error: string): ItemRemoveFailureAction => {
	return {
		type: ITEM_REMOVE_FAILURE,
		error
	};
};

const itemRemoveSuccess = (): ItemRemoveSuccessAction => {
	return {
		type: ITEM_REMOVE_SUCCESS
	};
};

const clearRequest = (): ClearRequestAction => {
	return {
		type: CLEAR_REQUEST
	};
};

const clearFailure = (error: string): ClearFailureAction => {
	return {
		type: CLEAR_FAILURE,
		error
	};
};

const clearSuccess = (): ClearSuccessAction => {
	return {
		type: CLEAR_SUCCESS
	};
};

const itemEnhanceRequest = (): ItemEnhanceRequestAction => {
	return {
		type: ITEM_ENHANCE_REQUEST,
	};
};

const itemEnhanceSuccess = (enhancedItems: Array<EnhancedCartItemModel>): ItemEnhanceSuccessAction => {
	return {
		type: ITEM_ENHANCE_SUCCESS,
		enhancedItems: enhancedItems
	};
};

const itemEditRequest = (partNumber: string, recipient: string, note: string, amount: number, isExpressOrder: boolean): ItemEditRequestAction => {
	return {
		type: ITEM_EDIT_REQUEST,
		partNumber,
		recipient,
		note,
		amount,
		isExpressOrder
	};
};

const itemEditFailure = (error: string): ItemEditFailureAction => {
	return {
		type: ITEM_EDIT_FAILURE,
		error
	};
};

const itemEditSuccess = (): ItemEditSuccessAction => {
	return {
		type: ITEM_EDIT_SUCCESS
	};
};

const itemAddRequest = (partNumber: string): ItemAddRequestAction => {
	return {
		type: ITEM_ADD_REQUEST,
		partNumber
	};
};

const itemAddFailure = (): ItemAddFailureAction => {
	return {
		type: ITEM_ADD_FAILURE,
		message: <Trans i18nKey="cart.itemAddFailure"/>
	};
};

const itemAddSuccess = (): ItemAddSuccessAction => {
	return {
		type: ITEM_ADD_SUCCESS
	};
};

const itemsAddRequest = (): ItemsAddRequestAction => {
	return {
		type: ITEM_ADD_REQUEST
	};
};

const itemsAddFailure = (): ItemsAddFailureAction => {
	return {
		type: ITEMS_ADD_FAILURE,
		message: <Trans i18nKey="cart.itemAddFailure"/>
	};
};

const itemsAddSuccess = (amount: number): ItemsAddSuccessAction => {
	return {
		type: ITEMS_ADD_SUCCESS,
		amount
	};
};

const cartRequest = (): CartRequestAction => {
	return {
		type: CART_REQUEST
	};
};

const cartFailure = (error: string): CartFailureAction => {
	return {
		type: CART_FAILURE,
		error
	};
};

const cartSuccess = (cartItems: Array<CartItemModel>, enhancedCartItems: Array<EnhancedCartItemModel>): CartSuccessAction => {
	return async dispatch => {
		return dispatch({
			type: CART_SUCCESS,
			cartItems,
			enhancedCartItems,
		});
	};
};

async function getCart(): Promise<Cart> {
	return cartService.getCart();
}

export function getCartWithDetails(): ThunkAction {
	return async dispatch => {
		dispatch(cartRequest());
		try {
			const cart = await getCart();
			return dispatch(cartSuccess(cart.cartItems, []));
		} catch (e) {
			return dispatch(cartFailure(e.message));
		}
	};
}

export function removeItem(partNumber: string): ThunkAction {
	return async dispatch => {
		dispatch(itemRemoveRequest(partNumber));
		try {
			await cartService.removeItem(partNumber, null, "CART");
			return dispatch(itemRemoveSuccess());
		} catch (e) {
			return dispatch(itemRemoveFailure(e.message));
		}
	};
}

export function clear(): ThunkAction {
	return async dispatch => {
		dispatch(clearRequest());
		try {
			await cartService.clear();
			return dispatch(clearSuccess());
		} catch (e) {
			return dispatch(clearFailure(e.message));
		}
	};
}

export function addCartItem(partNumber: string, amount: number, note: string): ThunkAction {
	return async dispatch => {
		dispatch(itemAddRequest(partNumber));
		try {
			await cartService.addCartItem(partNumber, amount, note, null, "CART");
			return dispatch(itemAddSuccess());
		} catch (e) {
			return dispatch(itemAddFailure());
		}
	};
}

export function addCartItems(items: BaseCartItem[]): ThunkAction {
	return async dispatch => {
		dispatch(itemsAddRequest());
		try {
			await cartService.addCartItems(null, "CART", items);
			return dispatch(itemsAddSuccess(items.length));
		} catch (e) {
			return dispatch(itemsAddFailure());
		}
	};
}

export function editItem(partNumber: string, recipient: string, note: string, amount: number, isExpressOrder: boolean): ThunkAction {
	return async dispatch => {
		dispatch(itemEditRequest(partNumber, recipient, note, amount, isExpressOrder));
		try {
			await cartService.editItem(partNumber, recipient, note, amount, isExpressOrder, null, "CART");
			return dispatch(itemEditSuccess());
		} catch (e) {
			return dispatch(itemEditFailure(e.message));
		}
	};
}

export function updateCart(cartItems: Array<CartItemModel>): ThunkAction {
	return (dispatch, getState) => {
		let enhancedCartItems = getState().cart.enhancedItems.map(enhancedItem => {
			let item = cartItems.find(item => item.partNumber === enhancedItem.partNumber);
			return item
				? {
					...enhancedItem,
					amount: item.amount,
					note: item.note,
					expressOrder: item.expressOrder,
					recipient: item.recipient
				}
				: enhancedItem;
		});
		enhancedCartItems = enhancedCartItems.filter(enhancedItem => cartItems.find(item => item.partNumber === enhancedItem.partNumber));
		return dispatch(cartSuccess(cartItems, enhancedCartItems));
	};
}

export function enhanceCart(cartItems: CartItemModel[]): ThunkAction {
	return dispatch => {
		dispatch(itemEnhanceRequest());
		enhanceCartItems(cartItems, false)
			.then((enhancedItems: Array<EnhancedCartItemModel>) => {
				dispatch(itemEnhanceSuccess(enhancedItems));
			});
	};
}
