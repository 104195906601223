import {Axios} from "axios";
import {axiosInstance} from "../api";

class SupportMailService {
	axios: Axios;

	constructor(axios: Axios) {
		this.axios = axios;
	}

	async createTicket(name: string, phone: string, mail: string, description: string, category: string, dealerId: string): Promise<void> {
		await this.axios.post("/ticket", {
			userName: name,
			phone,
			mail,
			description,
			dealerId,
			category
		});
	};
}

const SupportMailServiceInstance = new SupportMailService(axiosInstance);
export default SupportMailServiceInstance;
